html {
  color: $font-color--base;
  font-family: var(--font-family);
  line-height: var(--line-height);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family);
  font-style: normal;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 5.25rem;
  letter-spacing: -1.5px;
}

h2 {
  font-size: 3.28rem;
}

h3 {
  font-size: 2.63rem;
}

h4 {
  font-size: 1.86rem;
}

h5 {
  font-size: 1.53rem;
}

h6 {
  font-size: 1.31rem;
}
