:root,
.Polaris-TopBar {
  /* Polaris variables */
  --top-bar-background: #8eb1c7;
}

#legacy {
  &.inside-shopify {
    .Polaris-Page__Content {
      margin-top: 0;
    }
    .Polaris-Frame__Navigation {
      top: 0 !important;
      height: calc(100%) !important;
    }
    .Polaris-Frame--hasNav {
      margin-left: 65px;
    }
    .Polaris-Frame--hasNav .Polaris-Frame__Main {
      padding-left: calc(18rem + env(safe-area-inset-left));
    }
  }

  &.outside-shopify {
    .Polaris-Frame--hasNav .Polaris-Frame__Main {
      padding-left: calc(10rem + env(safe-area-inset-left));
    }
  }

  .connections-container {
    min-width: 530px;
  }

  .connect-button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 10px;
  }

  .connect-button img {
    margin-left: 6px;
  }

  .shopify-store {
    height: 38px;
    font-size: 14px;
    padding: 6px;
    margin-left: 6px;
    height: 40px;
    width: 220px;
  }

  .rct-chart-background,
  .rct-plot-background {
    fill: none;
  }

  .rct-chart-ticks-x,
  .rct-chart-ticks-y {
    stroke: #160c28;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .Polaris-Banner {
    margin-bottom: 1rem;
  }

  .Polaris-ChoiceList__Choices {
    background-color: transparent !important;
  }
}
