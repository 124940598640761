$font-color--base: #000;
$viewport-background-color: #fff;

$black: #000;
$dark-blue: #08678c;
$dark-gray: #757575;
$gray: #ecf0f4;
$green: #78be20;
$light-blue: #66b8d9;
$light-brown: #d9bba0;
$orange: #f2541b;
$white: #fff;

:root {
  // Typography
  --font-family--monospace: B612, Menlo, Monaco, monospace;
  --font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;

  --font-weight--thin: 100;
  --font-weight--extra-light: 200;
  --font-weight--light: 300;
  --font-weight--normal: 400;
  --font-weight--medium: 500;
  --font-weight--semi-bold: 600;
  --font-weight--bold: 700;
  --font-weight--extra-bold: 800;
  --font-weight--black: 900;

  // Line heights
  --line-height: 1.5;
}
