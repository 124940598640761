.Polaris-ChoiceList__Choices {
    background-color: pink;
}

.Polaris-Select__SelectedOption {
    display: none;
    visibility: hidden;
}

.Polaris-Icon__Svg {
    display: none;
    visibility: hidden;
}
