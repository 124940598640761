.Polaris-Modal-Dialog__Container{
  position: fixed;
	z-index: 519;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background-color: rgba(100,100,100,0.4);
}
@media (min-width: 48.0625em){
  .Polaris-Modal-Dialog__Container{
    justify-content: center;
}
}
.Polaris-Modal-Dialog__Modal{
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	width: 100vw;
	max-height: calc(100vh - 60px);
	background: #fff;
	box-shadow: 0 31px 41px 0 rgba(32,42,53,.2), 0 2px 16px 0 rgba(32,42,54,.08);
}
@media (max-width: 48.0525em){
  .Polaris-Modal-Dialog__Modal{
    bottom: 0;
    max-height: 100%;
  
}
}
.Polaris-Modal-Dialog__Modal:focus{
  outline: 0;
}
@media (min-width: 48.0625em){
	
.Polaris-Modal-Dialog__Modal{
	position: relative;
	max-width: 62rem;
	margin: 0 auto;
	border-radius: 6px;
}
}
@media (min-width: 48.0625em) and (min-height: 660px){
  .Polaris-Modal-Dialog__Modal 
  .Polaris-Modal-Dialog--limitHeight {
    max-height: 600px;
  
}
}
@media (min-width: 48.0625em){
  .Polaris-Modal-Dialog__Modal
  .Polaris-Modal-Dialog--sizeLarge {
    max-width: calc(100vw - 6.4rem);
  
}
}
@media (min-width: 65.25em){
  .Polaris-Modal-Dialog__Modal
  .Polaris-Modal-Dialog--sizeLarge{
    max-width: 98rem;
  
}
}
.Polaris-Modal-Dialog--animateFadeUp{
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	will-change: transform,opacity;
	opacity: 1;
	transform: translateY(0);
  transition: transform .2s cubic-bezier(.64,0,.35,1),opacity .2s cubic-bezier(.64,0,.35,1)
}
.Polaris-Modal-Dialog--animateFadeUp
.Polaris-Modal-Dialog--entering,
.Polaris-Modal-Dialog--animateFadeUp
.Polaris-Modal-Dialog--exited,
.Polaris-Modal-Dialog--animateFadeUp
.Polaris-Modal-Dialog--exiting{
	opacity: 0;
  transform: translateY(20rem);
}
.Polaris-Modal-Dialog--animateFadeUp
.Polaris-Modal-Dialog--entered{
	opacity: 1;
  transform: translateY(0);
}
.Polaris-Modal-Footer{
	display: flex;
	align-self: flex-end;
	align-items: center;
	width: 100%;
	min-height: 6.4rem;
	padding: 1.6rem;
  border-top: .1rem solid #dfe3e8;
}
.Polaris-Modal-Footer__FooterContent{
  width: 100%;
}
.Polaris-Modal-CloseButton{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: none;
	border: none;
	font-size: inherit;
	line-height: inherit;
	cursor: pointer;
	margin: 0 -.8rem 0 2rem;
  padding: .8rem;
}
.Polaris-Modal-CloseButton:focus{
  outline: none;
}
.Polaris-Modal-CloseButton:active,
.Polaris-Modal-CloseButton:focus{
	border-radius: 6px;
  background: rgba(33,43,54,.1);
}
.Polaris-Modal-CloseButton--withoutTitle{
	position: absolute;
	right: .8rem;
}
.Polaris-Modal-Header{
	display: flex;
	align-items: flex-start;
	flex-shrink: 0;
	padding: 1rem 2rem;
  border-bottom: .1rem solid #dfe3e8;
}
.Polaris-Modal-Header__Title{
	word-wrap: break-word;
	word-break: break-word;
	overflow-wrap: break-word;
	flex: 1 1;
  margin-top: .4rem;
}
.Polaris-Modal-Section{
	flex: 0 0 auto;
  padding: 1.6rem 2rem;
}
.Polaris-Modal-Section:first-of-type {
  padding-top: 2rem;
}
.Polaris-Modal-Section:not(:last-of-type){
  border-bottom: .1rem solid #dfe3e8;
}
.Polaris-Modal-Section
.Polaris-Modal-Section--subdued{
  background: #f4f6f8;
}
.Polaris-Modal-Section
.Polaris-Modal-Section--flush{
  padding: 0;
}
.Polaris-Modal__BodyWrapper{
	display: flex;
	flex-grow: 1;
  // overflow-x: hidden;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.Polaris-Modal__Body{
	width: 100;
}
.Polaris-Modal__IFrame{
	display: block;
	width: 62rem;
	max-width: 100vw;
	border: none;
}
@media (min-width: 48.0625em){
  .Polaris-Modal__IFrame{
    max-width: 62rem;
}
}
.Polaris-Modal__Spinner {
	margin: 1.6rem;
	text-align: center;
}
.Polaris-FormLayout{
margin-top:-1.6rem;margin-left:-2rem
}
.Polaris-FormLayout__Title{
margin-bottom:-.8rem;padding:1.6rem 2rem 0
}
.Polaris-FormLayout__Items{
display:flex;flex-wrap:wrap
}
.Polaris-FormLayout__HelpText{
color:#637381;padding:.8rem 2rem 0
}
.Polaris-FormLayout__Item{
flex:1 1 22rem;margin-top:1.6rem;margin-left:2rem;max-width:calc(100% - 2rem)
}
.Polaris-FormLayout--grouped .Polaris-FormLayout__Item{
min-width:22rem
}
.Polaris-TextField{
font-size:1.6rem;font-weight:400;line-height:2.4rem;text-transform:none;letter-spacing:normal;position:relative;display:flex;align-items:center;color:#637381;cursor:text
}
@media (min-width:40em){
.Polaris-TextField{
font-size:1.4rem
}
}
.Polaris-TextField svg{
fill:#637381
}
.Polaris-TextField--multiline{
padding:0;flex-wrap:wrap
}
.Polaris-TextField--multiline>.Polaris-TextField__Input{
overflow:auto;padding-left:1.2rem;padding-right:1.2rem;resize:none
}
.Polaris-TextField--hasValue{
color:#637381
}
.Polaris-TextField--focus>.Polaris-TextField__Input,.Polaris-TextField__Input:focus{
outline:none;border:.1rem solid transparent
}
.Polaris-TextField--focus>.Polaris-TextField__Input~.Polaris-TextField__Backdrop:after,.Polaris-TextField__Input:focus~.Polaris-TextField__Backdrop:after{
opacity:1
}
.Polaris-TextField--error .Polaris-TextField__Input::-webkit-input-placeholder{
color:#9c9798
}
.Polaris-TextField--error .Polaris-TextField__Input::-ms-input-placeholder{
color:#9c9798
}
.Polaris-TextField--error .Polaris-TextField__Input::placeholder{
color:#9c9798
}
.Polaris-TextField--error>.Polaris-TextField__Input~.Polaris-TextField__Backdrop{
background-color:#fbeae5;border-color:#bf0711
}
.Polaris-TextField--error>.Polaris-TextField__Input~.Polaris-TextField__Backdrop:after{
border-color:#bf0711
}
.Polaris-TextField--readOnly>.Polaris-TextField__Backdrop{
background-color:#f9fafb
}
.Polaris-TextField--disabled{
color:#919eab;cursor:auto
}
.Polaris-TextField--disabled>.Polaris-TextField__Backdrop{
background-color:#f9fafb;box-shadow:none
}
.Polaris-TextField--disabled svg{
fill:#919eab
}
.Polaris-TextField__Input{
font-size:1.6rem;font-weight:400;line-height:2.4rem;text-transform:none;letter-spacing:normal;position:relative;z-index:20;display:block;flex:1 1;width:100%;min-width:0;min-height:3.6rem;margin:0;padding:.5rem 1.2rem;background:none;border:.1rem solid transparent;font-family:inherit;font-size:inherit;font-weight:inherit;-webkit-appearance:none;-moz-appearance:none;appearance:none
}
@media (min-width:40em){
.Polaris-TextField__Input{
font-size:1.4rem
}
}
.Polaris-TextField__Prefix+.Polaris-TextField__Input{
padding-left:0
}
.Polaris-TextField__Input:disabled{
background:none;border:.1rem solid transparent;color:currentColor;opacity:1;-webkit-text-fill-color:currentColor
}
.Polaris-TextField__Input:invalid{
box-shadow:none
}
.Polaris-TextField__Input::-webkit-input-placeholder{
color:#919eab
}
.Polaris-TextField__Input::-ms-input-placeholder{
color:#919eab
}
.Polaris-TextField__Input::placeholder{
color:#919eab
}
.Polaris-TextField__Input[type=number]{
-webkit-appearance:textfield;-moz-appearance:textfield;appearance:textfield
}
.Polaris-TextField__Input[type=number]::-webkit-inner-spin-button,.Polaris-TextField__Input[type=number]::-webkit-outer-spin-button{
-webkit-appearance:none;appearance:none;margin:0
}
.Polaris-TextField__Input--hasClearButton[type=search]::-webkit-search-cancel-button{
-webkit-appearance:none;appearance:none
}
.Polaris-TextField__Input--suffixed{
padding-right:0
}
.Polaris-TextField__Input--alignRight{
text-align:right
}
.Polaris-TextField__Input--alignLeft{
text-align:left
}
.Polaris-TextField__Input--alignCenter{
text-align:center
}
.Polaris-TextField__Backdrop{
position:absolute;z-index:10;top:0;right:0;bottom:0;left:0;background-color:#fff;border:.1rem solid #c4cdd5;border-radius:3px;box-shadow:inset 0 1px 0 0 rgba(99,115,129,.05);pointer-events:none
}
.Polaris-TextField__Backdrop:after{
content:"";position:absolute;top:-.2rem;right:-.2rem;bottom:-.2rem;left:-.2rem;border:.2rem solid #5c6ac4;border-radius:inherit;opacity:0;transition:opacity .2s cubic-bezier(.64,0,.35,1);pointer-events:none
}
.Polaris-InlineError{
display:flex;color:#bf0711;fill:#bf0711
}
.Polaris-InlineError__Icon{
fill:currentColor;margin-left:-.2rem;margin-right:.6rem
}
.Polaris-Icon {
	display: block;
	height: 2rem;
	width: 2rem;
	max-height: 100%;
	max-width: 100%;
	margin: auto;
}
