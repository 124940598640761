// NOTE(ivy): These styles have been manually scoped to isolate our old CSS from
// the new CSS.

/* file copied from node_modules/@shopify/polaris/styles.css */
:root{
  --polaris-version-number:'4.14.0'; }

html#legacy,
#legacy body{
  font-size:1.5rem;
  font-weight:400;
  line-height:2rem;
  text-transform:initial;
  letter-spacing:initial;
  font-weight:400;
  color:var(--p-text, #212b36); }
  @media (min-width: 40em){
    html#legacy,
    html#legacy body{
      font-size:1.4rem; } }

html#legacy,
#legacy body,
button{
  font-family:-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif; }

html#legacy{
  position:relative;
  font-size:62.5%;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  -webkit-text-size-adjust:100%;
      -ms-text-size-adjust:100%;
          text-size-adjust:100%;
  text-rendering:optimizeLegibility; }

#legacy {
  body{
    min-height:100%;
    margin:0;
    padding:0;
    background-color:#f4f6f8; }

  *,
  *::before,
  *::after{
    box-sizing:border-box; }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p{
    margin:0;
    font-size:1em;
    font-weight:400; }

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner{
    border-style:none; }

  @keyframes polaris-SkeletonShimmerAnimation{
    0%{
      opacity:0.45; }
    100%{
      opacity:0.9; } }


  .Polaris-Avatar{
    position:relative;
    display:block;
    overflow:hidden;
    min-width:3.2rem;
    max-width:100%;
    background:var(--p-action-secondary, #dfe3e8);
    color:var(--p-icon-subdued, #919eab);
    border-radius:3rem;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none; }
    @media (-ms-high-contrast: active){
      .Polaris-Avatar{
        border:1px solid windowText; } }
    .Polaris-Avatar::after{
      content:'';
      display:block;
      padding-bottom:100%; }

  .Polaris-Avatar--hidden{
    visibility:hidden; }

  .Polaris-Avatar--sizeSmall{
    width:3.2rem; }

  .Polaris-Avatar--sizeMedium{
    width:4rem; }

  .Polaris-Avatar--sizeLarge{
    width:6rem; }

  .Polaris-Avatar--styleOne{
    color:var(--p-decorative-one-text, white);
    background:var(--p-decorative-one-surface, #47c1bf); }

  .Polaris-Avatar--styleTwo{
    color:var(--p-decorative-two-text, white);
    background:var(--p-decorative-two-surface, #de3618); }

  .Polaris-Avatar--styleThree{
    color:var(--p-decorative-three-text, white);
    background:var(--p-decorative-three-surface, #f49342); }

  .Polaris-Avatar--styleFour{
    color:var(--p-decorative-four-text, white);
    background:var(--p-decorative-four-surface, #50b83c); }

  .Polaris-Avatar--styleFive{
    color:var(--p-decorative-five-text, white);
    background:var(--p-decorative-five-surface, #006fbb); }

  .Polaris-Avatar--styleSix{
    color:white;
    background:#9c6ade; }

  .Polaris-Avatar--hasImage{
    background:transparent; }

  .Polaris-Avatar__Image{
    position:absolute;
    top:50%;
    left:50%;
    width:100%;
    height:100%;
    background-color:var(--p-background, #dfe3e8);
    border-radius:3rem;
    transform:translate(-50%, -50%);
    object-fit:cover; }

  .Polaris-Avatar__Initials{
    position:absolute;
    top:0;
    right:0;
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:100%; }

  .Polaris-Avatar__Svg{
    width:100%;
    height:100%; }


  .Polaris-Icon{
    display:block;
    height:2rem;
    width:2rem;
    max-height:100%;
    max-width:100%;
    margin:auto; }

  .Polaris-Icon--hasBackdrop{
    position:relative;
    display:flex;
    align-items:center;
    margin:0.4rem; }
    .Polaris-Icon--hasBackdrop::before{
      content:'';
      position:absolute;
      top:-0.4rem;
      bottom:-0.4rem;
      left:-0.4rem;
      right:-0.4rem;
      border-radius:50%; }

  .Polaris-Icon--isColored{
    color:white; }

  .Polaris-Icon--colorWhite{
    color:transparent; }
    .Polaris-Icon--colorWhite svg{
      fill:white; }
    .Polaris-Icon--colorWhite img{
      -webkit-filter:brightness(0) saturate(100%) invert(100%);
              filter:brightness(0) saturate(100%) invert(100%); }

  .Polaris-Icon--colorBlack svg{
    fill:black; }

  .Polaris-Icon--colorBlack img{
    -webkit-filter:brightness(0) saturate(100%);
            filter:brightness(0) saturate(100%); }

  .Polaris-Icon--colorSkyLighter svg{
    fill:#f9fafb; }

  .Polaris-Icon--colorSkyLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(159%) hue-rotate(170deg) brightness(99%) contrast(99%);
            filter:brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(159%) hue-rotate(170deg) brightness(99%) contrast(99%); }

  .Polaris-Icon--colorSkyLight svg{
    fill:#f4f6f8; }

  .Polaris-Icon--colorSkyLight img{
    -webkit-filter:brightness(0) saturate(100%) invert(99%) sepia(12%) saturate(467%) hue-rotate(174deg) brightness(99%) contrast(96%);
            filter:brightness(0) saturate(100%) invert(99%) sepia(12%) saturate(467%) hue-rotate(174deg) brightness(99%) contrast(96%); }

  .Polaris-Icon--colorSky svg{
    fill:#dfe3e8; }

  .Polaris-Icon--colorSky img{
    -webkit-filter:brightness(0) saturate(100%) invert(100%) sepia(95%) saturate(336%) hue-rotate(175deg) brightness(97%) contrast(87%);
            filter:brightness(0) saturate(100%) invert(100%) sepia(95%) saturate(336%) hue-rotate(175deg) brightness(97%) contrast(87%); }

  .Polaris-Icon--colorSkyDark svg{
    fill:#c4cdd5; }

  .Polaris-Icon--colorSkyDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(86%) sepia(4%) saturate(502%) hue-rotate(167deg) brightness(96%) contrast(91%);
            filter:brightness(0) saturate(100%) invert(86%) sepia(4%) saturate(502%) hue-rotate(167deg) brightness(96%) contrast(91%); }

  .Polaris-Icon--colorInkLightest svg{
    fill:#919eab; }

  .Polaris-Icon--colorInkLightest img{
    -webkit-filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%);
            filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%); }

  .Polaris-Icon--colorInkLighter svg{
    fill:#637381; }

  .Polaris-Icon--colorInkLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(8%) saturate(825%) hue-rotate(166deg) brightness(95%) contrast(90%);
            filter:brightness(0) saturate(100%) invert(45%) sepia(8%) saturate(825%) hue-rotate(166deg) brightness(95%) contrast(90%); }

  .Polaris-Icon--colorInkLighter::before{
    background-color:#dfe3e8; }

  .Polaris-Icon--colorInkLight svg{
    fill:#454f5b; }

  .Polaris-Icon--colorInkLight img{
    -webkit-filter:brightness(0) saturate(100%) invert(32%) sepia(9%) saturate(1069%) hue-rotate(173deg) brightness(83%) contrast(84%);
            filter:brightness(0) saturate(100%) invert(32%) sepia(9%) saturate(1069%) hue-rotate(173deg) brightness(83%) contrast(84%); }

  .Polaris-Icon--colorInk svg{
    fill:#212b36; }

  .Polaris-Icon--colorInk img{
    -webkit-filter:brightness(0) saturate(100%) invert(10%) sepia(10%) saturate(2259%) hue-rotate(171deg) brightness(99%) contrast(84%);
            filter:brightness(0) saturate(100%) invert(10%) sepia(10%) saturate(2259%) hue-rotate(171deg) brightness(99%) contrast(84%); }

  .Polaris-Icon--colorInk::before{
    background-color:#dfe3e8; }

  .Polaris-Icon--colorBlueLighter svg{
    fill:#ebf5fa; }

  .Polaris-Icon--colorBlueLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(100%) sepia(94%) saturate(686%) hue-rotate(175deg) brightness(103%) contrast(96%);
            filter:brightness(0) saturate(100%) invert(100%) sepia(94%) saturate(686%) hue-rotate(175deg) brightness(103%) contrast(96%); }

  .Polaris-Icon--colorBlueLight svg{
    fill:#b4e1fa; }

  .Polaris-Icon--colorBlueLight img{
    -webkit-filter:brightness(0) saturate(100%) invert(80%) sepia(7%) saturate(1832%) hue-rotate(178deg) brightness(108%) contrast(96%);
            filter:brightness(0) saturate(100%) invert(80%) sepia(7%) saturate(1832%) hue-rotate(178deg) brightness(108%) contrast(96%); }

  .Polaris-Icon--colorBlue svg{
    fill:#006fbb; }

  .Polaris-Icon--colorBlue img{
    -webkit-filter:brightness(0) saturate(100%) invert(19%) sepia(98%) saturate(2885%) hue-rotate(190deg) brightness(99%) contrast(101%);
            filter:brightness(0) saturate(100%) invert(19%) sepia(98%) saturate(2885%) hue-rotate(190deg) brightness(99%) contrast(101%); }

  .Polaris-Icon--colorBlueDark svg{
    fill:#084e8a; }

  .Polaris-Icon--colorBlueDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(22%) sepia(70%) saturate(1308%) hue-rotate(182deg) brightness(94%) contrast(101%);
            filter:brightness(0) saturate(100%) invert(22%) sepia(70%) saturate(1308%) hue-rotate(182deg) brightness(94%) contrast(101%); }

  .Polaris-Icon--colorBlueDark::before{
    background-color:#b4e1fa; }

  .Polaris-Icon--colorBlueDarker svg{
    fill:#001429; }

  .Polaris-Icon--colorBlueDarker img{
    -webkit-filter:brightness(0) saturate(100%) invert(5%) sepia(33%) saturate(5606%) hue-rotate(195deg) brightness(97%) contrast(102%);
            filter:brightness(0) saturate(100%) invert(5%) sepia(33%) saturate(5606%) hue-rotate(195deg) brightness(97%) contrast(102%); }

  .Polaris-Icon--colorIndigoLighter svg{
    fill:#f4f5fa; }

  .Polaris-Icon--colorIndigoLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(1090%) hue-rotate(179deg) brightness(100%) contrast(96%);
            filter:brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(1090%) hue-rotate(179deg) brightness(100%) contrast(96%); }

  .Polaris-Icon--colorIndigoLight svg{
    fill:#b3bcf5; }

  .Polaris-Icon--colorIndigoLight img{
    -webkit-filter:brightness(0) saturate(100%) invert(82%) sepia(37%) saturate(4261%) hue-rotate(194deg) brightness(111%) contrast(92%);
            filter:brightness(0) saturate(100%) invert(82%) sepia(37%) saturate(4261%) hue-rotate(194deg) brightness(111%) contrast(92%); }

  .Polaris-Icon--colorIndigo svg{
    fill:#5c6ac4; }

  .Polaris-Icon--colorIndigo img{
    -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
            filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }

  .Polaris-Icon--colorIndigoDark svg{
    fill:#202e78; }

  .Polaris-Icon--colorIndigoDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(17%) sepia(28%) saturate(4409%) hue-rotate(218deg) brightness(87%) contrast(98%);
            filter:brightness(0) saturate(100%) invert(17%) sepia(28%) saturate(4409%) hue-rotate(218deg) brightness(87%) contrast(98%); }

  .Polaris-Icon--colorIndigoDarker svg{
    fill:#000639; }

  .Polaris-Icon--colorIndigoDarker img{
    -webkit-filter:brightness(0) saturate(100%) invert(5%) sepia(81%) saturate(5060%) hue-rotate(229deg) brightness(72%) contrast(111%);
            filter:brightness(0) saturate(100%) invert(5%) sepia(81%) saturate(5060%) hue-rotate(229deg) brightness(72%) contrast(111%); }

  .Polaris-Icon--colorTealLighter svg{
    fill:#e0f5f5; }

  .Polaris-Icon--colorTealLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(87%) sepia(5%) saturate(1124%) hue-rotate(173deg) brightness(114%) contrast(92%);
            filter:brightness(0) saturate(100%) invert(87%) sepia(5%) saturate(1124%) hue-rotate(173deg) brightness(114%) contrast(92%); }

  .Polaris-Icon--colorTealLight svg{
    fill:#b7ecec; }

  .Polaris-Icon--colorTealLight img{
    -webkit-filter:brightness(0) saturate(100%) invert(95%) sepia(12%) saturate(683%) hue-rotate(122deg) brightness(97%) contrast(91%);
            filter:brightness(0) saturate(100%) invert(95%) sepia(12%) saturate(683%) hue-rotate(122deg) brightness(97%) contrast(91%); }

  .Polaris-Icon--colorTeal svg{
    fill:#47c1bf; }

  .Polaris-Icon--colorTeal img{
    -webkit-filter:brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(2838%) hue-rotate(130deg) brightness(92%) contrast(87%);
            filter:brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(2838%) hue-rotate(130deg) brightness(92%) contrast(87%); }

  .Polaris-Icon--colorTeal::before{
    background-color:white; }

  .Polaris-Icon--colorTealDark svg{
    fill:#00848e; }

  .Polaris-Icon--colorTealDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(28%) sepia(83%) saturate(3919%) hue-rotate(168deg) brightness(93%) contrast(101%);
            filter:brightness(0) saturate(100%) invert(28%) sepia(83%) saturate(3919%) hue-rotate(168deg) brightness(93%) contrast(101%); }

  .Polaris-Icon--colorTealDark::before{
    background-color:#b7ecec; }

  .Polaris-Icon--colorTealDarker svg{
    fill:#003135; }

  .Polaris-Icon--colorTealDarker img{
    -webkit-filter:brightness(0) saturate(100%) invert(15%) sepia(23%) saturate(2237%) hue-rotate(141deg) brightness(96%) contrast(104%);
            filter:brightness(0) saturate(100%) invert(15%) sepia(23%) saturate(2237%) hue-rotate(141deg) brightness(96%) contrast(104%); }

  .Polaris-Icon--colorGreenLighter svg{
    fill:#e3f1df; }

  .Polaris-Icon--colorGreenLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(92%) sepia(51%) saturate(187%) hue-rotate(46deg) brightness(108%) contrast(89%);
            filter:brightness(0) saturate(100%) invert(92%) sepia(51%) saturate(187%) hue-rotate(46deg) brightness(108%) contrast(89%); }

  .Polaris-Icon--colorGreen svg{
    fill:#50b83c; }

  .Polaris-Icon--colorGreen img{
    -webkit-filter:brightness(0) saturate(100%) invert(56%) sepia(10%) saturate(2637%) hue-rotate(64deg) brightness(106%) contrast(91%);
            filter:brightness(0) saturate(100%) invert(56%) sepia(10%) saturate(2637%) hue-rotate(64deg) brightness(106%) contrast(91%); }

  .Polaris-Icon--colorGreen::before{
    background-color:#e3f1df; }

  .Polaris-Icon--colorGreenDark svg{
    fill:#108043; }

  .Polaris-Icon--colorGreenDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(18%) sepia(75%) saturate(6649%) hue-rotate(155deg) brightness(97%) contrast(87%);
            filter:brightness(0) saturate(100%) invert(18%) sepia(75%) saturate(6649%) hue-rotate(155deg) brightness(97%) contrast(87%); }

  .Polaris-Icon--colorGreenDark::before{
    background-color:#bbe5b3; }

  .Polaris-Icon--colorYellowLighter svg{
    fill:#fcf1cd; }

  .Polaris-Icon--colorYellowLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(88%) sepia(27%) saturate(234%) hue-rotate(357deg) brightness(103%) contrast(98%);
            filter:brightness(0) saturate(100%) invert(88%) sepia(27%) saturate(234%) hue-rotate(357deg) brightness(103%) contrast(98%); }

  .Polaris-Icon--colorYellow svg{
    fill:#eec200; }

  .Polaris-Icon--colorYellow img{
    -webkit-filter:brightness(0) saturate(100%) invert(65%) sepia(91%) saturate(530%) hue-rotate(5deg) brightness(100%) contrast(100%);
            filter:brightness(0) saturate(100%) invert(65%) sepia(91%) saturate(530%) hue-rotate(5deg) brightness(100%) contrast(100%); }

  .Polaris-Icon--colorYellowDark svg{
    fill:#8a6116; }

  .Polaris-Icon--colorYellowDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(37%) sepia(51%) saturate(709%) hue-rotate(0deg) brightness(93%) contrast(89%);
            filter:brightness(0) saturate(100%) invert(37%) sepia(51%) saturate(709%) hue-rotate(0deg) brightness(93%) contrast(89%); }

  .Polaris-Icon--colorYellowDark::before{
    background-color:#ffea8a; }

  .Polaris-Icon--colorOrange svg{
    fill:#f49342; }

  .Polaris-Icon--colorOrange img{
    -webkit-filter:brightness(0) saturate(100%) invert(54%) sepia(86%) saturate(416%) hue-rotate(340deg) brightness(105%) contrast(91%);
            filter:brightness(0) saturate(100%) invert(54%) sepia(86%) saturate(416%) hue-rotate(340deg) brightness(105%) contrast(91%); }

  .Polaris-Icon--colorOrangeDark svg{
    fill:#c05717; }

  .Polaris-Icon--colorOrangeDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(29%) sepia(94%) saturate(1431%) hue-rotate(5deg) brightness(96%) contrast(82%);
            filter:brightness(0) saturate(100%) invert(29%) sepia(94%) saturate(1431%) hue-rotate(5deg) brightness(96%) contrast(82%); }

  .Polaris-Icon--colorRedLighter svg{
    fill:#fbeae5; }

  .Polaris-Icon--colorRedLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(89%) sepia(21%) saturate(137%) hue-rotate(324deg) brightness(102%) contrast(97%);
            filter:brightness(0) saturate(100%) invert(89%) sepia(21%) saturate(137%) hue-rotate(324deg) brightness(102%) contrast(97%); }

  .Polaris-Icon--colorRed svg{
    fill:#de3618; }

  .Polaris-Icon--colorRed img{
    -webkit-filter:brightness(0) saturate(100%) invert(28%) sepia(67%) saturate(3622%) hue-rotate(353deg) brightness(89%) contrast(95%);
            filter:brightness(0) saturate(100%) invert(28%) sepia(67%) saturate(3622%) hue-rotate(353deg) brightness(89%) contrast(95%); }

  .Polaris-Icon--colorRedDark svg{
    fill:#bf0711; }

  .Polaris-Icon--colorRedDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(12%) sepia(100%) saturate(5699%) hue-rotate(353deg) brightness(75%) contrast(101%);
            filter:brightness(0) saturate(100%) invert(12%) sepia(100%) saturate(5699%) hue-rotate(353deg) brightness(75%) contrast(101%); }

  .Polaris-Icon--colorRedDark::before{
    background-color:#fead9a; }

  .Polaris-Icon--colorPurple svg{
    fill:#9c6ade; }

  .Polaris-Icon--colorPurple img{
    -webkit-filter:brightness(0) saturate(100%) invert(49%) sepia(77%) saturate(1864%) hue-rotate(229deg) brightness(91%) contrast(91%);
            filter:brightness(0) saturate(100%) invert(49%) sepia(77%) saturate(1864%) hue-rotate(229deg) brightness(91%) contrast(91%); }

  .Polaris-Icon__Svg,
  .Polaris-Icon__Img{
    position:relative;
    display:block;
    width:100%;
    max-width:100%;
    max-height:100%; }

  .Polaris-Icon__Placeholder{
    padding-bottom:100%;
    background:currentColor; }


  .Polaris-VisuallyHidden{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }


  @keyframes Polaris-Spinner--loading{
    to{
      transform:rotate(360deg); } }

  .Polaris-Spinner{
    animation:Polaris-Spinner--loading 500ms linear infinite;
    color:transparent; }

  .Polaris-Spinner--sizeSmall{
    height:2rem;
    width:2rem; }

  .Polaris-Spinner--sizeLarge{
    height:4.4rem;
    width:4.4rem; }

  .Polaris-Spinner--colorWhite{
    -webkit-filter:brightness(0) saturate(100%) invert(100%);
            filter:brightness(0) saturate(100%) invert(100%); }

  .Polaris-Spinner--colorTeal{
    -webkit-filter:brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(2838%) hue-rotate(130deg) brightness(92%) contrast(87%);
            filter:brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(2838%) hue-rotate(130deg) brightness(92%) contrast(87%); }

  .Polaris-Spinner--colorInkLightest{
    -webkit-filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%);
            filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%); }


  .Polaris-Scrollable{
    -webkit-overflow-scrolling:touch;
    position:relative; }

  .Polaris-Scrollable--horizontal{
    overflow-x:auto; }

  .Polaris-Scrollable--vertical{
    overflow-y:auto; }

  .Polaris-Scrollable--verticalHasScrolling{
    overflow-y:scroll; }

  .Polaris-Scrollable--hasTopShadow{
    box-shadow:inset 0 2rem 2rem -2rem var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1)); }

  .Polaris-Scrollable--hasBottomShadow{
    box-shadow:inset 0 -2rem 2rem -2rem var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1)); }

  .Polaris-Scrollable--hasTopShadow.Polaris-Scrollable--hasBottomShadow{
    box-shadow:inset 0 2rem 2rem -2rem var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1)), inset 0 -2rem 2rem -2rem var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1)); }


  .Polaris-Popover{
    max-width:calc(100vw - 3.2rem);
    margin:0.5rem 0.8rem 1.6rem;
    box-shadow:var(--p-popover-shadow, 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08));
    border-radius:var(--p-border-radius-wide, 3px);
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:left, top; }

  .Polaris-Popover__PopoverOverlay{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity;
    opacity:0;
    transition:opacity 200ms cubic-bezier(0.36, 0, 1, 1); }

  .Polaris-Popover__PopoverOverlay--entering{
    opacity:1; }

  .Polaris-Popover__PopoverOverlay--open{
    opacity:1; }

  .Polaris-Popover__PopoverOverlay--exiting{
    opacity:0;
    transition-timing-function:cubic-bezier(0, 0, 0.42, 1); }

  .Polaris-Popover--measuring:not(.Polaris-Popover__PopoverOverlay--exiting){
    opacity:0; }

  .Polaris-Popover--fullWidth{
    margin:0;
    margin-top:0.5rem; }
    .Polaris-Popover--fullWidth .Polaris-Popover__Content{
      max-width:none; }

  .Polaris-Popover--positionedAbove{
    margin:1.6rem 0.8rem 0.5rem; }
    .Polaris-Popover--positionedAbove.Polaris-Popover--fullWidth{
      margin:0 0 0.5rem; }

  .Polaris-Popover__Wrapper{
    position:relative;
    overflow:hidden;
    background-color:var(--p-surface, white);
    border-radius:var(--p-border-radius-wide, 3px); }
    @media (-ms-high-contrast: active){
      .Polaris-Popover__Wrapper{
        box-shadow:inset 0 0 0 0.1rem windowText; } }

  .Polaris-Popover__Content{
    position:relative;
    display:flex;
    flex-direction:column;
    border-radius:3px;
    max-width:40rem;
    max-height:29.5rem; }
    .Polaris-Popover__Content:focus{
      outline:none; }

  .Polaris-Popover__Content--fullHeight{
    max-height:none; }

  .Polaris-Popover__Content--fluidContent{
    max-height:none;
    max-width:none; }

  .Polaris-Popover__Pane{
    flex:1 1;
    max-width:100%; }
    .Polaris-Popover__Pane + .Polaris-Popover__Pane{
      border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }

  .Polaris-Popover__Pane--fixed{
    overflow:visible;
    flex:0 0 auto; }

  .Polaris-Popover__Section{
    padding:1.6rem; }
    .Polaris-Popover__Section + .Polaris-Popover__Section{
      border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }

  .Polaris-Popover__FocusTracker{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }


  .Polaris-PositionedOverlay{
    position:absolute;
    z-index:400; }

  .Polaris-PositionedOverlay--fixed{
    position:fixed; }

  .Polaris-PositionedOverlay--calculating{
    visibility:hidden; }


  .Polaris-Badge{
    display:inline-flex;
    align-items:center;
    padding:0 0.8rem;
    background-color:var(--p-action-secondary-disabled, #dfe3e8);
    border:0.2rem solid var(--p-surface, white);
    border-radius:2rem;
    font-size:1.3rem;
    line-height:2rem;
    color:var(--p-text, #454f5b); }
    .Polaris-Badge .Polaris-Badge__Pip{
      color:var(--p-icon, #919eab); }

  .Polaris-Badge--sizeSmall{
    font-size:1.2rem;
    line-height:1.8rem; }

  .Polaris-Badge--statusSuccess{
    background-color:var(--p-surface-success, #bbe5b3);
    color:#414f3e; }
    .Polaris-Badge--statusSuccess .Polaris-Badge__Pip{
      color:var(--p-icon-success, #108043); }
    .Polaris-Badge--statusSuccess .Polaris-Badge__Content{
      color:var(--p-text);
      mix-blend-mode:var(--p-badge-mix-blend-mode); }

  .Polaris-Badge--statusInfo{
    background-color:var(--p-surface-highlight, #b4e1fa);
    color:#3e4e57; }
    .Polaris-Badge--statusInfo .Polaris-Badge__Pip{
      color:var(--p-icon-highlight, #084e8a); }
    .Polaris-Badge--statusInfo .Polaris-Badge__Content{
      color:var(--p-text);
      mix-blend-mode:var(--p-badge-mix-blend-mode); }

  .Polaris-Badge--statusAttention{
    background-color:#ffea8a;
    color:#595130; }
    .Polaris-Badge--statusAttention .Polaris-Badge__Pip{
      color:#8a6116; }

  .Polaris-Badge--statusWarning{
    background-color:var(--p-surface-warning, #ffc58b);
    color:#594430; }
    .Polaris-Badge--statusWarning .Polaris-Badge__Pip{
      color:var(--p-icon-warning, #c05717); }
    .Polaris-Badge--statusWarning .Polaris-Badge__Content{
      color:var(--p-text);
      mix-blend-mode:var(--p-badge-mix-blend-mode); }

  .Polaris-Badge--statusNew{
    background-color:#dfe3e8;
    color:#212b36;
    font-weight:500;
    border:none; }

  .Polaris-Badge__Pip{
    height:1rem;
    width:1rem;
    margin:0 0.4rem 0 -0.3rem;
    border:0.2rem solid currentColor;
    border-radius:50%; }

  .Polaris-Badge--progressIncomplete .Polaris-Badge__Pip{
    background:transparent; }

  .Polaris-Badge--progressPartiallyComplete .Polaris-Badge__Pip{
    background:linear-gradient(to top, currentColor, currentColor 50%, transparent 50%, transparent); }

  .Polaris-Badge--progressComplete .Polaris-Badge__Pip{
    background:linear-gradient(to top, currentColor, currentColor 50%, currentColor 50%); }


  .Polaris-TextStyle--variationPositive{
    color:var(--p-text-success, #108043); }

  .Polaris-TextStyle--variationNegative{
    color:var(--p-text-critical, #bf0711); }

  .Polaris-TextStyle--variationCode{
    position:relative;
    padding:0 0.4rem;
    border-radius:3px;
    background-color:var(--p-surface-subdued, #f4f6f8);
    display:inline-block;
    font-size:1.15em;
    box-shadow:inset 0 0 0 1px var(--p-border-subdued, #dfe3e8); }
    .Polaris-TextStyle--variationCode::after{
      content:'';
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      border:1px solid transparent; }

  .Polaris-TextStyle--variationStrong{
    font-weight:600; }

  .Polaris-TextStyle--variationSubdued{
    color:var(--p-text-subdued, #637381); }


  .Polaris-ActionList{
    list-style:none;
    margin:0;
    padding:0.8rem 0; }
    .Polaris-ActionList.Polaris-ActionList--newDesignLanguage{
      padding:0.8rem; }

  .Polaris-ActionList__Section--withoutTitle:not(:first-child){
    border-top:0.1rem solid var(--p-border-subdued, #dfe3e8);
    margin-top:0.8rem;
    padding-top:0.8rem; }

  .Polaris-ActionList__Actions{
    list-style:none;
    margin:0;
    padding:0;
    border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }

  .Polaris-ActionList > .Polaris-ActionList__Section--withoutTitle .Polaris-ActionList__Actions,
  .Polaris-ActionList__Section:first-child > .Polaris-ActionList__Section--withoutTitle .Polaris-ActionList__Actions{
    border-top:none; }

  .Polaris-ActionList__Title{
    font-size:1.3rem;
    font-weight:600;
    line-height:1.6rem;
    text-transform:uppercase;
    padding:1.2rem 1.6rem; }
    @media (min-width: 40em){
      .Polaris-ActionList__Title{
        font-size:1.2rem; } }

  .Polaris-ActionList__Item{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    color:inherit;
    text-decoration:none;
    display:block;
    width:100%;
    min-height:4rem;
    padding:1rem 1.6rem;
    text-align:left;
    cursor:pointer;
    border-radius:0; }
    .Polaris-ActionList__Item:focus{
      outline:none; }
    .Polaris-ActionList__Item:visited{
      color:inherit; }
    .Polaris-ActionList__Item.Polaris-ActionList--active{
      background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }
    .Polaris-ActionList__Item:active{
      background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }
      .Polaris-ActionList__Item:active:hover{
        background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
        .Polaris-ActionList__Item:active:hover:focus{
          box-shadow:inset 0.2rem 0 0 #5c6ac4;
          background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ActionList__Item:hover{
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
      .Polaris-ActionList__Item:hover:focus{
        box-shadow:inset 0.2rem 0 0 #5c6ac4;
        background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ActionList__Item:focus{
      box-shadow:inset 0.2rem 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ActionList__Item.Polaris-ActionList--destructive{
      color:#bf0711; }
      .Polaris-ActionList__Item.Polaris-ActionList--destructive svg{
        fill:#bf0711; }
      .Polaris-ActionList__Item.Polaris-ActionList--destructive:active{
        background-image:linear-gradient(rgba(220, 56, 37, 0.03), rgba(220, 56, 37, 0.03)); }
        .Polaris-ActionList__Item.Polaris-ActionList--destructive:active:hover{
          background-image:linear-gradient(rgba(220, 56, 37, 0.03), rgba(220, 56, 37, 0.03)), linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)); }
          .Polaris-ActionList__Item.Polaris-ActionList--destructive:active:hover:focus{
            box-shadow:inset 0.2rem 0 0 #de3618;
            background-image:linear-gradient(rgba(220, 56, 37, 0.03), rgba(220, 56, 37, 0.03)), linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)), linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)); }
      .Polaris-ActionList__Item.Polaris-ActionList--destructive:hover{
        background-image:linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)); }
        .Polaris-ActionList__Item.Polaris-ActionList--destructive:hover:focus{
          box-shadow:inset 0.2rem 0 0 #de3618;
          background-image:linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)), linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)); }
      .Polaris-ActionList__Item.Polaris-ActionList--destructive:focus{
        box-shadow:inset 0.2rem 0 0 #de3618;
        background-image:linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)); }
    .Polaris-ActionList__Item.Polaris-ActionList--disabled{
      background-image:linear-gradient(#f9fafb, #f9fafb);
      color:#919eab;
      pointer-events:none; }
      .Polaris-ActionList__Item.Polaris-ActionList--disabled .Polaris-ActionList__Image svg{
        fill:#919eab;
        color:white; }
    .Polaris-ActionList__Item::-moz-focus-inner{
      border:none; }
    .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage{
      padding:1rem 0.8rem;
      border-radius:var(--p-border-radius-base);
      position:relative; }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage:hover{
        background-image:none;
        background-color:var(--p-surface-hovered); }
        @media (-ms-high-contrast: active){
          .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage:hover{
            outline:1px solid windowText; } }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--active, .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage:active{
        background-image:none;
        background-color:var(--p-surface-pressed); }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage:active:hover:focus{
        box-shadow:none;
        background-image:none; }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage:hover:focus{
        box-shadow:none;
        background-image:none; }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage:focus{
        box-shadow:none;
        background-image:none; }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage:focus:not(:active)::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--destructive{
        color:var(--p-interactive-critical); }
        .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--destructive svg{
          fill:var(--p-icon-critical); }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--destructive:hover{
        background-image:none;
        background-color:var(--p-surface-critical-subdued-hovered); }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--destructive.Polaris-ActionList--active, .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--destructive:active{
        background-image:none;
        background-color:var(--p-surface-critical-subdued-pressed); }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--destructive:active:hover:focus{
        box-shadow:none;
        background-image:none; }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--destructive:hover:focus{
        box-shadow:none;
        background-image:none; }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--destructive:focus{
        box-shadow:none;
        background-image:none; }
      .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--disabled{
        background-image:none;
        color:var(--p-text-disabled); }
        .Polaris-ActionList__Item.Polaris-ActionList--newDesignLanguage.Polaris-ActionList--disabled .Polaris-ActionList__Image svg{
          fill:var(--p-icon-disabled); }

  .Polaris-ActionList__Content{
    display:flex;
    align-items:center; }

  .Polaris-ActionList__Image{
    display:flex;
    flex:0 0 auto;
    justify-content:center;
    align-items:center;
    height:2rem;
    width:2rem;
    border-radius:3px;
    margin:-1rem 1.6rem -1rem 0;
    background-size:cover;
    background-position:center center; }
    .Polaris-ActionList__Image svg{
      fill:#454f5b;
      color:white; }

  .Polaris-ActionList__Text{
    min-width:0;
    max-width:100%;
    flex:1 1 auto; }

  .Polaris-ActionList__BadgeWrapper{
    margin-left:1.6rem; }


  .Polaris-Button{
    position:relative;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    min-height:3.6rem;
    min-width:3.6rem;
    margin:0;
    padding:0.7rem 1.6rem;
    background:linear-gradient(to bottom, white, #f9fafb);
    border:0.1rem solid var(--p-border, #c4cdd5);
    box-shadow:0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    line-height:1;
    color:#212b36;
    text-align:center;
    cursor:pointer;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    transition-property:background, border, box-shadow;
    transition-duration:var(--p-override-none, 200ms);
    transition-timing-function:var(--p-override-none, cubic-bezier(0.64, 0, 0.35, 1));
    -webkit-tap-highlight-color:transparent; }
    .Polaris-Button svg{
      fill:#637381; }
    .Polaris-Button:hover{
      background:linear-gradient(to bottom, #f9fafb, #f4f6f8);
      border-color:#c4cdd5; }
    .Polaris-Button:focus{
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-Button:focus{
          outline:2px dotted; } }
    .Polaris-Button:active{
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      border-color:#c4cdd5;
      box-shadow:0 0 0 0 transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2); }
    .Polaris-Button.Polaris-Button--newDesignLanguage{
      position:relative;
      background:var(--p-action-secondary);
      border:none;
      box-shadow:none;
      border-radius:var(--p-border-radius-base);
      color:var(--p-text); }
      .Polaris-Button.Polaris-Button--newDesignLanguage svg{
        fill:var(--p-icon); }
      .Polaris-Button.Polaris-Button--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Button.Polaris-Button--newDesignLanguage:hover{
        background:var(--p-action-secondary-hovered);
        border-color:transparent; }
      .Polaris-Button.Polaris-Button--newDesignLanguage:focus{
        border-color:transparent;
        box-shadow:none; }
        .Polaris-Button.Polaris-Button--newDesignLanguage:focus::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Button.Polaris-Button--newDesignLanguage:active{
        background:var(--p-action-secondary-pressed);
        border-color:transparent;
        box-shadow:none; }
        .Polaris-Button.Polaris-Button--newDesignLanguage:active::after{
          border:none;
          box-shadow:none; }
      @media (-ms-high-contrast: active){
        .Polaris-Button.Polaris-Button--newDesignLanguage{
          border:1px solid windowText; } }
    .Polaris-Button.Polaris-Button--disabled{
      transition:none;
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      color:#919eab; }
      .Polaris-Button.Polaris-Button--disabled svg{
        fill:#919eab; }
      .Polaris-Button.Polaris-Button--disabled.Polaris-Button--newDesignLanguage{
        background:var(--p-action-secondary-disabled);
        color:var(--p-text-disabled); }
        .Polaris-Button.Polaris-Button--disabled.Polaris-Button--newDesignLanguage svg{
          fill:var(--p-icon-disabled); }
    .Polaris-Button.Polaris-Button--connectedDisclosure{
      z-index:10;
      border-top-right-radius:0;
      border-bottom-right-radius:0; }
      .Polaris-Button.Polaris-Button--connectedDisclosure::after{
        border-top-right-radius:0;
        border-bottom-right-radius:0; }
      .Polaris-Button.Polaris-Button--connectedDisclosure:focus{
        z-index:20; }

  [data-buttongroup-segmented] .Polaris-Button,
  [data-buttongroup-segmented] .Polaris-Button::after{
    border-radius:0; }

  [data-buttongroup-segmented] > :first-child .Polaris-Button,
  [data-buttongroup-segmented] > :first-child .Polaris-Button::after{
    border-top-left-radius:var(--p-border-radius-base, 3px);
    border-bottom-left-radius:var(--p-border-radius-base, 3px); }

  [data-buttongroup-segmented] > :last-child .Polaris-Button,
  [data-buttongroup-segmented] > :last-child .Polaris-Button::after{
    border-top-right-radius:var(--p-border-radius-base, 3px);
    border-bottom-right-radius:var(--p-border-radius-base, 3px); }

  [data-buttongroup-connected-top] > :first-child .Polaris-Button,
  [data-buttongroup-connected-top] > :first-child .Polaris-Button::after{
    border-top-left-radius:0; }

  [data-buttongroup-connected-top] > :last-child .Polaris-Button,
  [data-buttongroup-connected-top] > :last-child .Polaris-Button::after{
    border-top-right-radius:0; }

  [data-buttongroup-full-width] .Polaris-Button{
    display:flex;
    width:100%; }

  .Polaris-Button__Content{
    font-size:1.5rem;
    font-weight:var(--p-button-font-weight, 400);
    line-height:1.6rem;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    min-width:1px;
    min-height:1px; }
    @media (min-width: 40em){
      .Polaris-Button__Content{
        font-size:1.4rem; } }

  .Polaris-Button--textAlignLeft{
    justify-content:flex-start;
    text-align:left; }

  .Polaris-Button--textAlignCenter{
    justify-content:center;
    text-align:center; }

  .Polaris-Button--textAlignRight{
    justify-content:flex-end;
    text-align:right; }

  .Polaris-Button__Icon{
    transition:color 200ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Button__Icon:first-child{
      margin-left:-0.4rem; }
    .Polaris-Button__Icon:last-child{
      margin-right:-0.8rem;
      margin-left:0.4rem; }
    .Polaris-Button__Icon + *:not(.Polaris-Button__Icon){
      margin-left:0.4rem; }

  .Polaris-Button__Spinner{
    position:absolute;
    top:50%;
    left:50%;
    margin-top:-1rem;
    margin-left:-1rem; }

  .Polaris-Button--primary{
    --p-button-color:var(--p-action-primary);
    --p-button-text:var(--p-text-on-primary);
    --p-button-color-hover:var(--p-action-primary-hovered);
    --p-button-color-active:var(--p-action-primary-pressed);
    background:linear-gradient(to bottom, #6371c7, #5563c1);
    border-color:#3f4eae;
    box-shadow:inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
    color:white; }
    .Polaris-Button--primary:hover{
      background:linear-gradient(to bottom, #5c6ac4, #4959bd);
      border-color:#3f4eae;
      color:white;
      text-decoration:none; }
    .Polaris-Button--primary:focus{
      border-color:#202e78;
      box-shadow:inset 0 1px 0 0 #6f7bcb, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 1px #202e78; }
    .Polaris-Button--primary:active{
      background:linear-gradient(to bottom, #3f4eae, #3f4eae);
      border-color:#38469b;
      box-shadow:inset 0 0 0 0 transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 1px 0 #38469b; }
    .Polaris-Button--primary.Polaris-Button--newDesignLanguage{
      background:var(--p-button-color);
      border-color:transparent;
      box-shadow:none;
      color:var(--p-button-text); }
      .Polaris-Button--primary.Polaris-Button--newDesignLanguage:hover{
        background:var(--p-button-color-hover);
        border-color:transparent;
        color:var(--p-button-text); }
      .Polaris-Button--primary.Polaris-Button--newDesignLanguage:focus{
        border-color:transparent;
        box-shadow:none; }
      .Polaris-Button--primary.Polaris-Button--newDesignLanguage:active{
        background:var(--p-button-color-active);
        border-color:transparent;
        box-shadow:none; }
    .Polaris-Button--primary svg{
      fill:white; }
    .Polaris-Button--primary.Polaris-Button--newDesignLanguage svg{
      fill:var(--p-button-text); }
    .Polaris-Button--primary.Polaris-Button--disabled{
      background:linear-gradient(to bottom, #bac0e6, #bac0e6);
      border-color:#a7aedf;
      box-shadow:none;
      color:white; }
      .Polaris-Button--primary.Polaris-Button--disabled svg{
        fill:white; }
      .Polaris-Button--primary.Polaris-Button--disabled.Polaris-Button--newDesignLanguage{
        border-color:transparent;
        background:var(--p-action-secondary-disabled);
        color:var(--p-text-disabled); }
        .Polaris-Button--primary.Polaris-Button--disabled.Polaris-Button--newDesignLanguage svg{
          fill:var(--p-icon-disabled); }
    .Polaris-Button--primary.Polaris-Button--pressed{
      background:linear-gradient(to bottom, #3f4eae, #3f4eae);
      border-color:#10173c;
      box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #10173c; }
      .Polaris-Button--primary.Polaris-Button--pressed:focus, .Polaris-Button--primary.Polaris-Button--pressed:hover{
        transition-duration:100ms;
        background:linear-gradient(to bottom, #3c4ba7, #3c4ba7);
        border-color:#10173c;
        box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #10173c; }
      .Polaris-Button--primary.Polaris-Button--pressed:active{
        background:linear-gradient(to bottom, #3f4eae, #3f4eae);
        border-color:#10173c;
        box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #0b0f27; }

  .Polaris-Button--destructive{
    --p-button-color:var(--p-action-critical);
    --p-button-text:var(--p-text-on-critical);
    --p-button-color-hover:var(--p-action-critical-hovered);
    --p-button-color-active:var(--p-action-critical-pressed);
    background:linear-gradient(to bottom, #e6391a, #d53417);
    border-color:#b02b13;
    box-shadow:inset 0 1px 0 0 #e73d1f, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
    color:white; }
    .Polaris-Button--destructive:hover{
      background:linear-gradient(to bottom, #de3618, #c73016);
      border-color:#b02b13;
      color:white;
      text-decoration:none; }
    .Polaris-Button--destructive:focus{
      border-color:#bf0711;
      box-shadow:inset 0 1px 0 0 #e84528, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 1px #bf0711; }
    .Polaris-Button--destructive:active{
      background:linear-gradient(to bottom, #b02b13, #b02b13);
      border-color:#992511;
      box-shadow:inset 0 0 0 0 transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 1px 0 #992511; }
    .Polaris-Button--destructive.Polaris-Button--newDesignLanguage{
      background:var(--p-button-color);
      border-color:transparent;
      box-shadow:none;
      color:var(--p-button-text); }
      .Polaris-Button--destructive.Polaris-Button--newDesignLanguage:hover{
        background:var(--p-button-color-hover);
        border-color:transparent;
        color:var(--p-button-text); }
      .Polaris-Button--destructive.Polaris-Button--newDesignLanguage:focus{
        border-color:transparent;
        box-shadow:none; }
      .Polaris-Button--destructive.Polaris-Button--newDesignLanguage:active{
        background:var(--p-button-color-active);
        border-color:transparent;
        box-shadow:none; }
    .Polaris-Button--destructive svg{
      fill:white; }
    .Polaris-Button--destructive.Polaris-Button--disabled{
      background:linear-gradient(to bottom, #f29484, #f29484);
      border-color:#ef816d;
      box-shadow:none;
      color:white; }
      .Polaris-Button--destructive.Polaris-Button--disabled svg{
        fill:white; }
      .Polaris-Button--destructive.Polaris-Button--disabled.Polaris-Button--newDesignLanguage{
        border-color:transparent;
        background:var(--p-action-secondary-disabled);
        color:var(--p-text-disabled); }
        .Polaris-Button--destructive.Polaris-Button--disabled.Polaris-Button--newDesignLanguage svg{
          fill:var(--p-icon-disabled); }
    .Polaris-Button--destructive.Polaris-Button--pressed{
      background:linear-gradient(to bottom, #b02b13, #b02b13);
      border-color:#75040a;
      box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #75040a; }
      .Polaris-Button--destructive.Polaris-Button--pressed:focus, .Polaris-Button--destructive.Polaris-Button--pressed:hover{
        transition-duration:100ms;
        background:linear-gradient(to bottom, #a72912, #a72912);
        border-color:#75040a;
        box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #75040a; }
      .Polaris-Button--destructive.Polaris-Button--pressed:active{
        background:linear-gradient(to bottom, #b02b13, #b02b13);
        border-color:#75040a;
        box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #5d0308; }
    .Polaris-Button--destructive.Polaris-Button--newDesignLanguage svg{
      fill:var(--p-button-text); }

  .Polaris-Button--outline{
    background:transparent;
    border-color:rgba(99, 115, 129, 0.4);
    box-shadow:none;
    color:#374047; }
    .Polaris-Button--outline svg{
      fill:#374047; }
    .Polaris-Button--outline:hover{
      background:rgba(99, 115, 129, 0.05);
      border-color:rgba(99, 115, 129, 0.4); }
    .Polaris-Button--outline:focus{
      border-color:rgba(99, 115, 129, 0.8);
      box-shadow:0 0 0 1px rgba(99, 115, 129, 0.8); }
    .Polaris-Button--outline:active{
      background:rgba(99, 115, 129, 0.1);
      box-shadow:none; }
    .Polaris-Button--outline.Polaris-Button--newDesignLanguage{
      background:transparent;
      position:relative;
      box-shadow:0 0 0 0.1rem var(--p-border-secondary);
      color:var(--p-text); }
      .Polaris-Button--outline.Polaris-Button--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.2rem;
        right:-0.2rem;
        bottom:-0.2rem;
        left:-0.2rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.2rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Button--outline.Polaris-Button--newDesignLanguage:hover{
        box-shadow:0 0 0 0.1rem var(--p-border-secondary);
        background:var(--p-surface-hovered); }
      .Polaris-Button--outline.Polaris-Button--newDesignLanguage:focus{
        box-shadow:0 0 0 0.1rem var(--p-border-secondary); }
        .Polaris-Button--outline.Polaris-Button--newDesignLanguage:focus::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Button--outline.Polaris-Button--newDesignLanguage:active{
        box-shadow:0 0 0 0.1rem var(--p-border-secondary);
        background:var(--p-surface-pressed); }
        .Polaris-Button--outline.Polaris-Button--newDesignLanguage:active::after{
          box-shadow:none; }
      .Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--disabled{
        box-shadow:0 0 0 0.1rem var(--p-border-secondary-disabled);
        background:transparent;
        color:var(--p-text-disabled); }
      .Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive{
        background:transparent;
        box-shadow:0 0 0 0.1rem var(--p-border-critical);
        color:var(--p-interactive-critical); }
        .Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive svg{
          fill:var(--p-icon-critical); }
        .Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:hover{
          box-shadow:0 0 0 0.1rem var(--p-border-critical);
          background:var(--p-surface-critical-subdued); }
        .Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:focus{
          box-shadow:0 0 0 0.2rem var(--p-border-critical); }
          .Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:focus::after{
            content:none; }
        .Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:active{
          box-shadow:0 0 0 0.1rem var(--p-border-critical);
          background:var(--p-surface-critical-subdued); }
        .Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive.Polaris-Button--disabled{
          box-shadow:0 0 0 0.1rem var(--p-border-critical-disabled);
          background:transparent;
          color:var(--p-interactive-critical-disabled); }
    .Polaris-Button--outline.Polaris-Button--disabled{
      background:transparent;
      border-color:rgba(99, 115, 129, 0.25);
      box-shadow:none;
      color:#919eab; }
      .Polaris-Button--outline.Polaris-Button--disabled svg{
        fill:#919eab; }

  .Polaris-Button--destructive.Polaris-Button--outline{
    background:transparent;
    border-color:rgba(222, 54, 24, 0.4);
    box-shadow:none;
    color:#82200e; }
    .Polaris-Button--destructive.Polaris-Button--outline svg{
      fill:#82200e; }
    .Polaris-Button--destructive.Polaris-Button--outline:hover{
      background:rgba(222, 54, 24, 0.05);
      border-color:rgba(222, 54, 24, 0.4); }
    .Polaris-Button--destructive.Polaris-Button--outline:focus{
      border-color:rgba(222, 54, 24, 0.8);
      box-shadow:0 0 0 1px rgba(222, 54, 24, 0.8); }
    .Polaris-Button--destructive.Polaris-Button--outline:active{
      background:rgba(222, 54, 24, 0.1);
      box-shadow:none; }
    .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage{
      background:transparent;
      position:relative;
      box-shadow:0 0 0 0.1rem var(--p-border-secondary);
      color:var(--p-text); }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.2rem;
        right:-0.2rem;
        bottom:-0.2rem;
        left:-0.2rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.2rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage:hover{
        box-shadow:0 0 0 0.1rem var(--p-border-secondary);
        background:var(--p-surface-hovered); }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage:focus{
        box-shadow:0 0 0 0.1rem var(--p-border-secondary); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage:focus::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage:active{
        box-shadow:0 0 0 0.1rem var(--p-border-secondary);
        background:var(--p-surface-pressed); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage:active::after{
          box-shadow:none; }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--disabled{
        box-shadow:0 0 0 0.1rem var(--p-border-secondary-disabled);
        background:transparent;
        color:var(--p-text-disabled); }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive{
        background:transparent;
        box-shadow:0 0 0 0.1rem var(--p-border-critical);
        color:var(--p-interactive-critical); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive svg{
          fill:var(--p-icon-critical); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:hover{
          box-shadow:0 0 0 0.1rem var(--p-border-critical);
          background:var(--p-surface-critical-subdued); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:focus{
          box-shadow:0 0 0 0.2rem var(--p-border-critical); }
          .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:focus::after{
            content:none; }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:active{
          box-shadow:0 0 0 0.1rem var(--p-border-critical);
          background:var(--p-surface-critical-subdued); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--newDesignLanguage.Polaris-Button--destructive.Polaris-Button--disabled{
          box-shadow:0 0 0 0.1rem var(--p-border-critical-disabled);
          background:transparent;
          color:var(--p-interactive-critical-disabled); }
    .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed{
      background:rgba(191, 7, 17, 0.03);
      border-color:rgba(191, 7, 17, 0.4);
      box-shadow:none;
      color:#5d0308; }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed svg{
        fill:#5d0308; }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed:hover{
        background:rgba(191, 7, 17, 0.05);
        border-color:rgba(191, 7, 17, 0.4); }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed:focus{
        border-color:rgba(191, 7, 17, 0.8);
        box-shadow:0 0 0 1px rgba(191, 7, 17, 0.8); }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed:active{
        background:rgba(191, 7, 17, 0.1);
        box-shadow:none; }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage{
        background:transparent;
        position:relative;
        box-shadow:0 0 0 0.1rem var(--p-border-secondary);
        color:var(--p-text); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.2rem;
          right:-0.2rem;
          bottom:-0.2rem;
          left:-0.2rem;
          display:block;
          pointer-events:none;
          box-shadow:0 0 0 -0.2rem var(--p-focused);
          transition:box-shadow 100ms var(--p-ease);
          border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage:hover{
          box-shadow:0 0 0 0.1rem var(--p-border-secondary);
          background:var(--p-surface-hovered); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage:focus{
          box-shadow:0 0 0 0.1rem var(--p-border-secondary); }
          .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage:focus::after{
            box-shadow:0 0 0 0.2rem var(--p-focused); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage:active{
          box-shadow:0 0 0 0.1rem var(--p-border-secondary);
          background:var(--p-surface-pressed); }
          .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage:active::after{
            box-shadow:none; }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage.Polaris-Button--disabled{
          box-shadow:0 0 0 0.1rem var(--p-border-secondary-disabled);
          background:transparent;
          color:var(--p-text-disabled); }
        .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage.Polaris-Button--destructive{
          background:transparent;
          box-shadow:0 0 0 0.1rem var(--p-border-critical);
          color:var(--p-interactive-critical); }
          .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage.Polaris-Button--destructive svg{
            fill:var(--p-icon-critical); }
          .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:hover{
            box-shadow:0 0 0 0.1rem var(--p-border-critical);
            background:var(--p-surface-critical-subdued); }
          .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:focus{
            box-shadow:0 0 0 0.2rem var(--p-border-critical); }
            .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:focus::after{
              content:none; }
          .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:active{
            box-shadow:0 0 0 0.1rem var(--p-border-critical);
            background:var(--p-surface-critical-subdued); }
          .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed.Polaris-Button--newDesignLanguage.Polaris-Button--destructive.Polaris-Button--disabled{
            box-shadow:0 0 0 0.1rem var(--p-border-critical-disabled);
            background:transparent;
            color:var(--p-interactive-critical-disabled); }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed svg{
        fill:#330101; }

  .Polaris-Button--disabled{
    cursor:default;
    pointer-events:none; }

  @keyframes Polaris-Button--loading{
    to{
      transform:rotate(360deg); } }

  .Polaris-Button--loading,
  .Polaris-Button--newDesignLanguage.Polaris-Button--loading{
    position:relative;
    transition:border-color 200ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Button--loading, .Polaris-Button--loading:hover, .Polaris-Button--loading.Polaris-Button--disabled,
    .Polaris-Button--newDesignLanguage.Polaris-Button--loading,
    .Polaris-Button--newDesignLanguage.Polaris-Button--loading:hover,
    .Polaris-Button--newDesignLanguage.Polaris-Button--loading.Polaris-Button--disabled{
      color:transparent; }

  .Polaris-Button--pressed{
    background:#dfe3e8;
    border-color:#b8c3cd;
    box-shadow:0 0 0 1px transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.1); }
    .Polaris-Button--pressed:hover{
      transition-duration:100ms;
      background:#d9dee4;
      border-color:#b8c3cd;
      box-shadow:0 0 0 1px transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.1); }
    .Polaris-Button--pressed:focus{
      border-color:#5c6ac4;
      box-shadow:0 0 0 1px #5c6ac4, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.1); }
    .Polaris-Button--pressed:active{
      background:#d3d9df;
      border-color:#b8c3cd;
      box-shadow:0 0 0 1px transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.1); }
    @media (-ms-high-contrast: active){
      .Polaris-Button--pressed{
        color:buttonText;
        background:buttonFace; } }

  .Polaris-Button--plain{
    margin:-0.7rem -0.8rem;
    padding-left:0.8rem;
    padding-right:0.8rem;
    background:transparent;
    border:0;
    box-shadow:none;
    color:#006fbb; }
    .Polaris-Button--plain svg{
      fill:#006fbb; }
    .Polaris-Button--plain.Polaris-Button--pressed, .Polaris-Button--plain:hover, .Polaris-Button--plain:focus, .Polaris-Button--plain:active{
      background:transparent;
      border:0;
      box-shadow:none;
      color:#084e8a;
      text-decoration:underline; }
      .Polaris-Button--plain.Polaris-Button--pressed svg, .Polaris-Button--plain:hover svg, .Polaris-Button--plain:focus svg, .Polaris-Button--plain:active svg{
        fill:#084e8a; }
    @media (-ms-high-contrast: active){
      .Polaris-Button--plain.Polaris-Button--pressed, .Polaris-Button--plain:focus{
        outline:none; } }
    @media (-ms-high-contrast: active){
      .Polaris-Button--plain:focus > .Polaris-Button__Content{
        outline:2px dotted; } }
    .Polaris-Button--plain.Polaris-Button--pressed > .Polaris-Button__Content{
      padding:2px 5px;
      margin:-2px -5px;
      background:rgba(99, 115, 129, 0.1);
      border-radius:3px;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Button--plain.Polaris-Button--pressed:hover:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content,
    .Polaris-Button--plain.Polaris-Button--pressed:active:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content,
    .Polaris-Button--plain:focus:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content{
      padding:2px 5px;
      margin:-2px -5px;
      background:rgba(33, 43, 54, 0.1);
      border-radius:3px;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Button--plain.Polaris-Button--fullWidth{
      margin-left:0;
      margin-right:0; }
    .Polaris-Button--plain.Polaris-Button--destructive{
      color:var(--p-action-critical, #bf0711); }
      .Polaris-Button--plain.Polaris-Button--destructive:focus, .Polaris-Button--plain.Polaris-Button--destructive:active{
        color:var(--p-action-critical-pressed, #330101); }
    .Polaris-Button--plain.Polaris-Button--disabled{
      background:none; }
    .Polaris-Button--plain.Polaris-Button--sizeSlim{
      margin-top:-0.4rem;
      margin-bottom:-0.4rem; }
    .Polaris-Button--plain.Polaris-Button--sizeLarge{
      margin:-1.1rem -2rem; }
    .Polaris-Button--plain.Polaris-Button--iconOnly{
      margin:-0.8rem; }
      .Polaris-Button--plain.Polaris-Button--iconOnly svg{
        fill:#637381; }
      .Polaris-Button--plain.Polaris-Button--iconOnly:focus, .Polaris-Button--plain.Polaris-Button--iconOnly:active{
        background:rgba(33, 43, 54, 0.1); }
        .Polaris-Button--plain.Polaris-Button--iconOnly:focus svg, .Polaris-Button--plain.Polaris-Button--iconOnly:active svg{
          fill:#637381; }
      .Polaris-Button--plain.Polaris-Button--iconOnly:hover svg, .Polaris-Button--plain.Polaris-Button--iconOnly:active svg{
        fill:#212b36; }
      .Polaris-Button--plain.Polaris-Button--iconOnly > .Polaris-Button__Content::after{
        display:none; }
      .Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg{
        fill:#c4cdd5; }
    .Polaris-Button--plain .Polaris-Button__Icon{
      margin-left:0;
      margin-right:0; }
    .Polaris-Button--plain.Polaris-Button--newDesignLanguage{
      background:transparent;
      border:0;
      box-shadow:none;
      color:var(--p-interactive); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage svg{
        fill:var(--p-interactive); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage::after{
        content:none; }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage:hover, .Polaris-Button--plain.Polaris-Button--newDesignLanguage:focus, .Polaris-Button--plain.Polaris-Button--newDesignLanguage:active{
        background:transparent;
        border:0;
        box-shadow:none; }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage > .Polaris-Button__Content{
        font-weight:400;
        position:relative; }
        .Polaris-Button--plain.Polaris-Button--newDesignLanguage > .Polaris-Button__Content::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.1rem;
          right:-0.1rem;
          bottom:-0.1rem;
          left:-0.1rem;
          display:block;
          pointer-events:none;
          box-shadow:0 0 0 -0.1rem var(--p-focused);
          transition:box-shadow 100ms var(--p-ease);
          border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage:hover{
        color:var(--p-interactive-hovered);
        text-decoration:underline; }
        .Polaris-Button--plain.Polaris-Button--newDesignLanguage:hover svg{
          fill:var(--p-interactive-hovered); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage:focus{
        color:var(--p-interactive); }
        .Polaris-Button--plain.Polaris-Button--newDesignLanguage:focus svg{
          fill:var(--p-interactive); }
        .Polaris-Button--plain.Polaris-Button--newDesignLanguage:focus::after{
          content:none; }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage:active{
        color:var(--p-interactive-pressed); }
        .Polaris-Button--plain.Polaris-Button--newDesignLanguage:active svg{
          fill:var(--p-interactive-pressed); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage:focus:not(:active){
        text-decoration:none; }
        .Polaris-Button--plain.Polaris-Button--newDesignLanguage:focus:not(:active) > .Polaris-Button__Content::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage:focus:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content{
        background:none; }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage:active:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content{
        padding:2px 5px;
        margin:-2px -5px;
        background:var(--p-action-secondary);
        border-radius:3px;
        transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--destructive{
        color:var(--p-interactive-critical); }
        .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--destructive svg{
          fill:var(--p-interactive-critical); }
        .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:hover{
          color:var(--p-interactive-critical-hovered); }
        .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--destructive:active{
          color:var(--p-interactive-critical-pressed); }
        .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--destructive.Polaris-Button--disabled{
          color:var(--p-interactive-critical-disabled); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--disabled{
        color:var(--p-text-disabled);
        background:none; }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--iconOnly svg{
        fill:var(--p-interactive); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--iconOnly:hover svg{
        fill:var(--p-interactive-hovered); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--iconOnly:focus svg{
        fill:var(--p-interactive); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--iconOnly:active svg{
        fill:var(--p-interactive-pressed); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--iconOnly.Polaris-Button--disabled svg{
        fill:var(--p-interactive-disabled); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--destructive.Polaris-Button--iconOnly svg{
        fill:var(--p-action-critical); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--destructive.Polaris-Button--iconOnly:hover svg{
        fill:var(--p-action-critical-hovered); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--destructive.Polaris-Button--iconOnly:focus svg{
        fill:var(--p-action-critical); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--destructive.Polaris-Button--iconOnly:active svg{
        fill:var(--p-action-critical-pressed); }
      .Polaris-Button--plain.Polaris-Button--newDesignLanguage.Polaris-Button--destructive.Polaris-Button--iconOnly.Polaris-Button--disabled svg{
        fill:var(--p-action-critical-disabled); }

  .Polaris-Button--sizeSlim{
    min-height:3rem;
    padding:0.4rem 1.2rem; }
    .Polaris-Button--sizeSlim.Polaris-Button--newDesignLanguage{
      min-height:2.8rem; }

  .Polaris-Button--sizeLarge{
    min-height:4.4rem;
    min-width:4.4rem;
    padding:1.1rem 2.4rem; }
    .Polaris-Button--sizeLarge .Polaris-Button__Content{
      font-size:1.7rem;
      font-weight:var(--p-button-font-weight, 400);
      line-height:2rem;
      text-transform:initial;
      letter-spacing:initial; }
      @media (min-width: 40em){
        .Polaris-Button--sizeLarge .Polaris-Button__Content{
          font-size:1.6rem; } }

  .Polaris-Button--fullWidth{
    display:flex;
    width:100%; }

  .Polaris-Button--iconOnly{
    padding-left:0.8rem;
    padding-right:0.8rem; }
    .Polaris-Button--iconOnly.Polaris-Button--sizeLarge{
      padding-left:1.2rem;
      padding-right:1.2rem; }
    .Polaris-Button--iconOnly .Polaris-Button__Icon:first-child{
      margin-left:0; }
    .Polaris-Button--iconOnly .Polaris-Button__Icon:last-child{
      margin-right:-0.4rem; }
    .Polaris-Button--iconOnly .Polaris-Button__Icon + .Polaris-Button__Icon:last-child{
      margin-left:0; }
    .Polaris-Button--iconOnly .Polaris-Button__Icon:only-child{
      margin-right:0; }

  .Polaris-Button--monochrome.Polaris-Button--outline, .Polaris-Button--monochrome.Polaris-Button--plain{
    color:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline svg, .Polaris-Button--monochrome.Polaris-Button--plain svg{
      fill:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline:hover, .Polaris-Button--monochrome.Polaris-Button--outline:focus, .Polaris-Button--monochrome.Polaris-Button--outline:active, .Polaris-Button--monochrome.Polaris-Button--plain:hover, .Polaris-Button--monochrome.Polaris-Button--plain:focus, .Polaris-Button--monochrome.Polaris-Button--plain:active{
      color:currentColor; }
      .Polaris-Button--monochrome.Polaris-Button--outline:hover svg, .Polaris-Button--monochrome.Polaris-Button--outline:focus svg, .Polaris-Button--monochrome.Polaris-Button--outline:active svg, .Polaris-Button--monochrome.Polaris-Button--plain:hover svg, .Polaris-Button--monochrome.Polaris-Button--plain:focus svg, .Polaris-Button--monochrome.Polaris-Button--plain:active svg{
        fill:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled{
      color:currentColor;
      opacity:0.4; }
      .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled svg{
        fill:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly svg{
      fill:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:focus svg, .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:active svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:focus svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:active svg{
      fill:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly.Polaris-Button--disabled svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg{
      fill:currentColor; }

  .Polaris-Button--monochrome.Polaris-Button--plain .Polaris-Button__Text{
    text-decoration:underline; }

  .Polaris-Button--monochrome.Polaris-Button--outline{
    position:relative;
    border-color:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline::before{
      content:'';
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1);
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background-color:currentColor;
      opacity:0;
      z-index:0; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed{
      background:transparent;
      border-color:currentColor;
      box-shadow:none; }
      .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed::before{
        opacity:0.05; }
    .Polaris-Button--monochrome.Polaris-Button--outline:hover, .Polaris-Button--monochrome.Polaris-Button--outline:focus, .Polaris-Button--monochrome.Polaris-Button--outline:active{
      background-color:transparent;
      border-color:currentColor; }
      .Polaris-Button--monochrome.Polaris-Button--outline:hover::before, .Polaris-Button--monochrome.Polaris-Button--outline:focus::before, .Polaris-Button--monochrome.Polaris-Button--outline:active::before{
        opacity:0.07; }
    .Polaris-Button--monochrome.Polaris-Button--outline:focus{
      box-shadow:0 0 0 1px currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--newDesignLanguage{
      position:relative;
      box-shadow:0 0 0 0.1rem currentColor; }
      .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.2rem;
        right:-0.2rem;
        bottom:-0.2rem;
        left:-0.2rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.2rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--newDesignLanguage:focus::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--newDesignLanguage:active::after{
        box-shadow:none; }

  .Polaris-Button__DisclosureIcon{
    transition-property:transform;
    transition-duration:300ms;
    transition-timing-function:cubic-bezier(0, 0, 0.42, 1); }

  .Polaris-Button__DisclosureIconFacingUp{
    transform:rotate(-180deg); }

  .Polaris-Button__ConnectedDisclosureWrapper{
    display:flex;
    align-items:center; }

  .Polaris-Button__ConnectedDisclosure{
    z-index:10;
    margin-left:-0.1rem;
    border-top-left-radius:0;
    border-bottom-left-radius:0; }
    .Polaris-Button__ConnectedDisclosure.Polaris-Button--outline:not(:focus){
      border-left-color:rgba(99, 115, 129, 0); }
    .Polaris-Button__ConnectedDisclosure:focus, .Polaris-Button__ConnectedDisclosure:active{
      z-index:20; }
    .Polaris-Button__ConnectedDisclosure.Polaris-Button--newDesignLanguage{
      margin-left:0.1rem;
      border-top-left-radius:0;
      border-bottom-left-radius:0; }
      .Polaris-Button__ConnectedDisclosure.Polaris-Button--newDesignLanguage::after{
        border-top-left-radius:0;
        border-bottom-left-radius:0; }


  .Polaris-ButtonGroup{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    margin-top:-0.8rem;
    margin-left:-0.8rem; }

  .Polaris-ButtonGroup__Item{
    margin-top:0.8rem;
    margin-left:0.8rem; }

  .Polaris-ButtonGroup__Item--plain:not(:first-child){
    margin-left:1.6rem; }

  .Polaris-ButtonGroup__Item--plain:not(:last-child){
    margin-right:0.8rem; }

  .Polaris-ButtonGroup--segmented{
    display:flex;
    flex-wrap:nowrap;
    margin-top:0;
    margin-left:0; }
    .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item{
      position:relative;
      z-index:10;
      margin-top:0;
      margin-left:0; }
      .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item:not(:first-child){
        margin-left:var(--p-button-group-item-spacing, -0.1rem); }
    .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item--focused{
      z-index:20; }

  .Polaris-ButtonGroup--fullWidth .Polaris-ButtonGroup__Item{
    flex:1 1 auto; }


  .Polaris-Stack{
    margin-top:-1.6rem;
    margin-left:-1.6rem;
    display:flex;
    flex-wrap:wrap;
    align-items:stretch; }
    .Polaris-Stack > .Polaris-Stack__Item{
      margin-top:1.6rem;
      margin-left:1.6rem;
      max-width:100%; }

  .Polaris-Stack--noWrap{
    flex-wrap:nowrap; }

  .Polaris-Stack--spacingNone{
    margin-top:0;
    margin-left:0; }
    .Polaris-Stack--spacingNone > .Polaris-Stack__Item{
      margin-top:0;
      margin-left:0;
      max-width:100%; }

  .Polaris-Stack--spacingExtraTight{
    margin-top:-0.4rem;
    margin-left:-0.4rem; }
    .Polaris-Stack--spacingExtraTight > .Polaris-Stack__Item{
      margin-top:0.4rem;
      margin-left:0.4rem;
      max-width:100%; }

  .Polaris-Stack--spacingTight{
    margin-top:-0.8rem;
    margin-left:-0.8rem; }
    .Polaris-Stack--spacingTight > .Polaris-Stack__Item{
      margin-top:0.8rem;
      margin-left:0.8rem;
      max-width:100%; }

  .Polaris-Stack--spacingLoose{
    margin-top:-2rem;
    margin-left:-2rem; }
    .Polaris-Stack--spacingLoose > .Polaris-Stack__Item{
      margin-top:2rem;
      margin-left:2rem;
      max-width:100%; }

  .Polaris-Stack--spacingExtraLoose{
    margin-top:-3.2rem;
    margin-left:-3.2rem; }
    .Polaris-Stack--spacingExtraLoose > .Polaris-Stack__Item{
      margin-top:3.2rem;
      margin-left:3.2rem;
      max-width:100%; }

  .Polaris-Stack--distributionLeading{
    justify-content:flex-start; }

  .Polaris-Stack--distributionTrailing{
    justify-content:flex-end; }

  .Polaris-Stack--distributionCenter{
    justify-content:center; }

  .Polaris-Stack--distributionEqualSpacing{
    justify-content:space-between; }

  .Polaris-Stack--distributionFill > .Polaris-Stack__Item{
    flex:1 1 auto; }

  .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item{
    flex:1 1 auto; }
    @supports ((min-width: -webkit-fit-content) or (min-width: fit-content)){
      .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item{
        flex:1 0;
        min-width:-webkit-fit-content;
        min-width:fit-content; } }

  .Polaris-Stack--alignmentLeading{
    align-items:flex-start; }

  .Polaris-Stack--alignmentTrailing{
    align-items:flex-end; }

  .Polaris-Stack--alignmentCenter{
    align-items:center; }

  .Polaris-Stack--alignmentFill{
    align-items:stretch; }

  .Polaris-Stack--alignmentBaseline{
    align-items:baseline; }

  .Polaris-Stack--vertical{
    flex-direction:column;
    margin-left:0; }
    .Polaris-Stack--vertical > .Polaris-Stack__Item{
      margin-left:0; }

  .Polaris-Stack__Item{
    flex:0 0 auto;
    min-width:0; }

  .Polaris-Stack__Item--fill{
    flex:1 1 auto; }


  .Polaris-Heading{
    font-size:1.7rem;
    font-weight:600;
    line-height:2.4rem;
    margin:0; }
    @media (min-width: 40em){
      .Polaris-Heading{
        font-size:1.6rem; } }


  .Polaris-Card{
    overflow:var(--p-override-visible, hidden);
    background-color:var(--p-surface, white);
    box-shadow:var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
    .Polaris-Card + .Polaris-Card{
      margin-top:2rem; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Card{
        border-radius:var(--p-border-radius-wide, 3px); } }
    @media (min-width: 30.625em){
      .Polaris-Card{
        border-radius:var(--p-border-radius-wide, 3px); } }
    @media (-ms-high-contrast: active){
      .Polaris-Card{
        box-shadow:inset 0 0 0 0.1rem windowText; } }

  .Polaris-Card--subdued{
    background-color:var(--p-surface-subdued, #f9fafb); }

  .Polaris-Card__Header{
    padding:1.6rem 1.6rem 0; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Card__Header{
        padding:2rem 2rem 0; } }
    @media (min-width: 30.625em){
      .Polaris-Card__Header{
        padding:2rem 2rem 0; } }

  .Polaris-Card__Section{
    padding:1.6rem; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Card__Section{
        padding:2rem; } }
    @media (min-width: 30.625em){
      .Polaris-Card__Section{
        padding:2rem; } }
    .Polaris-Card__Section + .Polaris-Card__Section{
      border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }

  .Polaris-Card__Section--fullWidth{
    padding:1.6rem 0; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Card__Section--fullWidth{
        padding:2rem 0; } }
    @media (min-width: 30.625em){
      .Polaris-Card__Section--fullWidth{
        padding:2rem 0; } }

  .Polaris-Card__Section--subdued{
    background-color:var(--p-surface-subdued, #f9fafb); }
    @media (-ms-high-contrast: active){
      .Polaris-Card__Section--subdued{
        background-color:transparent; } }
    .Polaris-Card__Header + .Polaris-Card__Section--subdued{
      border-top:0.1rem solid var(--p-border-subdued, #dfe3e8);
      margin-top:2rem; }

  .Polaris-Card__SectionHeader{
    padding-bottom:0.8rem; }
    .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader{
      padding-left:1.6rem;
      padding-right:1.6rem; }
      @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
        [data-has-navigation] .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader{
          padding-left:2rem;
          padding-right:2rem; } }
      @media (min-width: 30.625em){
        .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader{
          padding-left:2rem;
          padding-right:2rem; } }

  .Polaris-Card__Subsection + .Polaris-Card__Subsection{
    margin-top:1.6rem;
    padding-top:1.6rem;
    border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }

  .Polaris-Card__Footer{
    display:flex;
    justify-content:flex-end;
    padding:0 1.6rem 1.6rem; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Card__Footer{
        padding:0 2rem 2rem; } }
    @media (min-width: 30.625em){
      .Polaris-Card__Footer{
        padding:0 2rem 2rem; } }
    .Polaris-Card__Footer.Polaris-Card__LeftJustified{
      justify-content:flex-start; }
    .Polaris-Card__Section--subdued + .Polaris-Card__Footer{
      border-top:0.1rem solid var(--p-border-subdued, #dfe3e8);
      padding:2rem; }


  .Polaris-Subheading{
    font-size:1.3rem;
    font-weight:600;
    line-height:1.6rem;
    text-transform:uppercase;
    margin:0; }
    @media (min-width: 40em){
      .Polaris-Subheading{
        font-size:1.2rem; } }


  .Polaris-SettingAction{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    margin-top:-1.6rem;
    margin-left:-1.6rem; }

  .Polaris-SettingAction__Setting,
  .Polaris-SettingAction__Action{
    flex:0 0 auto;
    margin-top:1.6rem;
    margin-left:1.6rem;
    max-width:calc(100% - 1.6rem);
    min-width:0; }

  .Polaris-SettingAction__Setting{
    flex:1 0 35rem; }


  .Polaris-AccountConnection__TermsOfService{
    margin-top:2rem; }

  .Polaris-AccountConnection__Content > * + *{
    margin-top:0.8rem; }


  .Polaris-ActionMenu-MenuAction{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    font-size:1.5rem;
    font-weight:400;
    line-height:2rem;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    display:inline-flex;
    align-items:center;
    padding:0 1.2rem;
    min-height:3.6rem;
    text-decoration:none;
    color:var(--p-text, #454f5b); }
    .Polaris-ActionMenu-MenuAction:focus{
      outline:none; }
    @media (min-width: 40em){
      .Polaris-ActionMenu-MenuAction{
        font-size:1.4rem; } }
    .Polaris-ActionMenu-MenuAction:hover, .Polaris-ActionMenu-MenuAction:active{
      color:#212b36; }
      .Polaris-ActionMenu-MenuAction:hover .Polaris-ActionMenu-MenuAction__IconWrapper svg, .Polaris-ActionMenu-MenuAction:active .Polaris-ActionMenu-MenuAction__IconWrapper svg{
        fill:#212b36;
        color:white; }
    .Polaris-ActionMenu-MenuAction:focus::after, .Polaris-ActionMenu-MenuAction:active::after{
      background:rgba(33, 43, 54, 0.1); }
    .Polaris-ActionMenu-MenuAction::after{
      content:'';
      position:absolute;
      top:0.4rem;
      right:0.4rem;
      left:0.4rem;
      display:block;
      height:2.8rem;
      border-radius:3px;
      background:transparent;
      -webkit-backface-visibility:hidden;
              backface-visibility:hidden;
      will-change:background;
      transition-property:background;
      transition-duration:200ms;
      transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--disabled{
      color:#919eab;
      cursor:default;
      pointer-events:none; }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--disabled .Polaris-ActionMenu-MenuAction__IconWrapper svg{
        fill:#919eab; }
    .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage{
      position:relative;
      border-radius:var(--p-border-radius-base); }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage:hover{
        background:var(--p-action-secondary-hovered); }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage:focus{
        background-color:transparent; }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage:focus:not(:active)::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage:active{
        background:var(--p-action-secondary-pressed); }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage.Polaris-ActionMenu-MenuAction--disabled{
        color:var(--p-text-disabled);
        cursor:default;
        pointer-events:none; }
        .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage.Polaris-ActionMenu-MenuAction--disabled .Polaris-ActionMenu-MenuAction__IconWrapper svg{
          fill:var(--p-icon-disabled); }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage .Polaris-ActionMenu-MenuAction__IconWrapper svg{
        fill:--p-icon; }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage:hover, .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage:active{
        color:var(--p-text); }
        .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage:hover .Polaris-ActionMenu-MenuAction__IconWrapper svg, .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage:active .Polaris-ActionMenu-MenuAction__IconWrapper svg{
          fill:var(--p-icon); }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage:focus::after, .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage:active::after{
        background:transparent; }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--newDesignLanguage::after{
        height:auto; }

  .Polaris-ActionMenu-MenuAction__ContentWrapper{
    display:flex;
    align-items:center; }

  .Polaris-ActionMenu-MenuAction__IconWrapper{
    display:inline-block; }
    .Polaris-ActionMenu-MenuAction__IconWrapper svg{
      fill:var(--p-icon, #637381);
      color:var(--p-override-transparent, white); }
    .Polaris-ActionMenu-MenuAction__IconWrapper:first-child{
      margin-right:0.4rem; }
    .Polaris-ActionMenu-MenuAction__IconWrapper:last-child{
      margin-left:0.4rem;
      margin-right:-3px; }
    .Polaris-ActionMenu-MenuAction__IconWrapper.Polaris-ActionMenu-MenuAction--disabled svg{
      fill:var(--p-icon-disabled, #919eab); }


  .Polaris-ActionMenu-MenuGroup__Details{
    margin-top:-1.6rem;
    padding:1.6rem; }


  .Polaris-ActionMenu-RollupActions__RollupActivator button[type='button']{
    margin:0; }


  .Polaris-ActionMenu:not(.Polaris-ActionMenu--rollup){
    margin-left:-1.2rem; }

  .Polaris-ActionMenu__ActionsLayout{
    display:flex;
    flex-wrap:wrap;
    align-items:center; }
    .Polaris-ActionMenu__ActionsLayout > *{
      flex:0 0 auto; }


  .Polaris-OptionList-Checkbox{
    position:relative;
    width:100%;
    margin:var(--p-choice-margin, 0); }

  .Polaris-OptionList-Checkbox__Input{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:focus + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:active + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop{
    background:#5c6ac4; }

  .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:checked ~ .Polaris-OptionList-Checkbox__Icon{
    transform:translate(-50%, -50%) scale(1); }

  .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:focus + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:active + .Polaris-OptionList-Checkbox__Backdrop{
    background:#5c6ac4;
    box-shadow:0 0 0 1px #5c6ac4, 0 0 0 0 transparent;
    border-color:transparent; }
    .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:focus + .Polaris-OptionList-Checkbox__Backdrop::after, .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:active + .Polaris-OptionList-Checkbox__Backdrop::after{
      background:white; }
    @media (-ms-high-contrast: active){
      .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:focus + .Polaris-OptionList-Checkbox__Backdrop::after, .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:active + .Polaris-OptionList-Checkbox__Backdrop::after{
        border:1px dashed buttonText; } }

  .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop{
    background:#dfe3e8;
    box-shadow:none; }
    .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop::after{
      background:#f9fafb; }
    @media (-ms-high-contrast: active){
      .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop{
        color:grayText; } }

  .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:disabled ~ .Polaris-OptionList-Checkbox__Icon svg{
    fill:#919eab; }

  .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage).Polaris-OptionList-Checkbox--active .Polaris-OptionList-Checkbox__Backdrop{
    background:#5c6ac4;
    box-shadow:0 0 0 1px #5c6ac4, 0 0 0 0 transparent;
    border-color:transparent; }
    .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage).Polaris-OptionList-Checkbox--active .Polaris-OptionList-Checkbox__Backdrop::after{
      background:white; }
    @media (-ms-high-contrast: active){
      .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage).Polaris-OptionList-Checkbox--active .Polaris-OptionList-Checkbox__Backdrop::after{
        border:1px dashed buttonText; } }

  .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Backdrop{
    background-color:#c4cdd5;
    border:0.1rem solid transparent;
    box-shadow:0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    transition-property:box-shadow, background-color;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0; }
    .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Backdrop::after{
      content:'';
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      display:block;
      background:linear-gradient(to bottom, white, #f9fafb);
      border-radius:2px; }
    @media (-ms-high-contrast: active){
      .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Backdrop{
        background:transparent;
        border:2px solid buttonText;
        transition:none;
        color:rgba(223, 227, 232, 0.3); }
        .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Backdrop::after{
          top:-4px;
          right:-4px;
          bottom:-4px;
          left:-4px;
          background:none;
          border-radius:4px; } }

  .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Icon{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%) scale(0);
    transform-origin:50% 50%;
    transition:transform 100ms cubic-bezier(0.36, 0, 1, 1); }
    .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Icon svg{
      fill:#5c6ac4; }

  .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox--keyFocused + .Polaris-OptionList-Checkbox__Backdrop::after{
    box-shadow:0 0 0 0.2rem var(--p-focused); }

  .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate + .Polaris-OptionList-Checkbox__Backdrop{
    border-color:var(--p-interactive); }
    .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop::before, .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop::before, .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate + .Polaris-OptionList-Checkbox__Backdrop::before{
      opacity:1;
      transform:scale(1); }
      @media (-ms-high-contrast: active){
        .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop::before, .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop::before, .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate + .Polaris-OptionList-Checkbox__Backdrop::before{
          border:2px solid windowText; } }

  .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:active:not(:disabled) ~ .Polaris-OptionList-Checkbox__Icon, .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:checked ~ .Polaris-OptionList-Checkbox__Icon, .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate ~ .Polaris-OptionList-Checkbox__Icon{
    transition-timing-function:var(--p-ease-in);
    transform:translate(-50%, -50%) scale(1);
    opacity:1;
    transition:opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease); }

  .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop{
    border-color:var(--p-border-secondary-disabled); }
    .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop::before{
      background-color:var(--p-action-secondary-disabled); }
    .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop:hover{
      cursor:default; }

  .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:disabled:checked + .Polaris-OptionList-Checkbox__Backdrop{
    background:var(--p-border-secondary-disabled); }
    .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Input:disabled:checked + .Polaris-OptionList-Checkbox__Backdrop::before{
      background:var(--p-border-secondary-disabled); }

  .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage.Polaris-OptionList-Checkbox--active .Polaris-OptionList-Checkbox__Backdrop::after{
    box-shadow:0 0 0 0.2rem var(--p-focused); }

  .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Backdrop{
    position:relative;
    border:var(--p-control-border-width) solid var(--p-border-secondary);
    background-color:var(--p-surface);
    border-radius:var(--p-border-radius-base);
    position:relative;
    display:block;
    width:100%;
    height:100%;
    position:relative; }
    .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Backdrop::before{
      content:'';
      position:absolute;
      top:calc(-1*var(--p-control-border-width));
      right:calc(-1*var(--p-control-border-width));
      bottom:calc(-1*var(--p-control-border-width));
      left:calc(-1*var(--p-control-border-width));
      border-radius:var(--p-border-radius-base);
      background-color:var(--p-interactive);
      opacity:0;
      transform:scale(0.25);
      transition:opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease); }
    .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Backdrop.Polaris-OptionList-Checkbox--hover, .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Backdrop:hover{
      cursor:pointer;
      border-color:var(--p-border-secondary-hovered); }
    .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Backdrop::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:calc(-1*(var(--p-control-border-width) + 0.1rem));
      right:calc(-1*(var(--p-control-border-width) + 0.1rem));
      bottom:calc(-1*(var(--p-control-border-width) + 0.1rem));
      left:calc(-1*(var(--p-control-border-width) + 0.1rem));
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 calc(-1*(var(--p-control-border-width) + 0.1rem)) var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }

  .Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--newDesignLanguage .Polaris-OptionList-Checkbox__Icon{
    transform:translate(-50%, -50%) scale(0.25);
    opacity:0;
    transition:opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease); }

  .Polaris-OptionList-Checkbox__Icon{
    position:absolute;
    top:50%;
    left:50%;
    transform-origin:50% 50%;
    pointer-events:none; }
    .Polaris-OptionList-Checkbox__Icon svg{
      fill:var(--p-icon-on-interactive, #5c6ac4); }
    @media (-ms-high-contrast: active){
      .Polaris-OptionList-Checkbox__Icon{
        fill:windowText; } }


  .Polaris-OptionList-Option{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    color:inherit;
    text-decoration:none;
    width:100%;
    min-height:3.6rem;
    text-align:left; }
    .Polaris-OptionList-Option:focus{
      outline:none; }
    .Polaris-OptionList-Option:visited{
      color:inherit; }

  .Polaris-OptionList-Option__SingleSelectOption{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    text-align:left; }
    .Polaris-OptionList-Option__SingleSelectOption:focus{
      outline:none; }
    .Polaris-OptionList-Option__SingleSelectOption:not(.Polaris-OptionList-Option--disabled){
      color:inherit; }
    .Polaris-OptionList-Option__SingleSelectOption .Polaris-OptionList-Option__Media{
      padding:0 0.8rem 0 0; }

  .Polaris-OptionList-Option__Label,
  .Polaris-OptionList-Option__SingleSelectOption{
    display:flex;
    align-items:flex-start;
    width:100%;
    padding:0.8rem 1.6rem; }

  .Polaris-OptionList-Option__Checkbox{
    box-sizing:border-box;
    display:flex;
    flex-shrink:0;
    width:var(--p-choice-size, 1.6rem);
    height:var(--p-choice-size, 1.6rem);
    margin-top:var(--p-override-none, 0.2rem);
    margin-right:0.8rem;
    margin-left:calc(-1*var(--p-choice-margin, 0)); }

  .Polaris-OptionList-Option--disabled .Polaris-OptionList-Option__Media svg{
    fill:var(--p-icon-disabled, #919eab);
    color:white; }

  .Polaris-OptionList-Option__Media{
    padding:0 0.8rem; }
    .Polaris-OptionList-Option__Media svg{
      fill:var(--p-icon, #454f5b);
      color:white; }

  .Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage):hover{
    background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }

  .Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--focused{
    box-shadow:inset 0.2rem 0 0 #5c6ac4;
    background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--focused:hover{
      box-shadow:inset 0.2rem 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }

  .Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--disabled{
    background-image:linear-gradient(#f9fafb, #f9fafb); }

  .Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--select{
    background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)); }
    .Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--select.Polaris-OptionList-Option--focused{
      box-shadow:inset 0.2rem 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
      .Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--select.Polaris-OptionList-Option--focused:hover{
        box-shadow:inset 0.2rem 0 0 #5c6ac4;
        background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }

  .Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--active{
    background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }

  .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage{
    border-radius:var(--p-border-radius-base);
    margin-top:0.4rem; }
    .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__Label,
    .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption{
      cursor:pointer;
      border-radius:var(--p-border-radius-base);
      padding:0.8rem; }
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__Label:hover:not(.Polaris-OptionList-Option--disabled),
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption:hover:not(.Polaris-OptionList-Option--disabled){
        background:var(--p-surface-hovered); }
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__Label.Polaris-OptionList-Option--select, .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__Label.Polaris-OptionList-Option--select:hover:not(.Polaris-OptionList-Option--disabled), .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__Label:active:not(.Polaris-OptionList-Option--disabled),
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select,
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select:hover:not(.Polaris-OptionList-Option--disabled),
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption:active:not(.Polaris-OptionList-Option--disabled){
        background:var(--p-surface-selected); }
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__Label.Polaris-OptionList-Option--disabled,
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--disabled{
        background:var(--p-surface-disabled);
        cursor:default;
        color:var(--p-text-disabled); }
    .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption{
      position:relative; }
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--active::after, .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--focused:not(:active)::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-OptionList-Option.Polaris-OptionList-Option--newDesignLanguage .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select::before{
        background:var(--p-interactive);
        content:'';
        position:absolute;
        top:0;
        left:-0.8rem;
        height:100%;
        display:block;
        width:0.3rem;
        border-top-right-radius:var(--p-border-radius-base);
        border-bottom-right-radius:var(--p-border-radius-base); }


  .Polaris-OptionList{
    margin:0;
    padding:0;
    list-style:none; }
    .Polaris-OptionList.Polaris-OptionList--newDesignLanguage{
      padding:0.8rem; }

  .Polaris-OptionList__Options{
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-OptionList__Title{
    font-size:1.3rem;
    font-weight:600;
    line-height:1.6rem;
    text-transform:uppercase;
    padding:0.8rem 1.6rem;
    border-bottom:var(--p-override-none, 0.1rem solid var(--p-border-subdued, #dfe3e8)); }
    @media (min-width: 40em){
      .Polaris-OptionList__Title{
        font-size:1.2rem; } }
    .Polaris-OptionList--newDesignLanguage .Polaris-OptionList__Title{
      padding:0.8rem;
      color:var(--p-text-subdued); }


  .Polaris-Autocomplete-ComboBox__EmptyState{
    padding:0.8rem 1.6rem; }


  .Polaris-Label{
    -webkit-tap-highlight-color:transparent; }

  .Polaris-Label--hidden{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-Label__Text{
    font-size:1.5rem;
    font-weight:400;
    line-height:2rem;
    text-transform:initial;
    letter-spacing:initial;
    display:block;
    flex:1 1 auto;
    color:currentColor;
    -webkit-tap-highlight-color:transparent; }
    @media (min-width: 40em){
      .Polaris-Label__Text{
        font-size:1.4rem; } }


  .Polaris-InlineError{
    display:flex;
    color:var(--p-text-critical, #bf0711);
    fill:var(--p-icon-critical, #bf0711); }

  .Polaris-InlineError__Icon{
    fill:currentColor;
    margin-left:-0.2rem;
    margin-right:0.6rem; }


  .Polaris-Labelled--hidden > .Polaris-Labelled__LabelWrapper{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-Labelled__LabelWrapper{
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    align-items:baseline;
    margin-bottom:0.4rem; }

  .Polaris-Labelled__HelpText{
    font-size:1.5rem;
    font-weight:400;
    line-height:2rem;
    text-transform:initial;
    letter-spacing:initial;
    color:var(--p-text-subdued, #637381);
    margin-top:0.4rem; }
    @media (min-width: 40em){
      .Polaris-Labelled__HelpText{
        font-size:1.4rem; } }

  .Polaris-Labelled__Error{
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word;
    margin-top:0.4rem; }

  .Polaris-Labelled__Action{
    flex:0 0 auto; }


  .Polaris-Connected{
    position:relative;
    display:flex; }

  .Polaris-Connected__Item{
    position:relative;
    z-index:10;
    flex:0 0 auto; }
    .Polaris-Connected__Item:not(:first-child){
      margin-left:-1px; }

  .Polaris-Connected__Item--primary{
    z-index:20;
    flex:1 1 auto; }
    .Polaris-Connected__Item--primary:not(:first-child) *{
      border-top-left-radius:0 !important;
      border-bottom-left-radius:0 !important; }
    .Polaris-Connected__Item--primary:not(:last-child) *{
      border-top-right-radius:0 !important;
      border-bottom-right-radius:0 !important; }

  .Polaris-Connected__Item--connection:first-child *{
    border-top-right-radius:0 !important;
    border-bottom-right-radius:0 !important; }
    .Polaris-Connected__Item--connection:first-child *::after{
      border-top-right-radius:0 !important;
      border-bottom-right-radius:0 !important; }

  .Polaris-Connected__Item--connection:last-child *{
    border-top-left-radius:0 !important;
    border-bottom-left-radius:0 !important; }
    .Polaris-Connected__Item--connection:last-child *::after{
      border-top-left-radius:0 !important;
      border-bottom-left-radius:0 !important; }

  .Polaris-Connected__Item--focused{
    z-index:30; }


  .Polaris-TextField{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    display:flex;
    align-items:center;
    color:#637381;
    cursor:text; }
    @media (min-width: 40em){
      .Polaris-TextField{
        font-size:1.4rem; } }
    .Polaris-TextField svg{
      fill:#637381; }

  .Polaris-TextField--multiline{
    padding:0;
    flex-wrap:wrap; }
    .Polaris-TextField--multiline > .Polaris-TextField__Input{
      overflow:auto;
      padding-left:1.2rem;
      padding-right:1.2rem;
      resize:none; }

  .Polaris-TextField--hasValue{
    color:#637381; }

  .Polaris-TextField--focus > .Polaris-TextField__Input,
  .Polaris-TextField__Input:focus{
    outline:none;
    border:0.1rem solid transparent; }
    .Polaris-TextField--focus > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after,
    .Polaris-TextField__Input:focus ~ .Polaris-TextField__Backdrop::after{
      opacity:1; }

  .Polaris-TextField--error .Polaris-TextField__Input::-webkit-input-placeholder{
    color:#9c9798; }

  .Polaris-TextField--error .Polaris-TextField__Input::-ms-input-placeholder{
    color:#9c9798; }

  .Polaris-TextField--error .Polaris-TextField__Input::placeholder{
    color:#9c9798; }

  .Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop{
    background-color:#fbeae5;
    border-color:#bf0711; }
    .Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after{
      border-color:#bf0711; }

  .Polaris-TextField--readOnly > .Polaris-TextField__Backdrop{
    background-color:#f9fafb; }

  .Polaris-TextField--disabled{
    color:#919eab;
    cursor:initial; }
    .Polaris-TextField--disabled > .Polaris-TextField__Backdrop{
      background-color:#f9fafb;
      box-shadow:none; }
    .Polaris-TextField--disabled svg{
      fill:#919eab; }

  .Polaris-TextField__Input{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    z-index:20;
    display:block;
    flex:1 1;
    width:100%;
    min-width:0;
    min-height:3.6rem;
    margin:0;
    padding:0.5rem 1.2rem;
    background:none;
    border:0.1rem solid transparent;
    font-family:inherit;
    font-size:inherit;
    font-weight:inherit;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none; }
    @media (min-width: 40em){
      .Polaris-TextField__Input{
        font-size:1.4rem; } }
    .Polaris-TextField__Prefix + .Polaris-TextField__Input{
      padding-left:0; }
    .Polaris-TextField__Input:disabled{
      background:none;
      border:0.1rem solid transparent;
      color:currentColor;
      opacity:1;
      -webkit-text-fill-color:currentColor; }
    .Polaris-TextField__Input:invalid{
      box-shadow:none; }
    .Polaris-TextField__Input::-webkit-input-placeholder{
      color:#919eab; }
    .Polaris-TextField__Input::-ms-input-placeholder{
      color:#919eab; }
    .Polaris-TextField__Input::placeholder{
      color:#919eab; }
    .Polaris-TextField__Input[type='number']{
      -webkit-appearance:textfield;
         -moz-appearance:textfield;
              appearance:textfield; }
      .Polaris-TextField__Input[type='number']::-webkit-outer-spin-button, .Polaris-TextField__Input[type='number']::-webkit-inner-spin-button{
        -webkit-appearance:none;
                appearance:none;
        margin:0; }

  .Polaris-TextField__Input--hasClearButton[type='search']::-webkit-search-cancel-button{
    -webkit-appearance:none;
            appearance:none; }

  .Polaris-TextField__Input--suffixed{
    padding-right:0; }

  .Polaris-TextField__Input--alignRight{
    text-align:right; }

  .Polaris-TextField__Input--alignLeft{
    text-align:left; }

  .Polaris-TextField__Input--alignCenter{
    text-align:center; }

  .Polaris-TextField__Backdrop{
    position:absolute;
    z-index:10;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:white;
    border:0.1rem solid var(--p-border, #c4cdd5);
    border-radius:var(--p-border-radius-base, 3px);
    box-shadow:inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
    pointer-events:none; }
    .Polaris-TextField__Backdrop::after{
      content:'';
      position:absolute;
      top:-0.2rem;
      right:-0.2rem;
      bottom:-0.2rem;
      left:-0.2rem;
      border:var(--p-override-none, 0.2rem solid #5c6ac4);
      border-radius:inherit;
      opacity:0;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1);
      pointer-events:none; }

  .Polaris-TextField__Prefix,
  .Polaris-TextField__Suffix{
    position:relative;
    z-index:20;
    flex:0 0 auto;
    color:currentColor;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none; }

  .Polaris-TextField__Prefix{
    margin-left:1.2rem;
    margin-right:0.8rem; }

  .Polaris-TextField__Suffix{
    margin-left:0.25em;
    margin-right:1.2rem; }

  .Polaris-TextField__CharacterCount{
    color:var(--p-text-subdued, #637381);
    z-index:20;
    margin:0 1.2rem 0 0.25em;
    pointer-events:none;
    text-align:right; }

  .Polaris-TextField__AlignFieldBottom{
    align-self:flex-end;
    width:100%;
    padding-bottom:0.8rem; }

  .Polaris-TextField__ClearButton{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    z-index:20;
    margin:0 1.2rem 0 0.25em; }
    .Polaris-TextField__ClearButton:focus{
      outline:none; }
    .Polaris-TextField__ClearButton:hover:enabled svg, .Polaris-TextField__ClearButton:focus:enabled svg{
      fill:#637381; }
    .Polaris-TextField__ClearButton:hover:enabled img, .Polaris-TextField__ClearButton:focus:enabled img{
      -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(8%) saturate(825%) hue-rotate(166deg) brightness(95%) contrast(90%);
              filter:brightness(0) saturate(100%) invert(45%) sepia(8%) saturate(825%) hue-rotate(166deg) brightness(95%) contrast(90%); }
    .Polaris-TextField__ClearButton:disabled{
      cursor:default; }

  .Polaris-TextField__Spinner{
    z-index:20;
    display:flex;
    align-self:stretch;
    flex-direction:column;
    width:2.2rem;
    margin:1px;
    color:#212b36;
    cursor:pointer; }

  .Polaris-TextField__SpinnerIcon{
    height:1.2rem;
    width:1.2rem; }

  .Polaris-TextField__Resizer{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    height:0;
    visibility:hidden;
    overflow:hidden; }

  .Polaris-TextField__DummyInput{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem;
    text-transform:initial;
    letter-spacing:initial;
    padding:0.5rem 1.2rem;
    border:0.1rem solid transparent;
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word;
    white-space:pre-wrap; }
    @media (min-width: 40em){
      .Polaris-TextField__DummyInput{
        font-size:1.4rem; } }

  .Polaris-TextField__Segment{
    display:flex;
    flex:1 1;
    justify-content:center;
    align-items:center;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    background:linear-gradient(to bottom, white, #f9fafb);
    border:none;
    border-left:0.1rem solid var(--p-border, #c4cdd5);
    transition:background 100ms cubic-bezier(0.64, 0, 0.35, 1), box-shadow 100ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-TextField__Segment:focus{
      outline:none; }
    .Polaris-TextField__Segment:active{
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      box-shadow:inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2); }
    .Polaris-TextField__Segment:not(:first-child){
      margin-top:-1px;
      border-top:0.1rem solid var(--p-border, #c4cdd5); }
    .Polaris-TextField__Segment:first-child{
      border-top-right-radius:3px; }
    .Polaris-TextField__Segment:last-child{
      border-bottom-right-radius:3px; }

  .Polaris-TextField--newDesignLanguage.Polaris-TextField{
    color:var(--p-text); }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField svg{
      fill:var(--p-icon); }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField--hasValue{
      color:var(--p-text); }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField.Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop{
      background-color:var(--p-surface-critical-subdued);
      border-color:var(--p-border-critical); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField.Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after{
        border-color:var(--p-focused); }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField.Polaris-TextField--readOnly > .Polaris-TextField__Backdrop{
      background-color:var(--p-action-secondary-disabled); }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField.Polaris-TextField--disabled{
      color:var(--p-text-disabled); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField.Polaris-TextField--disabled > .Polaris-TextField__Backdrop{
        background-color:var(--p-action-secondary-disabled); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField.Polaris-TextField--disabled svg{
        fill:var(--p-icon-disabled); }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Input{
      caret-color:var(--p-text);
      color:var(--p-text); }
      .Polaris-TextField--hasValue .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Input{
        color:var(--p-text); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Input::-webkit-input-placeholder{
        color:var(--p-text-subdued); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Input::-ms-input-placeholder{
        color:var(--p-text-subdued); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Input::placeholder{
        color:var(--p-text-subdued); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Input:focus ~ .Polaris-TextField__Backdrop::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Backdrop{
      position:relative;
      background-color:var(--p-surface);
      position:absolute; }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Backdrop::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.2rem;
        right:-0.2rem;
        bottom:-0.2rem;
        left:-0.2rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.2rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Backdrop::after{
        opacity:1; }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Backdrop--connectedLeft::after{
      border-top-left-radius:0;
      border-bottom-left-radius:0; }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Backdrop--connectedRight::after{
      border-top-right-radius:0;
      border-bottom-right-radius:0; }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Spinner{
      --p-text-field-spinner-offset-large:calc(var(--p-text-field-spinner-offset) + 0.1rem);
      margin:var(--p-text-field-spinner-offset-large);
      color:var(--p-icon); }
    .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Segment{
      --p-text-field-spinner-border-radius:calc(var(--p-border-radius-base) - var(--p-text-field-spinner-offset));
      background:var(--p-action-secondary);
      border-radius:var(--p-text-field-spinner-border-radius);
      border-left:var(--p-override-none); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Segment:hover{
        background:var(--p-action-secondary-hovered); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Segment:active{
        background:var(--p-action-secondary-pressed);
        box-shadow:none; }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Segment:not(:first-child){
        margin-top:none;
        border-top:none; }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Segment:first-child{
        border-top-right-radius:var(--p-text-field-spinner-border-radius) !important;
        margin-bottom:var(--p-text-field-spinner-offset); }
      .Polaris-TextField--newDesignLanguage.Polaris-TextField .Polaris-TextField__Segment:last-child{
        border-bottom-right-radius:var(--p-text-field-spinner-border-radius) !important; }


  .Polaris-Autocomplete__Loading{
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    padding:0.8rem 1.6rem; }


  [data-lock-scrolling]{
    overflow-y:scroll;
    margin:0; }
    [data-lock-scrolling] [data-lock-scrolling-wrapper]{
      overflow:hidden;
      height:100%; }


  .Polaris-Backdrop{
    position:fixed;
    z-index:518;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:block;
    background-color:var(--p-backdrop, rgba(33, 43, 54, 0.4));
    animation:Polaris-Backdrop__fade--in 200ms 1 forwards;
    opacity:1;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity; }

  .Polaris-Backdrop--transparent{
    background-color:transparent; }

  .Polaris-Backdrop--belowNavigation{
    z-index:515; }

  @keyframes Polaris-Backdrop__fade--in{
    0%{
      opacity:0; }
    100%{
      opacity:1; } }


  .Polaris-Banner{
    position:relative;
    display:flex; }

  .Polaris-Banner--withinContentContainer{
    border-radius:var(--p-border-radius-wide, 3px);
    padding:0.8rem 1.2rem;
    --p-banner-background:var(--p-background);
    --p-banner-border:var(--p-banner-border-default);
    transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
    transition-delay:100ms;
    border-radius:var(--p-border-radius-wide, inherit);
    box-shadow:var(--p-banner-border, none);
    background-color:var(--p-banner-background, #f4f6f8);
    position:relative; }
    @media (-ms-high-contrast: active){
      .Polaris-Banner--withinContentContainer{
        box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1rem 0.1rem windowText; } }
    .Polaris-Banner--withinContentContainer::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
    .Polaris-Banner--withinContentContainer:focus{
      outline:none; }
    .Polaris-Banner--withinContentContainer.Polaris-Banner--keyFocused{
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border, #637381), inset 0 0 0 3px var(--p-border, #637381), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--keyFocused::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess{
      --p-banner-background:var(--p-surface-success-subdued);
      --p-banner-border:var(--p-banner-border-success);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      background-color:var(--p-banner-background, #eff7ed);
      position:relative; }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1rem 0.1rem windowText; } }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess:focus{
        outline:none; }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess.Polaris-Banner--keyFocused{
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-success, #50b83c), inset 0 0 0 3px var(--p-border-success, #50b83c), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess.Polaris-Banner--keyFocused::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo{
      --p-banner-background:var(--p-surface-highlight-subdued);
      --p-banner-border:var(--p-banner-border-highlight);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      background-color:var(--p-banner-background, #eef9f9);
      position:relative; }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1rem 0.1rem windowText; } }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo:focus{
        outline:none; }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo.Polaris-Banner--keyFocused{
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-highlight, #47c1bf), inset 0 0 0 3px var(--p-border-highlight, #47c1bf), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo.Polaris-Banner--keyFocused::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning{
      --p-banner-background:var(--p-surface-warning-subdued);
      --p-banner-border:var(--p-banner-border-warning);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      background-color:var(--p-banner-background, #fdf7e3);
      position:relative; }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1rem 0.1rem windowText; } }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning:focus{
        outline:none; }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning.Polaris-Banner--keyFocused{
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-warning, #eec200), inset 0 0 0 3px var(--p-border-warning, #eec200), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning.Polaris-Banner--keyFocused::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical{
      --p-banner-background:var(--p-surface-critical-subdued);
      --p-banner-border:var(--p-banner-border-critical);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      background-color:var(--p-banner-background, #fdf3f0);
      position:relative; }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1rem 0.1rem windowText; } }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical:focus{
        outline:none; }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical.Polaris-Banner--keyFocused{
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-critical, #de3618), inset 0 0 0 3px var(--p-border-critical, #de3618), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical.Polaris-Banner--keyFocused::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Banner--withinContentContainer + .Polaris-Banner{
      margin-top:0.8rem; }
    .Polaris-Banner--withinContentContainer .Polaris-Banner__Ribbon{
      padding-right:1.2rem; }
    .Polaris-Banner--withinContentContainer .Polaris-Banner__Actions{
      padding:1.2rem 0 0.4rem 0; }
    .Polaris-Banner--withinContentContainer .Polaris-Banner__Dismiss{
      right:1.2rem;
      top:1.2rem;
      position:absolute; }

  .Polaris-Banner--withinPage{
    border-radius:0 0 3px 3px;
    padding:1.6rem;
    --p-banner-background:var(--p-background);
    --p-banner-border:var(--p-banner-border-default);
    transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
    transition-delay:100ms;
    border-radius:var(--p-border-radius-wide, inherit);
    box-shadow:var(--p-banner-border, none);
    box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border, #637381), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
    background-color:var(--p-banner-background, #f4f6f8);
    position:relative; }
    @media (-ms-high-contrast: active){
      .Polaris-Banner--withinPage{
        box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1rem 0.1rem windowText; } }
    .Polaris-Banner--withinPage::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
    .Polaris-Banner--withinPage:focus{
      outline:none; }
    .Polaris-Banner--withinPage.Polaris-Banner--keyFocused{
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border, #637381), inset 0 0 0 3px var(--p-border, #637381), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
      .Polaris-Banner--withinPage.Polaris-Banner--keyFocused::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess{
      --p-banner-background:var(--p-surface-success-subdued);
      --p-banner-border:var(--p-banner-border-success);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-success, #50b83c), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
      background-color:var(--p-banner-background, #eff7ed);
      position:relative; }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1rem 0.1rem windowText; } }
      .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
      .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess:focus{
        outline:none; }
      .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess.Polaris-Banner--keyFocused{
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-success, #50b83c), inset 0 0 0 3px var(--p-border-success, #50b83c), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
        .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess.Polaris-Banner--keyFocused::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Banner--withinPage.Polaris-Banner--statusInfo{
      --p-banner-background:var(--p-surface-highlight-subdued);
      --p-banner-border:var(--p-banner-border-highlight);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-highlight, #47c1bf), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
      background-color:var(--p-banner-background, #eef9f9);
      position:relative; }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinPage.Polaris-Banner--statusInfo{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1rem 0.1rem windowText; } }
      .Polaris-Banner--withinPage.Polaris-Banner--statusInfo::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
      .Polaris-Banner--withinPage.Polaris-Banner--statusInfo:focus{
        outline:none; }
      .Polaris-Banner--withinPage.Polaris-Banner--statusInfo.Polaris-Banner--keyFocused{
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-highlight, #47c1bf), inset 0 0 0 3px var(--p-border-highlight, #47c1bf), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
        .Polaris-Banner--withinPage.Polaris-Banner--statusInfo.Polaris-Banner--keyFocused::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Banner--withinPage.Polaris-Banner--statusWarning{
      --p-banner-background:var(--p-surface-warning-subdued);
      --p-banner-border:var(--p-banner-border-warning);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-warning, #eec200), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
      background-color:var(--p-banner-background, #fdf7e3);
      position:relative; }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinPage.Polaris-Banner--statusWarning{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1rem 0.1rem windowText; } }
      .Polaris-Banner--withinPage.Polaris-Banner--statusWarning::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
      .Polaris-Banner--withinPage.Polaris-Banner--statusWarning:focus{
        outline:none; }
      .Polaris-Banner--withinPage.Polaris-Banner--statusWarning.Polaris-Banner--keyFocused{
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-warning, #eec200), inset 0 0 0 3px var(--p-border-warning, #eec200), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
        .Polaris-Banner--withinPage.Polaris-Banner--statusWarning.Polaris-Banner--keyFocused::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Banner--withinPage.Polaris-Banner--statusCritical{
      --p-banner-background:var(--p-surface-critical-subdued);
      --p-banner-border:var(--p-banner-border-critical);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-critical, #de3618), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
      background-color:var(--p-banner-background, #fdf3f0);
      position:relative; }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinPage.Polaris-Banner--statusCritical{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1rem 0.1rem windowText; } }
      .Polaris-Banner--withinPage.Polaris-Banner--statusCritical::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
      .Polaris-Banner--withinPage.Polaris-Banner--statusCritical:focus{
        outline:none; }
      .Polaris-Banner--withinPage.Polaris-Banner--statusCritical.Polaris-Banner--keyFocused{
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-border-critical, #de3618), inset 0 0 0 3px var(--p-border-critical, #de3618), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)); }
        .Polaris-Banner--withinPage.Polaris-Banner--statusCritical.Polaris-Banner--keyFocused::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Banner--withinPage + .Polaris-Banner{
      margin-top:2rem; }
    .Polaris-Banner--withinPage .Polaris-Banner__Ribbon{
      padding-right:1.6rem; }
    .Polaris-Banner--withinPage .Polaris-Banner__Actions{
      padding-top:1.6rem; }
    .Polaris-Banner--withinPage .Polaris-Banner__Dismiss{
      right:1.6rem;
      top:2rem;
      position:absolute; }

  .Polaris-Banner--hasDismiss{
    padding-right:5.6rem; }

  .Polaris-Banner__Heading{
    padding-top:0.2rem;
    word-break:break-word; }

  .Polaris-Banner__Content{
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word;
    padding:0.4rem 0; }

  .Polaris-Banner__Ribbon{
    flex:0 0 3.2rem; }

  .Polaris-Banner__PrimaryAction{
    margin-right:0.6rem; }

  .Polaris-Banner__SecondaryAction{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    color:inherit;
    text-decoration:none;
    display:inline-block;
    margin:-0.8rem -0.6rem;
    padding:0.8rem 1.2rem;
    color:var(--p-text, #212b36);
    padding-left:0.6rem; }
    .Polaris-Banner__SecondaryAction:focus{
      outline:none; }
    .Polaris-Banner__SecondaryAction:visited{
      color:inherit; }
    .Polaris-Banner__SecondaryAction:hover > .Polaris-Banner__Text::after{
      opacity:0.75; }
    .Polaris-Banner__SecondaryAction:active > .Polaris-Banner__Text::after{
      opacity:0; }
    .Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text{
      padding:2px 5px;
      margin:-2px -5px;
      background:rgba(33, 43, 54, 0.1);
      border-radius:3px;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }
      @media (-ms-high-contrast: active){
        .Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text{
          outline:2px dotted; } }
      .Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text::after{
        opacity:0; }

  .Polaris-Banner__Text{
    position:relative; }
    .Polaris-Banner__Text::after, .Polaris-Banner__Text::before{
      content:'';
      position:absolute; }
    .Polaris-Banner__Text::after{
      bottom:0;
      left:0;
      right:0;
      display:block;
      border:0.1rem solid currentColor;
      opacity:0.25;
      -webkit-backface-visibility:hidden;
              backface-visibility:hidden;
      will-change:opacity;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }


  .Polaris-Breadcrumbs__Breadcrumb{
    font-size:1.5rem;
    font-weight:400;
    line-height:2rem;
    text-transform:initial;
    letter-spacing:initial;
    color:var(--p-text-subdued, #637381);
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    position:relative;
    display:flex;
    align-items:center;
    min-height:3.6rem;
    margin:-0.4rem -0.8rem -0.4rem -0.4rem;
    color:var(--p-text-subdued, #637381);
    text-decoration:none; }
    @media (min-width: 40em){
      .Polaris-Breadcrumbs__Breadcrumb{
        font-size:1.4rem; } }
    .Polaris-Breadcrumbs__Breadcrumb:focus{
      outline:none; }
    .Polaris-Breadcrumbs__Breadcrumb:hover, .Polaris-Breadcrumbs__Breadcrumb:active{
      color:var(--p-text, #212b36);
      text-decoration:none; }
      .Polaris-Breadcrumbs__Breadcrumb:hover .Polaris-Breadcrumbs__Icon svg, .Polaris-Breadcrumbs__Breadcrumb:active .Polaris-Breadcrumbs__Icon svg{
        fill:var(--p-icon, #212b36); }
    .Polaris-Breadcrumbs__Breadcrumb:focus{
      outline:none; }
      .Polaris-Breadcrumbs__Breadcrumb:focus .Polaris-Breadcrumbs__ContentWrapper{
        background:var(--p-override-transparent, rgba(33, 43, 54, 0.1)); }
    .Polaris-Breadcrumbs__Breadcrumb:active .Polaris-Breadcrumbs__ContentWrapper{
      background:var(--p-surface-subdued, rgba(33, 43, 54, 0.1)); }
    .Polaris-Breadcrumbs__Breadcrumb:focus:not(:active) .Polaris-Breadcrumbs__ContentWrapper::after{
      box-shadow:0 0 0 0.2rem var(--p-focused); }

  .Polaris-Breadcrumbs__ContentWrapper{
    position:relative;
    display:flex;
    align-items:center;
    padding:0.8rem 0.8rem;
    background:transparent;
    height:2.8rem;
    border-radius:var(--p-border-radius-base, 3px);
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:background;
    transition:background 200ms cubic-bezier(0.64, 0, 0.35, 1);
    position:relative; }
    .Polaris-Breadcrumbs__ContentWrapper::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }

  .Polaris-Breadcrumbs__Content{
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    position:relative; }

  .Polaris-Breadcrumbs__Icon{
    width:2rem;
    height:2rem;
    margin:-1rem 0 -1rem -0.8rem;
    margin:0 0 0 -0.8rem; }
    .Polaris-Breadcrumbs__Icon svg{
      fill:var(--p-icon-subdued, #637381); }


  .Polaris-TextContainer > *:not(:first-child){
    margin-top:1.6rem; }

  .Polaris-TextContainer--spacingTight > *:not(:first-child){
    margin-top:0.8rem; }

  .Polaris-TextContainer--spacingLoose > *:not(:first-child){
    margin-top:2rem; }


  .Polaris-CalloutCard{
    display:flex;
    align-items:center; }

  .Polaris-CalloutCard__Image{
    display:none;
    flex:0 0 auto;
    width:10rem; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-CalloutCard__Image{
        display:block;
        margin-left:2rem; } }
    @media (min-width: 30.625em){
      .Polaris-CalloutCard__Image{
        display:block;
        margin-left:2rem; } }

  .Polaris-CalloutCard__DismissImage{
    margin-right:2rem; }

  .Polaris-CalloutCard__Content{
    flex:1 1 auto; }

  .Polaris-CalloutCard__Title{
    margin-bottom:2rem; }

  .Polaris-CalloutCard__Buttons{
    margin-top:2rem; }

  .Polaris-CalloutCard__Container{
    position:relative; }

  .Polaris-CalloutCard__Dismiss{
    right:1.6rem;
    top:1.6rem;
    position:absolute; }


  .Polaris-Caption{
    font-size:1.3rem;
    font-weight:400;
    line-height:2rem;
    margin:0; }
    @media (min-width: 40em){
      .Polaris-Caption{
        font-size:1.2rem;
        line-height:1.6rem; } }


  .Polaris-Choice{
    display:inline-flex;
    justify-content:flex-start;
    padding:0.4rem 0;
    cursor:pointer; }

  .Polaris-Choice--labelHidden{
    padding:0; }
    .Polaris-Choice--labelHidden > .Polaris-Choice__Label{
      position:absolute !important;
      top:0;
      clip:rect(1px, 1px, 1px, 1px) !important;
      overflow:hidden !important;
      height:1px !important;
      width:1px !important;
      padding:0 !important;
      border:0 !important; }
    .Polaris-Choice--labelHidden .Polaris-Choice__Control{
      margin-top:0;
      margin-right:0; }

  .Polaris-Choice--disabled{
    cursor:default; }
    .Polaris-Choice--disabled > .Polaris-Choice__Label{
      color:#919eab; }
      .Polaris-Choice--disabled > .Polaris-Choice__Label:hover{
        cursor:default; }
      @media (-ms-high-contrast: active){
        .Polaris-Choice--disabled > .Polaris-Choice__Label{
          color:grayText; } }

  .Polaris-Choice__Control{
    display:flex;
    flex:0 0 auto;
    align-items:stretch;
    width:var(--p-choice-size, 1.6rem);
    height:var(--p-choice-size, 1.6rem);
    margin-top:var(--p-override-none, 0.2rem);
    margin-right:0.8rem; }
    .Polaris-Choice__Control > *{
      width:100%; }

  .Polaris-Choice__Label{
    font-size:1.5rem;
    font-weight:400;
    line-height:2rem;
    text-transform:initial;
    letter-spacing:initial;
    -webkit-tap-highlight-color:transparent; }
    @media (min-width: 40em){
      .Polaris-Choice__Label{
        font-size:1.4rem; } }
    .Polaris-Choice__Label:hover{
      cursor:pointer; }

  .Polaris-Choice__Descriptions{
    padding-left:calc(0.8rem + var(--p-choice-size, 1.6rem)); }

  .Polaris-Choice__HelpText{
    font-size:1.5rem;
    font-weight:400;
    line-height:2rem;
    text-transform:initial;
    letter-spacing:initial;
    color:var(--p-text-subdued, #637381);
    margin-bottom:0.4rem; }
    @media (min-width: 40em){
      .Polaris-Choice__HelpText{
        font-size:1.4rem; } }


  .Polaris-Checkbox{
    position:relative;
    margin:var(--p-choice-margin, 0); }

  .Polaris-Checkbox__Input{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop{
    background:#5c6ac4; }
    .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop::after, .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop::after, .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::after, .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop::after{
      background:white; }

  .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:checked ~ .Polaris-Checkbox__Icon, .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate ~ .Polaris-Checkbox__Icon{
    transform:translate(-50%, -50%) scale(1); }

  .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop{
    background:#5c6ac4;
    box-shadow:0 0 0 1px #5c6ac4, 0 0 0 0 transparent;
    border-color:transparent; }
    .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop::after, .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop::after{
      background:white; }
    @media (-ms-high-contrast: active){
      .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop::after, .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop::after{
        border:1px dashed buttonText; } }

  .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop{
    background:#dfe3e8;
    box-shadow:none; }
    .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop::after{
      background:#f9fafb; }
    @media (-ms-high-contrast: active){
      .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop{
        color:grayText; } }

  .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:disabled ~ .Polaris-Checkbox__Icon svg{
    fill:#919eab; }

  .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Backdrop{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    pointer-events:none;
    background-color:#c4cdd5;
    border:0.1rem solid transparent;
    box-shadow:0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    transition-property:box-shadow, background-color;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Backdrop::after{
      content:'';
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      display:block;
      background:linear-gradient(to bottom, white, #f9fafb);
      border-radius:2px; }
    @media (-ms-high-contrast: active){
      .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Backdrop{
        background:transparent;
        border:2px solid buttonText;
        transition:none;
        color:rgba(223, 227, 232, 0.3); }
        .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Backdrop::after{
          top:-4px;
          right:-4px;
          bottom:-4px;
          left:-4px;
          background:none;
          border-radius:4px; } }

  .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Icon{
    transition:transform 100ms cubic-bezier(0.36, 0, 1, 1);
    transform:translate(-50%, -50%) scale(0); }

  .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input.Polaris-Checkbox--keyFocused + .Polaris-Checkbox__Backdrop::after{
    box-shadow:0 0 0 0.2rem var(--p-focused); }

  .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop{
    border-color:var(--p-interactive); }
    .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop::before, .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::before, .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop::before{
      opacity:1;
      transform:scale(1); }
      @media (-ms-high-contrast: active){
        .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop::before, .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::before, .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop::before{
          border:2px solid windowText; } }

  .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:active:not(:disabled) ~ .Polaris-Checkbox__Icon, .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:checked ~ .Polaris-Checkbox__Icon, .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate ~ .Polaris-Checkbox__Icon{
    transition-timing-function:var(--p-ease-in);
    transform:translate(-50%, -50%) scale(1);
    opacity:1;
    transition:opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease); }

  .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop{
    border-color:var(--p-border-secondary-disabled); }
    .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop::before{
      background-color:var(--p-action-secondary-disabled); }
    .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop:hover{
      cursor:default; }

  .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:disabled:checked + .Polaris-Checkbox__Backdrop{
    background:var(--p-border-secondary-disabled); }
    .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:disabled:checked + .Polaris-Checkbox__Backdrop::before{
      background:var(--p-border-secondary-disabled); }

  .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Backdrop{
    position:relative;
    border:var(--p-control-border-width) solid var(--p-border-secondary);
    background-color:var(--p-surface);
    border-radius:var(--p-border-radius-base);
    position:relative;
    display:block;
    width:100%;
    height:100%;
    position:relative; }
    .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Backdrop::before{
      content:'';
      position:absolute;
      top:calc(-1*var(--p-control-border-width));
      right:calc(-1*var(--p-control-border-width));
      bottom:calc(-1*var(--p-control-border-width));
      left:calc(-1*var(--p-control-border-width));
      border-radius:var(--p-border-radius-base);
      background-color:var(--p-interactive);
      opacity:0;
      transform:scale(0.25);
      transition:opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease); }
    .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Backdrop.Polaris-Checkbox--hover, .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Backdrop:hover{
      cursor:pointer;
      border-color:var(--p-border-secondary-hovered); }
    .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Backdrop::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:calc(-1*(var(--p-control-border-width) + 0.1rem));
      right:calc(-1*(var(--p-control-border-width) + 0.1rem));
      bottom:calc(-1*(var(--p-control-border-width) + 0.1rem));
      left:calc(-1*(var(--p-control-border-width) + 0.1rem));
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 calc(-1*(var(--p-control-border-width) + 0.1rem)) var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }

  .Polaris-Checkbox.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Icon{
    transform:translate(-50%, -50%) scale(0.25);
    opacity:0;
    transition:opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease); }

  .Polaris-Checkbox__Icon{
    position:absolute;
    top:50%;
    left:50%;
    transform-origin:50% 50%;
    pointer-events:none; }
    .Polaris-Checkbox__Icon svg{
      fill:var(--p-icon-on-interactive, #5c6ac4); }
    @media (-ms-high-contrast: active){
      .Polaris-Checkbox__Icon{
        fill:windowText; } }

  .Polaris-Checkbox--error:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop,
  .Polaris-Checkbox--error:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop,
  .Polaris-Checkbox--error:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Backdrop{
    background:#bf0711;
    box-shadow:0 0 0 1px transparent; }
    .Polaris-Checkbox--error:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::after,
    .Polaris-Checkbox--error:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop::after,
    .Polaris-Checkbox--error:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Backdrop::after{
      background:#fbeae5; }

  .Polaris-Checkbox--error:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop,
  .Polaris-Checkbox--error:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop{
    background:#5c6ac4;
    box-shadow:0 0 0 1px #5c6ac4; }
    .Polaris-Checkbox--error:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop::after,
    .Polaris-Checkbox--error:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop::after{
      background:#fbeae5; }

  .Polaris-Checkbox--error.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Backdrop{
    border-color:var(--p-border-critical);
    background-color:var(--p-surface-critical); }
    .Polaris-Checkbox--error.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Backdrop.Polaris-Checkbox--hover, .Polaris-Checkbox--error.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Backdrop:hover{
      border-color:var(--p-border-critical); }
    .Polaris-Checkbox--error.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Backdrop::before{
      background-color:var(--p-border-critical); }

  .Polaris-Checkbox--error.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::before,
  .Polaris-Checkbox--error.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop::before,
  .Polaris-Checkbox--error.Polaris-Checkbox--newDesignLanguage .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop::before{
    background-color:var(--p-border-critical); }

  .Polaris-Checkbox--error .Polaris-Checkbox__Icon svg{
    fill:var(--p-icon-on-critical, #bf0711); }


  .Polaris-RadioButton{
    position:relative;
    margin:var(--p-choice-margin, 0); }

  .Polaris-RadioButton__Input{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:focus + .Polaris-RadioButton__Backdrop, .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:active + .Polaris-RadioButton__Backdrop, .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop{
    background:#5c6ac4; }

  .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:checked ~ .Polaris-RadioButton__Icon{
    transform:translate(-50%, -50%) scale(1); }

  .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:focus + .Polaris-RadioButton__Backdrop, .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:active + .Polaris-RadioButton__Backdrop{
    background:#5c6ac4;
    box-shadow:0 0 0 1px #5c6ac4, 0 0 0 0 transparent;
    border-color:transparent; }
    .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:focus + .Polaris-RadioButton__Backdrop::after, .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:active + .Polaris-RadioButton__Backdrop::after{
      background:white; }
    @media (-ms-high-contrast: active){
      .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:focus + .Polaris-RadioButton__Backdrop::after, .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:active + .Polaris-RadioButton__Backdrop::after{
        border:1px dashed buttonText; } }

  .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop{
    background:#dfe3e8;
    box-shadow:none; }
    .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop::after{
      background:#f9fafb; }
    @media (-ms-high-contrast: active){
      .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop{
        color:grayText; } }

  .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:disabled ~ .Polaris-RadioButton__Icon{
    background-color:#c4cdd5; }

  .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Backdrop{
    background-color:#c4cdd5;
    border:0.1rem solid transparent;
    box-shadow:0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    transition-property:box-shadow, background-color;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    border-radius:50%; }
    .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Backdrop::after{
      content:'';
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      display:block;
      background:linear-gradient(to bottom, white, #f9fafb);
      border-radius:2px; }
    @media (-ms-high-contrast: active){
      .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Backdrop{
        background:transparent;
        border:2px solid buttonText;
        transition:none;
        color:rgba(223, 227, 232, 0.3); }
        .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Backdrop::after{
          top:-4px;
          right:-4px;
          bottom:-4px;
          left:-4px;
          background:none;
          border-radius:4px; } }
    .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Backdrop::after{
      border-radius:50%; }

  .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Icon{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%) scale(0);
    transform-origin:50% 50%;
    height:50%;
    width:50%;
    background:var(--p-icon-on-interactive, #5c6ac4);
    border-radius:50%;
    transition:transform 100ms cubic-bezier(0.36, 0, 1, 1); }
    @media (-ms-high-contrast: active){
      .Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Icon{
        background:windowText; } }

  .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage{
    margin:var(--p-choice-margin, 0); }
    .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Backdrop{
      position:relative;
      top:0;
      left:0;
      display:block;
      width:100%;
      height:100%;
      border:var(--p-control-border-width) solid var(--p-border-secondary);
      border-radius:50%;
      background-color:var(--p-surface);
      transition:border-color var(--p-duration-1-0-0) var(--p-ease);
      position:relative; }
      .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Backdrop::before{
        content:'';
        position:absolute;
        top:50%;
        left:50%;
        opacity:0;
        transform:translate(-50%, -50%) scale(0.1);
        transform-origin:50% 50%;
        height:var(--p-icon-size);
        width:var(--p-icon-size);
        background-color:var(--p-interactive);
        border-radius:50%;
        transition:opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease); }
        @media (-ms-high-contrast: active){
          .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Backdrop::before{
            border:0.5rem solid windowText; } }
      .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Backdrop::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:calc(-1*(var(--p-control-border-width) + 0.1rem));
        right:calc(-1*(var(--p-control-border-width) + 0.1rem));
        bottom:calc(-1*(var(--p-control-border-width) + 0.1rem));
        left:calc(-1*(var(--p-control-border-width) + 0.1rem));
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 calc(-1*(var(--p-control-border-width) + 0.1rem)) var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Backdrop::after{
        border-radius:50%; }
      .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Backdrop.Polaris-RadioButton--hover, .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Backdrop:hover{
        cursor:pointer;
        border-color:var(--p-border-secondary-hovered); }
    .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Input.Polaris-RadioButton--keyFocused + .Polaris-RadioButton__Backdrop::after{
      box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Input.Polaris-RadioButton--keyFocused + .Polaris-RadioButton__Backdrop::after{
      border-radius:50%; }
    .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop{
      border-color:var(--p-interactive); }
      .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop::before{
        transition:opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease);
        opacity:1;
        transform:translate(-50%, -50%) scale(1); }
    .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop{
      border-color:var(--p-border-secondary-disabled); }
      .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop::before{
        background-color:var(--p-border-secondary-disabled); }
      .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop.Polaris-RadioButton--hover, .Polaris-RadioButton.Polaris-RadioButton--newDesignLanguage .Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop:hover{
        cursor:default; }


  .Polaris-ChoiceList{
    margin:0;
    padding:0;
    border:none; }

  .Polaris-ChoiceList--titleHidden > .Polaris-ChoiceList__Title{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-ChoiceList__Choices{
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-ChoiceList__ChoiceChildren{
    margin-bottom:0.8rem;
    padding-left:calc(0.8rem + var(--p-choice-size, 1.6rem)); }

  .Polaris-ChoiceList__ChoiceError{
    margin-top:0.4rem;
    margin-bottom:0.8rem; }

  .Polaris-ChoiceList__Title{
    font-size:1.5rem;
    font-weight:400;
    line-height:2rem;
    text-transform:initial;
    letter-spacing:initial;
    display:block;
    margin:0 0 0.4rem;
    padding:0; }
    @media (min-width: 40em){
      .Polaris-ChoiceList__Title{
        font-size:1.4rem; } }


  .Polaris-Collapsible{
    overflow:hidden;
    max-height:0;
    padding-top:0;
    padding-bottom:0;
    opacity:0;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity, max-height; }

  .Polaris-Collapsible--animating{
    transition-property:opacity, max-height;
    transition-duration:300ms;
    transition-timing-function:cubic-bezier(0, 0, 0.42, 1); }

  .Polaris-Collapsible--open{
    opacity:1; }

  .Polaris-Collapsible--fullyOpen{
    overflow:visible; }


  .Polaris-ColorPicker{
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    display:flex; }

  .Polaris-ColorPicker__MainColor{
    background-image:linear-gradient(45deg, var(--p-surface, #dfe3e8) 25%, transparent 25%), linear-gradient(-45deg, var(--p-surface, #dfe3e8) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--p-surface, #dfe3e8) 75%), linear-gradient(-45deg, transparent 75%, var(--p-surface, #dfe3e8) 75%);
    background-size:1.6rem 1.6rem;
    background-position:0 0, 0 0.8rem, 0.8rem -0.8rem, -0.8rem 0;
    position:relative;
    overflow:hidden;
    height:16rem;
    width:16rem;
    border-radius:var(--p-border-radius-base, 4px);
    cursor:pointer; }
    .Polaris-ColorPicker__MainColor .Polaris-ColorPicker__Dragger{
      right:0.9rem;
      margin:0; }
    .Polaris-ColorPicker__MainColor .Polaris-ColorPicker__ColorLayer{
      box-shadow:inset var(--p-override-none, 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08));
      border-radius:var(--p-border-radius-base, 4px); }
    .Polaris-ColorPicker__MainColor::after, .Polaris-ColorPicker__MainColor::before{
      content:'';
      position:absolute;
      z-index:20;
      top:0;
      left:0;
      display:block;
      height:100%;
      width:100%;
      pointer-events:none;
      border-radius:var(--p-border-radius-base, 3px); }
    .Polaris-ColorPicker__MainColor::before{
      background:linear-gradient(to right, white, transparent); }
    .Polaris-ColorPicker__MainColor::after{
      background-image:linear-gradient(to top, black, transparent);
      box-shadow:inset var(--p-override-none, 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08)); }

  .Polaris-ColorPicker__Dragger{
    position:relative;
    z-index:30;
    bottom:0.9rem;
    transform:none;
    height:1.8rem;
    width:1.8rem;
    margin:0 auto;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:transform;
    background:transparent;
    border:var(--p-border-radius-base, 3px) solid var(--p-surface, white);
    box-shadow:var(--p-override-none, 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08)), inset var(--p-override-none, 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08));
    border-radius:50%;
    pointer-events:none; }

  .Polaris-ColorPicker__HuePicker,
  .Polaris-ColorPicker__AlphaPicker{
    position:relative;
    overflow:hidden;
    height:16rem;
    width:2.4rem;
    margin-left:0.8rem;
    border-width:var(--p-border-radius-base, 3px);
    border-radius:8rem; }

  .Polaris-ColorPicker__HuePicker{
    background-image:linear-gradient(to bottom, red 1.8rem, yellow, lime, cyan, blue, magenta, red 14.2rem);
    box-shadow:var(--p-override-none, inset 0 0 2px 0 rgba(0, 0, 0, 0.5)); }

  .Polaris-ColorPicker__AlphaPicker{
    background-image:linear-gradient(45deg, var(--p-surface, #dfe3e8) 25%, transparent 25%), linear-gradient(-45deg, var(--p-surface, #dfe3e8) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--p-surface, #dfe3e8) 75%), linear-gradient(-45deg, transparent 75%, var(--p-surface, #dfe3e8) 75%);
    background-size:1.6rem 1.6rem;
    background-position:0 0, 0 0.8rem, 0.8rem -0.8rem, -0.8rem 0; }
    .Polaris-ColorPicker__AlphaPicker .Polaris-ColorPicker__ColorLayer{
      border-radius:var(--p-override-none, 8rem); }

  .Polaris-ColorPicker__ColorLayer{
    position:absolute;
    z-index:10;
    top:0;
    left:0;
    height:100%;
    width:100%;
    box-shadow:var(--p-override-none, inset 0 0 2px 0 rgba(0, 0, 0, 0.5));
    pointer-events:none; }

  .Polaris-ColorPicker__Slidable{
    height:100%;
    width:100%;
    cursor:pointer; }


  .Polaris-DataTable{
    position:relative;
    max-width:100vw; }

  .Polaris-DataTable--condensed .Polaris-DataTable__Navigation{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    padding:1.6rem 0.8rem; }
    @media (min-width: 48em){
      .Polaris-DataTable--condensed .Polaris-DataTable__Navigation{
        justify-content:flex-end; } }

  .Polaris-DataTable__Navigation{
    display:none; }

  .Polaris-DataTable__Pip{
    height:0.6rem;
    width:0.6rem;
    background:var(--p-text-subdued, #919eab);
    border-radius:var(--p-border-radius-base, 3px); }
    .Polaris-DataTable__Pip:not(:last-of-type){
      margin-right:0.4rem; }

  .Polaris-DataTable__Pip--visible{
    background:var(--p-text, #212b36); }

  .Polaris-DataTable__ScrollContainer{
    overflow-x:auto;
    -webkit-overflow-scrolling:touch; }

  .Polaris-DataTable__Table{
    width:100%;
    border-spacing:0; }

  @media (min-width: 48em){
    .Polaris-DataTable__TableRow:hover .Polaris-DataTable__Cell{
      background:var(--p-surface-hovered, #f9fafb); } }

  .Polaris-DataTable__Cell{
    padding:1.6rem;
    border-bottom:0.1rem solid var(--p-border-subdued, #f4f6f8);
    white-space:nowrap;
    text-align:left;
    transition:background-color 0.2s ease-in-out; }

  .Polaris-DataTable__Cell--firstColumn{
    font-weight:400;
    color:var(--p-text, #212b36);
    text-align:left;
    white-space:normal; }

  .Polaris-DataTable__Cell--numeric{
    text-align:right; }

  .Polaris-DataTable__Cell--truncated{
    white-space:nowrap;
    overflow-x:hidden;
    text-overflow:ellipsis;
    max-width:14.5rem; }

  .Polaris-DataTable__Cell--header{
    font-weight:400;
    color:var(--p-text, #212b36);
    border-bottom:0.1rem solid var(--p-border, #c4cdd5);
    border-top:0; }

  .Polaris-DataTable__Cell--sortable{
    padding:0; }

  .Polaris-DataTable__Cell--verticalAlignTop{
    vertical-align:top; }

  .Polaris-DataTable__Cell--verticalAlignBottom{
    vertical-align:bottom; }

  .Polaris-DataTable__Cell--verticalAlignMiddle{
    vertical-align:middle; }

  .Polaris-DataTable__Cell--verticalAlignBaseline{
    vertical-align:baseline; }

  .Polaris-DataTable__Icon{
    display:flex;
    align-self:flex-end;
    opacity:0;
    transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1), fill 200ms cubic-bezier(0.64, 0, 0.35, 1); }

  .Polaris-DataTable__Heading{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    position:relative;
    display:inline-flex;
    justify-content:flex-end;
    align-items:baseline;
    color:var(--p-text, #212b36);
    transition:color 200ms cubic-bezier(0.64, 0, 0.35, 1);
    padding:1.6rem;
    cursor:pointer; }
    .Polaris-DataTable__Heading:focus{
      outline:none; }
    .Polaris-DataTable__Heading svg{
      fill:var(--p-icon); }
    .Polaris-DataTable__Heading:hover, .Polaris-DataTable__Heading:focus{
      color:var(--p-interactive-hovered, #5c6ac4); }
      .Polaris-DataTable__Heading:hover svg, .Polaris-DataTable__Heading:focus svg{
        fill:var(--p-interactive-hovered, #5c6ac4); }
      .Polaris-DataTable__Heading:hover .Polaris-DataTable__Icon, .Polaris-DataTable__Heading:focus .Polaris-DataTable__Icon{
        opacity:1; }

  .Polaris-DataTable__Heading--left{
    justify-content:flex-start;
    flex-direction:row-reverse; }

  .Polaris-DataTable__Cell--sorted .Polaris-DataTable__Icon{
    opacity:1; }

  .Polaris-DataTable__Cell--total{
    font-weight:600;
    background:var(--p-surface-subdued, #f9fafb);
    border-bottom:0.1rem solid var(--p-border-subdued, #dfe3e8); }

  .Polaris-DataTable--cellTotalFooter{
    border-top:0.1rem solid var(--p-border-subdued, #dfe3e8);
    border-bottom:none; }

  .Polaris-DataTable__Footer{
    padding:1.6rem;
    background:var(--p-surface-subdued, #f4f6f8);
    color:var(--p-text-subdued, #637381);
    text-align:center; }


  .Polaris-DatePicker{
    position:relative; }

  .Polaris-DatePicker__MonthContainer{
    display:flex;
    flex-wrap:wrap;
    margin-top:-1.6rem;
    margin-left:-1.6rem; }

  .Polaris-DatePicker__Month{
    flex:1 1 auto;
    margin-top:1.6rem;
    margin-left:1.6rem;
    max-width:calc(100% - 1.6rem);
    min-width:23rem; }

  .Polaris-DatePicker__Month--current{
    font-weight:700; }

  .Polaris-DatePicker__Week,
  .Polaris-DatePicker__WeekHeadings{
    display:flex; }
    .Polaris-DatePicker__Week + .Polaris-DatePicker__Week,
    .Polaris-DatePicker__WeekHeadings + .Polaris-DatePicker__Week{
      margin-top:-1px; }

  .Polaris-DatePicker__Day{
    display:block;
    flex:1 0;
    width:14.28571%;
    margin:0;
    padding:0.8rem;
    background:transparent;
    border:0.1rem solid var(--p-border-subdued, #dfe3e8);
    border-radius:0;
    outline:none;
    font-size:1.2rem;
    text-align:center;
    cursor:pointer; }
    .Polaris-DatePicker__Day:hover{
      background:#dfe3e8; }
    .Polaris-DatePicker__Day:focus{
      box-shadow:inset 0 0 0 2px #5c6ac4; }
    .Polaris-DatePicker__Day + .Polaris-DatePicker__Day,
    .Polaris-DatePicker__Day + .Polaris-DatePicker__EmptyDay{
      margin-left:-1px; }

  .Polaris-DatePicker__Day--today{
    font-weight:700; }

  .Polaris-DatePicker__Day--inRange{
    z-index:20;
    background:#b3bcf5;
    border:0.1rem solid #9ca6de; }
    .Polaris-DatePicker__Day--inRange:hover{
      background:#9ca8f2; }
    @media screen and (-ms-high-contrast: active){
      .Polaris-DatePicker__Day--inRange{
        background:highlight; } }

  .Polaris-DatePicker__Day--selected{
    z-index:30;
    background:#5c6ac4;
    border:0.1rem solid #5c6ac4;
    color:white; }
    .Polaris-DatePicker__Day--selected:hover{
      background:#4959bd; }
    .Polaris-DatePicker__Day--selected:focus{
      box-shadow:inset 0 0 0 2px #202e78; }
    @media screen and (-ms-high-contrast: active){
      .Polaris-DatePicker__Day--selected{
        background:highlight; } }

  .Polaris-DatePicker__Day--disabled{
    background-color:#f9fafb;
    color:#c4cdd5;
    cursor:default; }
    .Polaris-DatePicker__Day--disabled:hover{
      background-color:#f9fafb; }
    .Polaris-DatePicker__Day--disabled:focus{
      box-shadow:none; }

  .Polaris-DatePicker__EmptyDay{
    display:block;
    flex:1 0;
    width:14.28571%;
    margin:0;
    padding:0.8rem;
    border:0.1rem solid var(--p-border-subdued, #dfe3e8); }
    .Polaris-DatePicker__EmptyDay + .Polaris-DatePicker__EmptyDay,
    .Polaris-DatePicker__EmptyDay + .Polaris-DatePicker__Day{
      margin-left:-1px; }

  .Polaris-DatePicker__Weekday{
    display:block;
    flex:1 0;
    padding:0.8rem;
    background:transparent;
    font-size:1.2rem;
    color:#637381;
    text-align:center; }
    .Polaris-DatePicker__Weekday + .Polaris-DatePicker__Weekday{
      margin-left:-1px; }

  .Polaris-DatePicker__Weekday--current{
    font-weight:700;
    color:black; }

  .Polaris-DatePicker__Header{
    position:absolute;
    top:1.6rem;
    display:flex;
    justify-content:space-between;
    width:100%; }

  .Polaris-DatePicker__Title{
    flex:1 1 auto;
    margin-top:0.2rem;
    padding-bottom:0.4rem;
    text-align:center; }


  .Polaris-DescriptionList{
    margin:0;
    padding:0; }
    @media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em){
      [data-has-navigation] .Polaris-DescriptionList{
        display:flex;
        flex-wrap:wrap;
        align-items:flex-start; } }
    @media (min-width: 36.875em){
      .Polaris-DescriptionList{
        display:flex;
        flex-wrap:wrap;
        align-items:flex-start; } }

  .Polaris-DescriptionList__Term{
    font-weight:600;
    padding:1.6rem 0 0.8rem; }
    @media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em){
      [data-has-navigation] .Polaris-DescriptionList__Term{
        flex:0 1 25%;
        padding:1.6rem 1.6rem 1.6rem 0; }
        .Polaris-DescriptionList__Description + [data-has-navigation] .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description{
          border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); } }
    @media (min-width: 36.875em){
      .Polaris-DescriptionList__Term{
        flex:0 1 25%;
        padding:1.6rem 1.6rem 1.6rem 0; }
        .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description{
          border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); } }

  .Polaris-DescriptionList__Description{
    margin-left:0;
    padding:0 0 1.6rem; }
    .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term{
      border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }
    @media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em){
      [data-has-navigation] .Polaris-DescriptionList__Description{
        flex:1 1 51%;
        padding:1.6rem 0; }
        [data-has-navigation] .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description{
          border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); } }
    @media (min-width: 36.875em){
      .Polaris-DescriptionList__Description{
        flex:1 1 51%;
        padding:1.6rem 0; }
        .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description{
          border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); } }


  .Polaris-DisplayText{
    margin:0; }

  .Polaris-DisplayText--sizeSmall{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem; }
    @media (min-width: 40em){
      .Polaris-DisplayText--sizeSmall{
        font-size:2rem;
        line-height:2.8rem; } }

  .Polaris-DisplayText--sizeMedium{
    font-size:2.1rem;
    font-weight:400;
    line-height:2.8rem; }
    @media (min-width: 40em){
      .Polaris-DisplayText--sizeMedium{
        font-size:2.6rem;
        line-height:3.2rem; } }

  .Polaris-DisplayText--sizeLarge{
    font-size:2.4rem;
    font-weight:600;
    line-height:2.8rem; }
    @media (min-width: 40em){
      .Polaris-DisplayText--sizeLarge{
        font-size:2.8rem;
        line-height:3.2rem; } }

  .Polaris-DisplayText--sizeExtraLarge{
    font-size:2.7rem;
    font-weight:600;
    line-height:3.6rem; }
    @media (min-width: 40em){
      .Polaris-DisplayText--sizeExtraLarge{
        font-size:4.2rem;
        line-height:4.4rem; } }


  .Polaris-DropZone-FileUpload{
    padding:1.5rem;
    text-align:center; }

  .Polaris-DropZone-FileUpload__Button{
    position:relative;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    min-height:3.6rem;
    min-width:3.6rem;
    margin:0;
    padding:0.7rem 1.6rem;
    background:linear-gradient(to bottom, white, #f9fafb);
    border:0.1rem solid var(--p-border, #c4cdd5);
    box-shadow:0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    line-height:1;
    color:#212b36;
    text-align:center;
    cursor:pointer;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    transition-property:background, border, box-shadow;
    transition-duration:var(--p-override-none, 200ms);
    transition-timing-function:var(--p-override-none, cubic-bezier(0.64, 0, 0.35, 1));
    -webkit-tap-highlight-color:transparent; }
    .Polaris-DropZone-FileUpload__Button svg{
      fill:#637381; }
    .Polaris-DropZone-FileUpload__Button:hover{
      background:linear-gradient(to bottom, #f9fafb, #f4f6f8);
      border-color:#c4cdd5; }
    .Polaris-DropZone-FileUpload__Button:focus{
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-DropZone-FileUpload__Button:focus{
          outline:2px dotted; } }
    .Polaris-DropZone-FileUpload__Button:active{
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      border-color:#c4cdd5;
      box-shadow:0 0 0 0 transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2); }
    .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--newDesignLanguage{
      position:relative;
      background:var(--p-action-secondary);
      border:none;
      box-shadow:none;
      border-radius:var(--p-border-radius-base);
      color:var(--p-text); }
      .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--newDesignLanguage svg{
        fill:var(--p-icon); }
      .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--newDesignLanguage:hover{
        background:var(--p-action-secondary-hovered);
        border-color:transparent; }
      .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--newDesignLanguage:focus{
        border-color:transparent;
        box-shadow:none; }
        .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--newDesignLanguage:focus::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--newDesignLanguage:active{
        background:var(--p-action-secondary-pressed);
        border-color:transparent;
        box-shadow:none; }
        .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--newDesignLanguage:active::after{
          border:none;
          box-shadow:none; }
      @media (-ms-high-contrast: active){
        .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--newDesignLanguage{
          border:1px solid windowText; } }
    .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--disabled{
      transition:none;
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      color:#919eab;
      cursor:not-allowed;
      box-shadow:none; }
      .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--disabled svg{
        fill:#919eab; }
      .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--disabled.Polaris-DropZone-FileUpload--newDesignLanguage{
        background:var(--p-action-secondary-disabled);
        color:var(--p-text-disabled); }
        .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--disabled.Polaris-DropZone-FileUpload--newDesignLanguage svg{
          fill:var(--p-icon-disabled); }
    .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--focused{
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--focused{
          outline:2px dotted; } }
    .Polaris-DropZone-FileUpload__Button .Polaris-DropZone-FileUpload--sizeSlim{
      min-height:3rem;
      padding:0.4rem 1.2rem; }

  .Polaris-DropZone-FileUpload__ActionTitle{
    color:#006fbb;
    text-decoration:none; }
    .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled){
      cursor:pointer; }
      .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):hover, .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):active{
        color:#084e8a;
        text-decoration:underline; }

  .Polaris-DropZone-FileUpload__ActionTitle--focused{
    color:#084e8a;
    text-decoration:underline; }

  .Polaris-DropZone-FileUpload__ActionTitle--disabled{
    color:#919eab; }

  .Polaris-DropZone-FileUpload__Image.Polaris-DropZone-FileUpload--sizeExtraLarge{
    width:90px; }

  .Polaris-DropZone-FileUpload__Image.Polaris-DropZone-FileUpload--sizeLarge{
    width:60px; }

  .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__Button{
    position:relative; }
    .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__Button::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
    .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--focused{
      border-color:transparent;
      box-shadow:none; }
      .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--focused::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
      @media (-ms-high-contrast: active){
        .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--focused{
          outline:2px dotted; } }

  .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__ActionTitle{
    color:var(--p-interactive);
    text-decoration:none; }
    .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled){
      cursor:pointer; }
      .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):hover, .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):active{
        color:#084e8a;
        text-decoration:underline; }

  .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__ActionTitle--focused{
    color:var(--p-interactive);
    text-decoration:underline; }

  .Polaris-DropZone-FileUpload--newDesignLanguage .Polaris-DropZone-FileUpload__ActionTitle--disabled{
    color:--p-interactive; }


  .Polaris-DropZone{
    position:relative;
    display:flex;
    justify-content:center;
    background-color:var(--p-surface, white);
    border-radius:var(--p-border-radius-base, 3px); }
    .Polaris-DropZone::after{
      content:'';
      position:absolute;
      z-index:29;
      top:0;
      right:0;
      bottom:0;
      left:0;
      border:0.2rem dashed transparent;
      border-radius:var(--p-border-radius-base, 3px);
      pointer-events:none; }

  .Polaris-DropZone--hasOutline{
    padding:0.2rem; }
    .Polaris-DropZone--hasOutline::after{
      border-color:var(--p-border-secondary, #919eab); }
    .Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover{
      cursor:pointer;
      background-color:var(--p-surface-hovered, #f4f5fa); }
    .Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover:not(.Polaris-DropZone--newDesignLanguage)::after{
      border-color:var(--p-border-secondary, #5c6ac4); }

  .Polaris-DropZone--focused:not(.Polaris-DropZone--newDesignLanguage):not(.Polaris-DropZone--isDisabled){
    background-color:var(--p-surface-hovered, #f4f5fa); }
    .Polaris-DropZone--focused:not(.Polaris-DropZone--newDesignLanguage):not(.Polaris-DropZone--isDisabled)::after{
      border:0.2rem dashed var(--p-border-secondary, #5c6ac4); }
      @media (-ms-high-contrast: active){
        .Polaris-DropZone--focused:not(.Polaris-DropZone--newDesignLanguage):not(.Polaris-DropZone--isDisabled)::after{
          border-style:solid;
          border-color:highlight; } }

  .Polaris-DropZone--isDragging:not(.Polaris-DropZone--isDisabled){
    background-color:var(--p-surface-hovered, #f4f5fa); }
    .Polaris-DropZone--isDragging:not(.Polaris-DropZone--isDisabled):not(.Polaris-DropZone--newDesignLanguage)::after{
      border-color:var(--p-border-secondary, #5c6ac4); }

  .Polaris-DropZone--isDisabled{
    cursor:not-allowed; }
    .Polaris-DropZone--isDisabled::after{
      border-color:var(--p-border-secondary-disabled, #dfe3e8); }

  .Polaris-DropZone--sizeExtraLarge{
    min-height:20.5rem; }

  .Polaris-DropZone--sizeLarge{
    min-height:16rem; }

  .Polaris-DropZone--sizeMedium{
    min-height:10rem;
    align-items:center; }

  .Polaris-DropZone--sizeSmall{
    padding:0;
    align-items:center;
    min-height:5rem; }

  .Polaris-DropZone--measuring{
    visibility:hidden;
    min-height:0; }

  .Polaris-DropZone__Container{
    flex:1 1; }

  .Polaris-DropZone__Overlay{
    position:absolute;
    z-index:30;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:1.5rem;
    border:0.2rem dashed var(--p-border-secondary, #5c6ac4);
    border-radius:var(--p-border-radius-base, 3px);
    text-align:center;
    color:var(--p-text, #5c6ac4);
    background-color:var(--p-surface-hovered, #f4f5fa); }
    .Polaris-DropZone--hasError .Polaris-DropZone__Overlay{
      border-color:var(--p-border-critical, #de3618);
      color:var(--p-text-critical, #de3618);
      background-color:var(--p-surface-critical-subdued, #fbeae5); }
    .Polaris-DropZone--sizeSmall .Polaris-DropZone__Overlay{
      padding:0; }

  .Polaris-DropZone--newDesignLanguage.Polaris-DropZone:not(.Polaris-DropZone--focused)::after{
    top:0;
    left:0;
    right:0;
    bottom:0;
    opacity:1;
    transform:scale(1);
    border:0.2rem dashed transparent; }

  .Polaris-DropZone--newDesignLanguage .Polaris-DropZone__Container{
    position:relative; }
    .Polaris-DropZone--newDesignLanguage .Polaris-DropZone__Container::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.3rem;
      right:-0.3rem;
      bottom:-0.3rem;
      left:-0.3rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.3rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }

  .Polaris-DropZone--newDesignLanguage.Polaris-DropZone__Overlay{
    border-radius:calc(var(--p-border-radius-base) + 0.3rem); }

  .Polaris-DropZone--newDesignLanguage.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--focused)::after{
    top:0;
    left:0;
    right:0;
    bottom:0;
    opacity:1;
    transform:scale(1);
    border:0.2rem dashed transparent;
    border-color:var(--p-border-secondary, #919eab); }

  .Polaris-DropZone--newDesignLanguage.Polaris-DropZone--focused:not(.Polaris-DropZone--isDisabled) .Polaris-DropZone__Container::after{
    box-shadow:0 0 0 0.2rem var(--p-focused); }


  .Polaris-EmptySearchResult__Image{
    width:178px;
    height:158px;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none; }


  .Polaris-EmptyState{
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    margin:2rem auto 0 auto;
    padding:2rem 0;
    max-width:99.8rem; }

  .Polaris-EmptyState__Section{
    position:relative;
    display:flex;
    flex-direction:column;
    flex:1 1 auto;
    width:100%; }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-EmptyState__Section{
        left:2rem;
        flex-direction:row;
        align-items:center; } }
    @media (min-width: 46.5em){
      .Polaris-EmptyState__Section{
        left:2rem;
        flex-direction:row;
        align-items:center; } }

  .Polaris-EmptyState__ImageContainer,
  .Polaris-EmptyState__DetailsContainer{
    flex:1 1 auto;
    padding:0;
    margin:0; }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-EmptyState__ImageContainer, [data-has-navigation]
      .Polaris-EmptyState__DetailsContainer{
        flex-basis:50%; } }
    @media (min-width: 46.5em){
      .Polaris-EmptyState__ImageContainer,
      .Polaris-EmptyState__DetailsContainer{
        flex-basis:50%; } }
    @media (max-width: 30.625em), (min-width: 48.0625em) and (max-width: 47.125em){
      [data-has-navigation] .Polaris-EmptyState__ImageContainer, [data-has-navigation]
      .Polaris-EmptyState__DetailsContainer{
        overflow-x:hidden; } }
    @media (max-width: 30.625em){
      .Polaris-EmptyState__ImageContainer,
      .Polaris-EmptyState__DetailsContainer{
        overflow-x:hidden; } }

  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Section{
    position:unset;
    flex-direction:column-reverse;
    align-items:center;
    justify-content:center; }

  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__DetailsContainer{
    padding-top:1.6rem; }

  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details{
    display:flex;
    text-align:center;
    flex-direction:column;
    align-items:center; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details{
        max-width:33.6rem; } }
    @media (min-width: 30.625em){
      .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details{
        max-width:33.6rem; } }

  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Image{
    margin:0;
    width:initial; }

  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Content{
    font-size:1.5rem;
    font-weight:400;
    line-height:2rem;
    text-transform:initial;
    letter-spacing:initial;
    padding-bottom:0.8rem; }
    @media (min-width: 40em){
      .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Content{
        font-size:1.4rem; } }

  .Polaris-EmptyState--withinPage .Polaris-EmptyState__Details{
    position:relative;
    z-index:10;
    padding:0 1.6rem;
    width:33.6rem; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-EmptyState--withinPage .Polaris-EmptyState__Details{
        padding:0; } }
    @media (min-width: 30.625em){
      .Polaris-EmptyState--withinPage .Polaris-EmptyState__Details{
        padding:0; } }

  .Polaris-EmptyState--withinPage .Polaris-EmptyState__Image{
    position:relative;
    z-index:0;
    margin-top:-2rem;
    margin-left:-70%;
    width:190%;
    max-width:none; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-EmptyState--withinPage .Polaris-EmptyState__Image{
        margin-left:0;
        width:100%; } }
    @media (min-width: 30.625em){
      .Polaris-EmptyState--withinPage .Polaris-EmptyState__Image{
        margin-left:0;
        width:100%; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-EmptyState--withinPage .Polaris-EmptyState__Image{
        margin-top:0;
        margin-left:-70%;
        width:170%; } }
    @media (min-width: 46.5em){
      .Polaris-EmptyState--withinPage .Polaris-EmptyState__Image{
        margin-top:0;
        margin-left:-70%;
        width:170%; } }

  @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
    [data-has-navigation] .Polaris-EmptyState--imageContained .Polaris-EmptyState__Image{
      position:initial;
      width:100%; } }

  @media (min-width: 46.5em){
    .Polaris-EmptyState--imageContained .Polaris-EmptyState__Image{
      position:initial;
      width:100%; } }

  .Polaris-EmptyState__Content{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem;
    color:var(--p-text-subdued, #637381); }
    @media (min-width: 40em){
      .Polaris-EmptyState__Content{
        font-size:2rem;
        line-height:2.8rem; } }

  .Polaris-EmptyState__Actions{
    margin-top:1.6rem; }

  .Polaris-EmptyState__FooterContent{
    color:var(--p-text-subdued, #637381);
    margin-top:1.6rem; }


  .Polaris-Truncate{
    display:block;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis; }


  .Polaris-ExceptionList{
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-ExceptionList__Item{
    position:relative;
    padding-left:2.4rem;
    color:var(--p-text-subdued, #637381); }
    .Polaris-ExceptionList__Item + .Polaris-ExceptionList__Item{
      margin-top:0.4rem; }

  .Polaris-ExceptionList__Icon{
    position:absolute;
    top:0;
    left:0;
    display:flex;
    align-items:center;
    justify-content:center;
    min-width:2rem;
    height:2rem;
    margin-right:0.4rem; }
    .Polaris-ExceptionList__Icon svg{
      fill:var(--p-icon-subdued, #919eab); }
    .Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Icon svg{
      fill:var(--p-icon-warning, #8a6116); }
    .Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Icon svg{
      fill:var(--p-icon-critical, #bf0711); }

  .Polaris-ExceptionList__Bullet{
    width:0.6rem;
    height:0.6rem;
    border-radius:100%;
    background-color:var(--p-icon-subdued, #919eab); }
    .Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Bullet{
      background-color:var(--p-icon-warning, #8a6116); }
    .Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Bullet{
      background-color:var(--p-icon-critical, #bf0711); }

  .Polaris-ExceptionList__Title + .Polaris-ExceptionList__Description::before{
    content:'–';
    margin:0 0.4rem; }

  .Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title,
  .Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title{
    font-weight:500; }

  .Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title{
    color:var(--p-text-warning, #8a6116); }

  .Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title{
    color:var(--p-text-critical, #bf0711); }


  .Polaris-Tag{
    display:inline-flex;
    max-width:100%;
    align-items:center;
    min-height:2.8rem;
    padding-left:0.8rem;
    background-color:var(--p-action-secondary, #dfe3e8);
    border-radius:3px;
    color:var(--p-text, #212b36); }
    .Polaris-Tag.Polaris-Tag--disabled{
      transition:none;
      background:var(--p-action-secondary-disabled, #f4f6f8);
      color:var(--p-text-disabled, #919eab); }
      .Polaris-Tag.Polaris-Tag--disabled svg{
        fill:var(--p-icon-disabled, #919eab); }
    .Polaris-Tag .Polaris-Tag__TagText{
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap; }
    @media (-ms-high-contrast: active){
      .Polaris-Tag{
        outline:1px solid windowText; } }

  .Polaris-Tag__Button{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    display:block;
    height:2.8rem;
    width:2.8rem;
    margin-left:0.4rem;
    border-radius:0 3px 3px 0; }
    .Polaris-Tag__Button svg{
      fill:#637381; }
    .Polaris-Tag__Button:focus{
      outline:none; }
    .Polaris-Tag__Button:hover, .Polaris-Tag__Button:focus, .Polaris-Tag__Button:active{
      background:#c4cdd5; }
      .Polaris-Tag__Button:hover svg, .Polaris-Tag__Button:focus svg, .Polaris-Tag__Button:active svg{
        fill:#212b36; }
    .Polaris-Tag__Button:disabled{
      cursor:default;
      pointer-events:none; }
      .Polaris-Tag__Button:disabled svg{
        fill:#919eab; }
    .Polaris-Tag__Button:active{
      background:rgba(145, 158, 171, 0.6); }
    .Polaris-Tag__Button.Polaris-Tag--newDesignLanguage{
      position:relative; }
      .Polaris-Tag__Button.Polaris-Tag--newDesignLanguage svg{
        fill:var(--p-icon); }
      .Polaris-Tag__Button.Polaris-Tag--newDesignLanguage:hover{
        background:var(--p-action-secondary-hovered); }
      .Polaris-Tag__Button.Polaris-Tag--newDesignLanguage:focus{
        background-color:transparent; }
      .Polaris-Tag__Button.Polaris-Tag--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Tag__Button.Polaris-Tag--newDesignLanguage:focus:not(:active)::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Tag__Button.Polaris-Tag--newDesignLanguage:active{
        background:var(--p-action-secondary-pressed); }
      .Polaris-Tag__Button.Polaris-Tag--newDesignLanguage:disabled{
        cursor:default;
        pointer-events:none; }
        .Polaris-Tag__Button.Polaris-Tag--newDesignLanguage:disabled svg{
          fill:var(--p-icon-disabled); }


  .Polaris-Sheet{
    position:fixed;
    bottom:0;
    width:100%;
    height:100%;
    background-color:var(--p-surface, white);
    box-shadow:var(--p-modal-shadow, 0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08)); }
    @media screen and (-ms-high-contrast: active){
      .Polaris-Sheet{
        border-left:0.1rem solid var(--p-border-subdued, #dfe3e8); } }
    @media (min-width: 48.0625em){
      .Polaris-Sheet{
        right:0;
        width:38rem; } }
    .Polaris-Sheet:focus{
      outline:0; }

  .Polaris-Sheet__Container{
    position:fixed;
    z-index:519;
    top:0;
    right:0;
    bottom:0;
    left:0; }
    @media (min-width: 48.0625em){
      .Polaris-Sheet__Container{
        left:auto;
        width:38rem; } }

  .Polaris-Sheet__Bottom{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:transform;
    transition:transform 300ms cubic-bezier(0.64, 0, 0.35, 1);
    transform-origin:bottom; }

  .Polaris-Sheet--enterBottom{
    transform:translateY(100%); }

  .Polaris-Sheet--enterBottomActive{
    transform:translateY(0%); }

  .Polaris-Sheet--exitBottom{
    transform:translateY(0%); }

  .Polaris-Sheet--exitBottomActive{
    transform:translateY(100%); }

  .Polaris-Sheet__Right{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:transform;
    transition:transform 300ms cubic-bezier(0.64, 0, 0.35, 1);
    transform-origin:right; }

  .Polaris-Sheet--enterRight{
    transform:translateX(100%); }

  .Polaris-Sheet--enterRightActive{
    transform:translateX(0%); }

  .Polaris-Sheet--exitRight{
    transform:translateX(0%); }

  .Polaris-Sheet--exitRightActive{
    transform:translateX(100%); }


  .Polaris-Filters-ConnectedFilterControl__Item{
    position:relative;
    z-index:10; }

  .Polaris-Filters-ConnectedFilterControl__Item--focused{
    z-index:20; }

  .Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer{
    position:absolute;
    display:flex;
    width:100%;
    height:0;
    visibility:hidden; }
    .Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer > *{
      flex-shrink:0; }

  .Polaris-Filters-ConnectedFilterControl{
    display:flex;
    flex-grow:1; }
    .Polaris-Filters-ConnectedFilterControl .Polaris-Filters-ConnectedFilterControl__CenterContainer{
      flex:1 1 auto;
      min-width:10rem; }
    .Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right .Polaris-Filters-ConnectedFilterControl__CenterContainer *{
      border-top-right-radius:0 !important;
      border-bottom-right-radius:0 !important; }

  .Polaris-Filters-ConnectedFilterControl__RightContainer{
    display:flex; }
    .Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item > * > *{
      margin-right:var(--p-button-group-item-spacing, 0);
      margin-left:var(--p-override-none, -1px);
      border-radius:0 !important; }
    .Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item{
      flex-shrink:0; }

  .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer *{
    border-top-left-radius:0 !important;
    border-bottom-left-radius:0 !important;
    flex-shrink:0;
    white-space:nowrap; }
    .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer * > div{
      margin-left:var(--p-override-none, -1px); }

  .Polaris-Filters-ConnectedFilterControl__Wrapper{
    display:flex; }

  .Polaris-Filters-ConnectedFilterControl__AuxiliaryContainer{
    flex-grow:0; }


  .Polaris-Filters{
    position:relative; }

  .Polaris-Filters__FiltersContainer{
    position:relative;
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column; }

  .Polaris-Filters__FiltersContainerHeader{
    top:0;
    width:100%;
    padding:1.6rem 2rem;
    border-bottom:0.1rem solid var(--p-border, #dfe3e8);
    height:5.6rem;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:space-between; }

  .Polaris-Filters__FiltersDesktopContainerContent{
    width:100%;
    height:calc(100% - 12.6rem); }

  .Polaris-Filters__FiltersMobileContainerContent{
    width:100%;
    height:calc(100% - 5.6rem); }

  .Polaris-Filters__FiltersContainerFooter{
    position:absolute;
    bottom:0;
    width:100%;
    padding:1.4rem 1.6rem;
    border-top:0.1rem solid var(--p-border, #dfe3e8);
    height:7rem;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:space-between; }

  .Polaris-Filters__FiltersMobileContainerFooter{
    width:100%;
    padding:1.4rem 1.6rem;
    height:7rem;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:space-between; }

  .Polaris-Filters__EmptyFooterState{
    border-top:0.1rem solid var(--p-border-subdued, #dfe3e8);
    padding-top:1.4rem;
    width:100%;
    display:flex;
    justify-content:center; }

  .Polaris-Filters__FilterTriggerContainer{
    position:relative; }

  .Polaris-Filters__FilterTrigger{
    width:100%;
    margin:0;
    padding:1.4rem 2rem;
    border:none;
    background:none;
    color:var(--p-text, #212b36); }
    .Polaris-Filters__FilterTrigger:hover{
      cursor:pointer;
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
      background-color:var(--p-surface-hovered); }
    .Polaris-Filters__FilterTrigger:focus{
      outline:none;
      box-shadow:inset 0.2rem 0 0 var(--p-override-none, #5c6ac4);
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3))); }
    .Polaris-Filters__FilterTrigger.Polaris-Filters--newDesignLanguage{
      position:relative; }
      .Polaris-Filters__FilterTrigger.Polaris-Filters--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Filters__FilterTrigger.Polaris-Filters--newDesignLanguage:focus:not(:active)::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }

  .Polaris-Filters__FilterTriggerTitle{
    font-size:1.5rem;
    font-weight:600; }

  .Polaris-Filters__AppliedFilterBadgeContainer{
    padding-top:0.4rem;
    display:flex; }
    .Polaris-Filters--open .Polaris-Filters__AppliedFilterBadgeContainer{
      display:none; }

  .Polaris-Filters__FilterTriggerLabelContainer{
    display:flex;
    align-items:center;
    justify-content:space-between; }

  .Polaris-Filters--open::before, .Polaris-Filters--open::after{
    content:'';
    position:relative;
    left:1.6rem;
    width:calc(100% - 3.2rem);
    height:0.1rem;
    background-color:var(--p-border, #dfe3e8);
    display:block; }

  .Polaris-Filters--open::before{
    top:0; }

  .Polaris-Filters--open::after{
    bottom:0; }

  .Polaris-Filters--open.Polaris-Filters--first::after{
    content:'';
    bottom:0;
    position:relative;
    left:1.6rem;
    width:calc(100% - 3.2rem);
    height:0.1rem;
    background-color:var(--p-border, #dfe3e8);
    display:block; }

  .Polaris-Filters--open.Polaris-Filters--first::before{
    display:none; }

  .Polaris-Filters--open ~ .Polaris-Filters--open::before{
    display:none; }

  .Polaris-Filters--open.Polaris-Filters--last::before{
    content:'';
    top:0;
    position:relative;
    left:1.6rem;
    width:calc(100% - 3.2rem);
    height:0.1rem;
    background-color:var(--p-border, #dfe3e8);
    display:block; }

  .Polaris-Filters--open.Polaris-Filters--last::after{
    display:none; }

  .Polaris-Filters--open + .Polaris-Filters--last::before{
    display:none; }

  .Polaris-Filters__FilterNodeContainer{
    padding:0.8rem 2rem 2rem 2rem; }

  .Polaris-Filters__SearchIcon{
    fill:currentColor; }

  .Polaris-Filters__Backdrop{
    position:fixed;
    z-index:518;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:block;
    opacity:0; }

  .Polaris-Filters__HelpText{
    margin-top:10px; }

  .Polaris-Filters__TagsContainer{
    display:flex;
    padding-top:0.8rem;
    flex-wrap:wrap; }
    .Polaris-Filters__TagsContainer > *{
      margin-right:0.8rem;
      margin-bottom:0.8rem; }


  .Polaris-FooterHelp{
    display:flex;
    justify-content:center;
    margin:2rem 0;
    width:100%; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-FooterHelp{
        margin:2rem;
        width:auto; } }
    @media (min-width: 30.625em){
      .Polaris-FooterHelp{
        margin:2rem;
        width:auto; } }

  .Polaris-FooterHelp__Content{
    display:inline-flex;
    align-items:center;
    padding:1.6rem 2rem 1.6rem 1.6rem;
    border-top:0.1rem solid var(--p-border-subdued, #dfe3e8);
    border-bottom:0.1rem solid var(--p-border-subdued, #dfe3e8);
    width:100%;
    justify-content:center; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-FooterHelp__Content{
        width:auto;
        border:0.1rem solid var(--p-border-subdued, #dfe3e8);
        border-radius:999px; } }
    @media (min-width: 30.625em){
      .Polaris-FooterHelp__Content{
        width:auto;
        border:0.1rem solid var(--p-border-subdued, #dfe3e8);
        border-radius:999px; } }

  .Polaris-FooterHelp__Icon{
    margin-right:0.8rem; }

  .Polaris-FooterHelp__Text{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem;
    text-transform:initial;
    letter-spacing:initial; }
    @media (min-width: 40em){
      .Polaris-FooterHelp__Text{
        font-size:1.4rem; } }


  .Polaris-FormLayout{
    margin-top:-1.6rem;
    margin-left:-2rem; }

  .Polaris-FormLayout__Title{
    margin-bottom:-0.8rem;
    padding:1.6rem 2rem 0; }

  .Polaris-FormLayout__Items{
    display:flex;
    flex-wrap:wrap; }

  .Polaris-FormLayout__HelpText{
    color:var(--p-text-subdued, #637381);
    padding:0.8rem 2rem 0; }

  .Polaris-FormLayout__Item{
    flex:1 1 22rem;
    margin-top:1.6rem;
    margin-left:2rem;
    max-width:calc(100% - 2rem); }
    .Polaris-FormLayout--grouped .Polaris-FormLayout__Item{
      min-width:22rem; }
    .Polaris-FormLayout--condensed .Polaris-FormLayout__Item{
      flex-basis:11rem;
      min-width:11rem; }


  .Polaris-Frame-Toast{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem;
    display:inline-flex;
    max-width:50rem;
    padding:0.8rem 1.6rem;
    border-radius:var(--p-border-radius-wide, 3px);
    background:var(--p-surface, rgba(0, 0, 0, 0.88));
    box-shadow:var(--p-override-none, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
    color:var(--p-text, white);
    margin-bottom:2rem; }
    @media (min-width: 40em){
      .Polaris-Frame-Toast{
        font-size:2rem;
        line-height:2.8rem; } }
    @media (min-width: 40em){
      .Polaris-Frame-Toast{
        padding:1.6rem; } }
    @media (-ms-high-contrast: active){
      .Polaris-Frame-Toast{
        border:0.2rem solid windowText; } }

  .Polaris-Frame-Toast__Action{
    margin-left:3.2rem;
    margin-right:1.6rem; }

  .Polaris-Frame-Toast--error{
    background:var(--p-action-critical, rgba(191, 7, 17, 0.88));
    color:var(--p-text-on-critical, white); }
    .Polaris-Frame-Toast--error .Polaris-Frame-Toast__CloseButton{
      fill:var(--p-text-on-critical, white); }

  .Polaris-Frame-Toast__CloseButton{
    display:flex;
    align-self:flex-start;
    flex-direction:column;
    justify-content:flex-start;
    margin:-0.8rem -1.6rem -0.8rem 0;
    padding:1.2rem 1.6rem 0.8rem;
    border:none;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    background:transparent;
    fill:var(--p-text, white);
    cursor:pointer; }
    .Polaris-Frame-Toast__CloseButton:focus{
      outline:none; }
    @media (-ms-high-contrast: active){
      .Polaris-Frame-Toast__CloseButton{
        fill:windowText; } }


  :root{
    --toast-translate-y-out:15rem;
    --toast-translate-y-in:0; }

  .Polaris-Frame-ToastManager{
    position:fixed;
    z-index:520;
    right:0;
    left:0;
    text-align:center;
    bottom:var(--global-ribbon-height, 0);
    display:flex;
    flex-direction:column;
    align-items:center; }

  .Polaris-Frame-ToastManager__ToastWrapper{
    position:absolute;
    display:inline-flex;
    opacity:0;
    transition:transform 400ms ease, opacity 400ms ease;
    transform:translateY(var(--toast-translate-y-out)); }

  .Polaris-Frame-ToastManager__ToastWrapper--enter,
  .Polaris-Frame-ToastManager__ToastWrapper--exit{
    transform:translateY(var(--toast-translate-y-out));
    opacity:0; }

  .Polaris-Frame-ToastManager--toastWrapperEnterDone{
    transform:translateY(var(--toast-translate-y-in));
    opacity:1; }


  .Polaris-Frame-Loading{
    overflow:hidden;
    height:0.3rem;
    background-color:var(--p-background, #dfe3e8);
    opacity:1; }

  .Polaris-Frame-Loading__Level{
    width:100%;
    height:100%;
    transform-origin:0;
    background-color:var(--p-border-highlight, #47c1bf);
    transition:transform 500ms cubic-bezier(0, 0, 0.42, 1); }
    @media screen and (-ms-high-contrast: active){
      .Polaris-Frame-Loading__Level{
        background-color:highlight; } }


  .Polaris-Modal-Dialog__Container{
    position:fixed;
    z-index:519;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:flex;
    flex-direction:column;
    justify-content:flex-end; }
    @media (min-width: 48.0625em){
      .Polaris-Modal-Dialog__Container{
        justify-content:center; } }

  .Polaris-Modal-Dialog:focus{
    outline:0; }

  .Polaris-Modal-Dialog__Modal{
    position:fixed;
    right:0;
    bottom:0;
    left:0;
    display:flex;
    flex-direction:column;
    width:100%;
    max-height:calc(100vh - 60px);
    background:var(--p-surface, white);
    box-shadow:var(--p-modal-shadow, 0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08)); }
    @media (-ms-high-contrast: active){
      .Polaris-Modal-Dialog__Modal{
        border:1px solid windowText; } }
    @media (max-width: 48.0525em){
      .Polaris-Modal-Dialog__Modal{
        bottom:0;
        max-height:100%; } }
    @media (min-width: 48.0625em){
      .Polaris-Modal-Dialog__Modal{
        position:relative;
        max-width:62rem;
        margin:0 auto;
        border-radius:var(--p-border-radius-wide, 6px); } }

  @media (min-width: 48.0625em) and (min-height: 660px){
    .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--limitHeight{
      max-height:600px; } }
    @media (min-width: 48.0625em){
      .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge{
        max-width:calc(100% - 6.4rem); } }
    @media (min-width: 65.25em){
      .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge{
        max-width:98rem; } }

  .Polaris-Modal-Dialog--animateFadeUp{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:transform, opacity;
    opacity:1;
    transform:translateY(0);
    transition:transform cubic-bezier(0.64, 0, 0.35, 1) 200ms, opacity cubic-bezier(0.64, 0, 0.35, 1) 200ms; }
    .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entering, .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exiting, .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exited{
      opacity:0;
      transform:translateY(20rem); }
    .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entered{
      opacity:1;
      transform:translateY(0); }


  .Polaris-Modal-Footer{
    display:flex;
    align-self:flex-end;
    align-items:center;
    width:100%;
    min-height:6.4rem;
    padding:1.6rem;
    border-top:var(--p-thin-border-subdued, 0.1rem solid var(--p-border-subdued, #dfe3e8)); }

  .Polaris-Modal-Footer__FooterContent{
    width:100%; }


  .Polaris-Modal-CloseButton{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    margin-left:2rem;
    margin-right:-0.8rem;
    padding:0.8rem; }
    .Polaris-Modal-CloseButton:focus{
      outline:none; }
    .Polaris-Modal-CloseButton:focus, .Polaris-Modal-CloseButton:active{
      border-radius:6px;
      background:rgba(33, 43, 54, 0.1); }

  .Polaris-Modal-CloseButton--withoutTitle{
    position:absolute;
    right:0.8rem; }


  .Polaris-Modal-Header{
    display:flex;
    align-items:flex-start;
    flex-shrink:0;
    padding:1.6rem 2rem;
    border-bottom:var(--p-thin-border-subdued, 0.1rem solid var(--p-border-subdued, #dfe3e8)); }

  .Polaris-Modal-Header__Title{
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word;
    flex:1 1;
    margin-top:0.4rem; }


  .Polaris-Modal-Section{
    flex:0 0 auto;
    padding:2rem; }
    .Polaris-Modal-Section:not(:last-of-type){
      border-bottom:0.1rem solid var(--p-border-subdued, #dfe3e8); }
    .Polaris-Modal-Section.Polaris-Modal-Section--subdued{
      background:var(--p-surface-subdued, #f4f6f8); }
    .Polaris-Modal-Section.Polaris-Modal-Section--flush{
      padding:0; }


  .Polaris-Modal__BodyWrapper{
    display:flex;
    flex-grow:1;
    overflow-x:hidden;
    -webkit-overflow-scrolling:touch; }

  .Polaris-Modal__Body{
    width:100%; }

  .Polaris-Modal__IFrame{
    display:block;
    width:62rem;
    max-width:100vw;
    border:none; }
    @media (min-width: 48.0625em){
      .Polaris-Modal__IFrame{
        max-width:62rem; } }

  .Polaris-Modal__Spinner{
    margin:1.6rem;
    text-align:center; }


  .Polaris-Frame-ContextualSaveBar{
    display:flex;
    height:5.6rem;
    background-color:white;
    box-shadow:0 2px 4px rgba(0, 0, 0, 0.1); }
    .Polaris-Frame-ContextualSaveBar::after{
      content:'';
      position:absolute;
      bottom:0;
      width:100%;
      border-bottom:1px solid transparent; }

  .Polaris-Frame-ContextualSaveBar__LogoContainer{
    display:none; }
    @media (min-width: 48.0625em){
      .Polaris-Frame-ContextualSaveBar__LogoContainer{
        display:flex;
        flex:0 0 24rem;
        align-items:center;
        height:100%;
        padding:0 1.6rem;
        border-right:1px solid #ebeef0;
        background-color:#fafbfc; } }

  .Polaris-Frame-ContextualSaveBar__Contents{
    display:flex;
    flex:1 1 auto;
    align-items:center;
    justify-content:space-between;
    min-width:1px;
    max-width:99.8rem;
    height:100%;
    margin:0 auto;
    padding:0 1.6rem; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Frame-ContextualSaveBar__Contents{
        padding:0 2rem; } }
    @media (min-width: 30.625em){
      .Polaris-Frame-ContextualSaveBar__Contents{
        padding:0 2rem; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-Frame-ContextualSaveBar__Contents{
        padding:0 3.2rem; } }
    @media (min-width: 46.5em){
      .Polaris-Frame-ContextualSaveBar__Contents{
        padding:0 3.2rem; } }

  .Polaris-Frame-ContextualSaveBar__Message{
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    font-size:1.7rem;
    font-weight:600;
    line-height:2.4rem;
    color:var(--p-text-subdued, #637381); }
    @media (min-width: 40em){
      .Polaris-Frame-ContextualSaveBar__Message{
        font-size:1.6rem; } }

  .Polaris-Frame-ContextualSaveBar__ActionContainer{
    flex-shrink:0; }

  .Polaris-Frame-ContextualSaveBar__Action{
    margin-left:0.8rem; }

  .Polaris-Frame-ContextualSaveBar--newDesignLanguage{
    background:var(--p-surface);
    height:var(--p-contextual-save-bar-height);
    margin:0.8rem;
    border-radius:var(--p-border-radius-wide); }
    .Polaris-Frame-ContextualSaveBar--newDesignLanguage::after{
      border-bottom:none; }
    @media (-ms-high-contrast: active){
      .Polaris-Frame-ContextualSaveBar--newDesignLanguage{
        border:1px solid windowText; } }
    .Polaris-Frame-ContextualSaveBar--newDesignLanguage .Polaris-Frame-ContextualSaveBar__LogoContainer{
      display:none; }
    .Polaris-Frame-ContextualSaveBar--newDesignLanguage .Polaris-Frame-ContextualSaveBar__Contents{
      margin:0;
      padding:0 1.6rem;
      max-width:100%; }
      @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
        [data-has-navigation] .Polaris-Frame-ContextualSaveBar--newDesignLanguage .Polaris-Frame-ContextualSaveBar__Contents{
          padding:0 1.6rem; } }
      @media (min-width: 30.625em){
        .Polaris-Frame-ContextualSaveBar--newDesignLanguage .Polaris-Frame-ContextualSaveBar__Contents{
          padding:0 1.6rem; } }
      @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
        [data-has-navigation] .Polaris-Frame-ContextualSaveBar--newDesignLanguage .Polaris-Frame-ContextualSaveBar__Contents{
          padding:0 1.6rem; } }
      @media (min-width: 46.5em){
        .Polaris-Frame-ContextualSaveBar--newDesignLanguage .Polaris-Frame-ContextualSaveBar__Contents{
          padding:0 1.6rem; } }
    .Polaris-Frame-ContextualSaveBar--newDesignLanguage .Polaris-Frame-ContextualSaveBar__Message{
      color:var(--p-text); }


  .Polaris-Frame-CSSAnimation--startFade{
    opacity:0;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity;
    transition:opacity 300ms cubic-bezier(0, 0, 0.42, 1);
    pointer-events:none; }

  .Polaris-Frame-CSSAnimation--endFade{
    opacity:1;
    pointer-events:auto; }

  .Polaris-Frame-CSSAnimation--startFadeUp{
    opacity:0;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity, transform;
    transform:translateY(2.5rem);
    transition:transform var(--p-duration-1-5-0) var(--p-ease), opacity var(--p-duration-1-5-0) var(--p-ease);
    pointer-events:none; }

  .Polaris-Frame-CSSAnimation--endFadeUp{
    opacity:1;
    transform:translateY(0);
    pointer-events:auto;
    transition:transform var(--p-duration-1-0-0) var(--p-ease), opacity var(--p-duration-1-5-0) var(--p-ease); }


  .Polaris-Frame{
    width:100%;
    min-height:100vh;
    display:flex;
    background-color:var(--p-background, #f4f6f8); }
    @media print{
      .Polaris-Frame{
        background-color:none; } }

  .Polaris-Frame__Navigation{
    position:fixed;
    z-index:516;
    top:0;
    left:0;
    display:none;
    flex:0 0 auto;
    align-items:stretch;
    height:100%;
    outline:none;
    transform:translateX(0%); }
    @media print{
      .Polaris-Frame__Navigation{
        display:none !important; } }
    @media (min-width: 48.0625em){
      .Polaris-Frame__Navigation{
        z-index:1;
        display:flex; }
        .Polaris-Frame__Navigation:not(.Polaris-Frame__Navigation--newDesignLanguage){
          top:5.6rem;
          height:calc(100% - 5.6rem); } }
    .Polaris-Frame__Navigation:focus{
      outline:none; }

  .Polaris-Frame__Navigation--enter,
  .Polaris-Frame__Navigation--enterActive,
  .Polaris-Frame__Navigation--exit,
  .Polaris-Frame__Navigation--exitActive{
    display:flex; }

  .Polaris-Frame__Navigation--enter{
    transform:translateX(-100%); }

  .Polaris-Frame__Navigation--enterActive{
    transform:translateX(0%);
    transition:transform 300ms cubic-bezier(0, 0, 0.42, 1); }

  .Polaris-Frame__Navigation--exit{
    transform:translateX(0%); }

  .Polaris-Frame__Navigation--exitActive{
    transform:translateX(-100%);
    transition:transform 300ms cubic-bezier(0, 0, 0.42, 1); }

  .Polaris-Frame__NavigationDismiss{
    position:absolute;
    top:0;
    left:100%;
    width:3.2rem;
    height:3.2rem;
    margin:1.6rem;
    padding:0;
    border:none;
    background:none;
    opacity:0;
    pointer-events:none;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity;
    cursor:pointer;
    transition:opacity 100ms cubic-bezier(0.64, 0, 0.35, 1); }
    @media print{
      .Polaris-Frame__NavigationDismiss{
        display:none !important; } }
    .Polaris-Frame__Navigation--visible .Polaris-Frame__NavigationDismiss{
      pointer-events:all;
      opacity:1; }
    .Polaris-Frame__NavigationDismiss:focus{
      border-radius:3px;
      background-color:var(--p-override-transparent, rgba(255, 255, 255, 0.16));
      outline:none; }
    @media (min-width: 48.0625em){
      .Polaris-Frame__NavigationDismiss{
        display:none; } }
    .Polaris-Frame__Navigation--newDesignLanguage .Polaris-Frame__NavigationDismiss{
      border-radius:50%; }
      .Polaris-Frame__Navigation--newDesignLanguage .Polaris-Frame__NavigationDismiss:hover{
        background-color:var(--p-overlay); }
      .Polaris-Frame__Navigation--newDesignLanguage .Polaris-Frame__NavigationDismiss:focus{
        position:relative;
        position:absolute; }
        .Polaris-Frame__Navigation--newDesignLanguage .Polaris-Frame__NavigationDismiss:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.1rem;
          right:-0.1rem;
          bottom:-0.1rem;
          left:-0.1rem;
          display:block;
          pointer-events:none;
          box-shadow:0 0 0 -0.1rem var(--p-focused);
          transition:box-shadow 100ms var(--p-ease);
          border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Frame__Navigation--newDesignLanguage .Polaris-Frame__NavigationDismiss:active{
        background-color:var(--p-overlay); }

  .Polaris-Frame__TopBar{
    position:fixed;
    z-index:512;
    top:0;
    left:0;
    width:100%;
    height:5.6rem; }
    @media print{
      .Polaris-Frame__TopBar{
        display:none !important; } }
    @media (min-width: 48.0625em){
      .Polaris-Frame__TopBar.Polaris-Frame__TopBar--newDesignLanguage{
        left:25.8rem;
        width:calc(100% - 25.8rem); } }

  .Polaris-Frame__ContextualSaveBar{
    position:fixed;
    z-index:513;
    top:0;
    left:0;
    width:100%; }

  @media (min-width: 48.0625em){
    .Polaris-Frame__ContextualSaveBar--newDesignLanguage{
      left:25.8rem;
      max-width:calc(100% - 25.8rem); } }

  .Polaris-Frame__Main{
    flex:1 1;
    display:flex;
    align-items:stretch;
    min-width:0;
    max-width:100%;
    padding-right:0px;
    padding-right:calc(0px + constant(safe-area-inset-right));
    padding-right:calc(0px + env(safe-area-inset-right));
    padding-left:0px;
    padding-left:calc(0px + constant(safe-area-inset-left));
    padding-left:calc(0px + env(safe-area-inset-left));
    padding-bottom:0px;
    padding-bottom:calc(0px + constant(safe-area-inset-bottom));
    padding-bottom:calc(0px + env(safe-area-inset-bottom)); }
    @media (min-width: 48.0625em){
      .Polaris-Frame--hasNav .Polaris-Frame__Main{
        padding-left:24rem;
        padding-left:24rem;
        padding-left:calc(24rem + constant(safe-area-inset-left));
        padding-left:calc(24rem + env(safe-area-inset-left)); } }
    @media print and (min-width: 48.0625em){
      .Polaris-Frame--hasNav .Polaris-Frame__Main{
        padding-left:0; } }
    .Polaris-Frame--hasTopBar .Polaris-Frame__Main{
      padding-top:5.6rem; }
      @media print{
        .Polaris-Frame--hasTopBar .Polaris-Frame__Main{
          padding-top:0; } }

  .Polaris-Frame--hasTopBar .Polaris-Frame__Main--newDesignLanguage{
    padding-top:6.8rem; }
    @media print{
      .Polaris-Frame--hasTopBar .Polaris-Frame__Main--newDesignLanguage{
        padding-top:0; } }

  @media (min-width: 48.0625em){
    .Polaris-Frame--hasNav .Polaris-Frame__Main--newDesignLanguage{
      padding-left:25.8rem;
      padding-left:25.8rem;
      padding-left:calc(25.8rem + constant(safe-area-inset-left));
      padding-left:calc(25.8rem + env(safe-area-inset-left)); } }
    @media print and (min-width: 48.0625em){
      .Polaris-Frame--hasNav .Polaris-Frame__Main--newDesignLanguage{
        padding-left:0; } }

  .Polaris-Frame__Content{
    padding-bottom:var(--global-ribbon-height, 0);
    flex:1 1;
    min-width:0;
    max-width:100%; }

  .Polaris-Frame__GlobalRibbonContainer{
    position:fixed;
    z-index:510;
    bottom:0;
    width:100%; }
    @media (min-width: 48.0625em){
      .Polaris-Frame--hasNav .Polaris-Frame__GlobalRibbonContainer{
        left:24rem;
        left:24rem;
        left:calc(24rem + constant(safe-area-inset-left));
        left:calc(24rem + env(safe-area-inset-left));
        width:calc(100% - 24rem); } }

  @media (min-width: 48.0625em){
    .Polaris-Frame--hasNav .Polaris-Frame__GlobalRibbonContainer--newDesignLanguage{
      left:25.8rem;
      left:25.8rem;
      left:calc(25.8rem + constant(safe-area-inset-left));
      left:calc(25.8rem + env(safe-area-inset-left));
      width:calc(100% - 25.8rem); } }

  .Polaris-Frame__LoadingBar{
    position:fixed;
    z-index:514;
    top:0;
    right:0;
    left:0; }
    @media print{
      .Polaris-Frame__LoadingBar{
        display:none !important; } }
    @media (min-width: 48.0625em){
      .Polaris-Frame--hasTopBar .Polaris-Frame__LoadingBar{
        top:var(--p-override-zero, 5.6rem);
        z-index:var(--p-override-loading-z-index, 511); } }

  .Polaris-Frame__Skip{
    position:fixed;
    z-index:517;
    top:1rem;
    left:0.8rem;
    opacity:0;
    pointer-events:none; }
    .Polaris-Frame__Skip.Polaris-Frame--focused{
      pointer-events:all;
      opacity:1; }

  .Polaris-Frame__SkipAnchor{
    position:relative;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    min-height:3.6rem;
    min-width:3.6rem;
    margin:0;
    padding:0.7rem 1.6rem;
    background:linear-gradient(to bottom, white, #f9fafb);
    border:0.1rem solid var(--p-border, #c4cdd5);
    box-shadow:0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    line-height:1;
    color:#212b36;
    text-align:center;
    cursor:pointer;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    transition-property:background, border, box-shadow;
    transition-duration:var(--p-override-none, 200ms);
    transition-timing-function:var(--p-override-none, cubic-bezier(0.64, 0, 0.35, 1));
    -webkit-tap-highlight-color:transparent; }
    .Polaris-Frame__SkipAnchor svg{
      fill:#637381; }
    .Polaris-Frame__SkipAnchor:hover{
      background:linear-gradient(to bottom, #f9fafb, #f4f6f8);
      border-color:#c4cdd5; }
    .Polaris-Frame__SkipAnchor:focus{
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-Frame__SkipAnchor:focus{
          outline:2px dotted; } }
    .Polaris-Frame__SkipAnchor:active{
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      border-color:#c4cdd5;
      box-shadow:0 0 0 0 transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2); }
    .Polaris-Frame__SkipAnchor.Polaris-Frame--newDesignLanguage{
      position:relative;
      background:var(--p-action-secondary);
      border:none;
      box-shadow:none;
      border-radius:var(--p-border-radius-base);
      color:var(--p-text); }
      .Polaris-Frame__SkipAnchor.Polaris-Frame--newDesignLanguage svg{
        fill:var(--p-icon); }
      .Polaris-Frame__SkipAnchor.Polaris-Frame--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Frame__SkipAnchor.Polaris-Frame--newDesignLanguage:hover{
        background:var(--p-action-secondary-hovered);
        border-color:transparent; }
      .Polaris-Frame__SkipAnchor.Polaris-Frame--newDesignLanguage:focus{
        border-color:transparent;
        box-shadow:none; }
        .Polaris-Frame__SkipAnchor.Polaris-Frame--newDesignLanguage:focus::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Frame__SkipAnchor.Polaris-Frame--newDesignLanguage:active{
        background:var(--p-action-secondary-pressed);
        border-color:transparent;
        box-shadow:none; }
        .Polaris-Frame__SkipAnchor.Polaris-Frame--newDesignLanguage:active::after{
          border:none;
          box-shadow:none; }
      @media (-ms-high-contrast: active){
        .Polaris-Frame__SkipAnchor.Polaris-Frame--newDesignLanguage{
          border:1px solid windowText; } }


  .Polaris-Indicator::before, .Polaris-Indicator::after{
    content:'';
    position:absolute;
    background-color:var(--p-border-highlight, #47c1bf);
    right:-0.4rem;
    top:-0.4rem;
    width:1rem;
    height:1rem;
    border-radius:100%; }

  .Polaris-Indicator--pulseIndicator::before{
    z-index:1;
    animation:Polaris-Indicator--bounce 5s ease infinite; }

  .Polaris-Indicator--pulseIndicator::after{
    right:-0.4rem;
    top:-0.4rem;
    animation:Polaris-Indicator--pulse 5s ease infinite; }

  @keyframes Polaris-Indicator--bounce{
    from,
    65%,
    85%{
      transform:scale(1); }
    75%{
      transform:scale(0.85); }
    82.5%{
      transform:scale(1.05); } }

  @keyframes Polaris-Indicator--pulse{
    from,
    75%{
      transform:scale(0.85);
      opacity:1; }
    to{
      transform:scale(2.5);
      opacity:0; } }


  .Polaris-KeyboardKey{
    display:inline-block;
    height:2.4rem;
    margin:0 0.2rem 2px;
    padding:0 0.4rem;
    background-color:white;
    box-shadow:0 0 0 1px #c4cdd5, 0 2px 0 0 white, 0 2px 0 1px #c4cdd5;
    border-radius:3px;
    font-family:-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif;
    font-size:1.2rem;
    font-weight:500;
    line-height:2.4rem;
    color:#637381;
    text-align:center;
    min-width:2.4rem;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none; }


  .Polaris-Layout{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:flex-start;
    margin-top:-2rem;
    margin-left:-2rem; }

  .Polaris-Layout__Section{
    flex:2 2 48rem;
    min-width:51%; }

  .Polaris-Layout__Section--secondary{
    flex:1 1 24rem;
    min-width:0; }

  .Polaris-Layout__Section--fullWidth{
    flex:1 1 100%; }

  .Polaris-Layout__Section--oneHalf{
    flex:1 1 45rem;
    min-width:0; }

  .Polaris-Layout__Section--oneThird{
    flex:1 1 24rem;
    min-width:0; }

  .Polaris-Layout__AnnotatedSection{
    min-width:0;
    flex:1 1 100%; }

  .Polaris-Layout__Section,
  .Polaris-Layout__AnnotatedSection{
    max-width:calc(100% - 2rem);
    margin-top:2rem;
    margin-left:2rem; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection, [data-has-navigation]
      .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection{
        padding-top:2rem;
        border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); } }
    @media (min-width: 30.625em){
      .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection,
      .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection{
        padding-top:2rem;
        border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); } }

  .Polaris-Layout__AnnotationWrapper{
    display:flex;
    flex-wrap:wrap;
    margin-top:-2rem;
    margin-left:-2rem; }

  .Polaris-Layout__AnnotationContent{
    flex:2 2 48rem; }

  .Polaris-Layout__Annotation{
    flex:1 1 24rem;
    padding:1.6rem 2rem 0; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Layout__Annotation{
        padding:1.6rem 0 0; } }
    @media (min-width: 30.625em){
      .Polaris-Layout__Annotation{
        padding:1.6rem 0 0; } }
    @media (max-width: 48.0625em) and (min-width: 50em), (min-width: 65em){
      [data-has-navigation] .Polaris-Layout__Annotation{
        padding:2rem 2rem 2rem 0; } }
    @media (min-width: 50em){
      .Polaris-Layout__Annotation{
        padding:2rem 2rem 2rem 0; } }

  .Polaris-Layout__Annotation,
  .Polaris-Layout__AnnotationContent{
    min-width:0;
    max-width:calc(100% - 2rem);
    margin-top:2rem;
    margin-left:2rem; }

  .Polaris-Layout__AnnotationDescription{
    color:var(--p-text-subdued, #637381); }


  .Polaris-Link{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    display:inline;
    text-align:inherit;
    padding:0;
    background:none;
    border:0;
    font-size:inherit;
    color:var(--p-interactive, #006fbb);
    text-decoration:none;
    cursor:pointer;
    position:relative; }
    .Polaris-Link:hover{
      color:var(--p-interactive-hovered, #084e8a);
      text-decoration:underline; }
    .Polaris-Link:focus{
      -webkit-text-decoration:var(--p-override-none, underline);
              text-decoration:var(--p-override-none, underline);
      outline:var(--p-override-none, rgba(0, 103, 244, 0.247) auto 0.45rem); }
    .Polaris-Link::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
    .Polaris-Link:focus:not(:active)::after{
      box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Link:active{
      position:relative;
      color:var(--p-interactive-pressed, #084e8a);
      -webkit-text-decoration:var(--p-override-none, underline);
              text-decoration:var(--p-override-none, underline); }
      .Polaris-Link:active::before{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:-1;
        top:-0.2rem;
        right:-0.5rem;
        bottom:-0.2rem;
        left:-0.5rem;
        display:block;
        border-radius:3px;
        background:var(--p-action-secondary, none); }

  .Polaris-Link__IconLockup{
    display:inline;
    white-space:nowrap; }
    .Polaris-Link__IconLockup svg{
      fill:currentColor; }
    .Polaris-Link__IconLockup::before{
      content:'\2060'; }

  .Polaris-Link__IconLayout{
    display:inline-flex; }
    .Polaris-Link__IconLayout::before{
      content:'\2060'; }

  .Polaris-Link--monochrome{
    color:inherit;
    text-decoration:underline; }
    .Polaris-Link--monochrome:hover, .Polaris-Link--monochrome:focus, .Polaris-Link--monochrome:active{
      color:inherit; }


  .Polaris-List{
    padding-left:2rem;
    margin-top:0;
    margin-bottom:0;
    list-style:disc outside none; }
    .Polaris-List + .Polaris-List{
      margin-top:1.6rem; }

  .Polaris-List--typeNumber{
    padding-left:3.2rem;
    list-style:decimal outside none; }

  .Polaris-List__Item{
    margin-bottom:0.8rem; }
    .Polaris-List__Item:last-child{
      margin-bottom:0; }
    .Polaris-List__Item .Polaris-List:first-child{
      margin-top:0.8rem; }


  @keyframes Polaris-Navigation__fade--in{
    0%{
      opacity:0; }
    100%{
      opacity:1; } }

  .Polaris-Navigation{
    display:flex;
    flex-direction:column;
    align-items:stretch;
    width:calc(100vw - 6.4rem);
    min-width:24rem;
    max-width:36rem;
    height:100%;
    min-height:100%;
    background-color:var(--p-surface, #f4f6f8);
    -webkit-overflow-scrolling:touch;
    padding-bottom:0px;
    padding-bottom:calc(0px + constant(safe-area-inset-bottom));
    padding-bottom:calc(0px + env(safe-area-inset-bottom)); }
    .Polaris-Navigation:focus{
      outline:none; }
    @media (min-width: 48.0625em){
      .Polaris-Navigation{
        max-width:24rem;
        border-right:var(--p-override-none, 0.1rem solid var(--p-border-subdued, #dfe3e8));
        max-width:24rem;
        max-width:calc(24rem + constant(safe-area-inset-left));
        max-width:calc(24rem + env(safe-area-inset-left)); } }
    .Polaris-Navigation.Polaris-Navigation__Navigation--newDesignLanguage{
      max-width:25.8rem;
      border-right:1px solid var(--p-border-subdued); }

  .Polaris-Navigation__Navigation--noMedia{
    padding-top:1.6rem; }

  .Polaris-Navigation__UserMenu{
    flex:0 0 auto; }

  @media (min-width: 48.0625em){
    .Polaris-Navigation__ContextControl{
      display:none; }
      .Polaris-Navigation__Navigation--newDesignLanguage .Polaris-Navigation__ContextControl{
        display:block; } }

  .Polaris-Navigation__PrimaryNavigation{
    display:flex;
    overflow:auto;
    flex:1 1 auto;
    flex-direction:column;
    align-items:stretch;
    max-width:100%; }

  .Polaris-Navigation__LogoContainer{
    display:none; }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__LogoContainer{
        display:flex;
        flex:0 0 6.8rem;
        align-items:center;
        height:100%;
        padding:0 0.8rem 0 1.6rem;
        flex-basis:6.8rem;
        flex-basis:calc(6.8rem + constant(safe-area-inset-left));
        flex-basis:calc(6.8rem + env(safe-area-inset-left));
        padding-left:1.6rem;
        padding-left:calc(1.6rem + constant(safe-area-inset-left));
        padding-left:calc(1.6rem + env(safe-area-inset-left)); } }

  .Polaris-Navigation__Logo,
  .Polaris-Navigation__LogoLink{
    display:block; }

  .Polaris-Navigation__Item{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    font-size:1.6rem;
    font-weight:400;
    line-height:4rem;
    display:flex;
    flex-grow:1;
    align-items:flex-start;
    max-width:100%;
    padding:0 0.8rem;
    margin:0 0.8rem;
    border-radius:var(--p-border-radius-base, 3px);
    color:var(--p-text, #212b36);
    text-decoration:none;
    text-align:left;
    position:relative; }
    .Polaris-Navigation__Item:focus{
      outline:none; }
    .Polaris-Navigation__Item:hover{
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
      background-color:var(--p-surface-hovered);
      color:var(--p-action-primary, #202e78);
      text-decoration:none; }
      .Polaris-Navigation__Item:hover .Polaris-Navigation__Icon svg{
        fill:var(--p-action-primary, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__Item:hover .Polaris-Navigation__Icon img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__Item::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
    .Polaris-Navigation__Item:focus{
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
      color:var(--p-text, #202e78);
      text-decoration:none; }
      .Polaris-Navigation__Item:focus::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Navigation__Item:focus .Polaris-Navigation__Icon svg{
        fill:var(--p-icon, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__Item:focus .Polaris-Navigation__Icon img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__Item:focus:hover{
      color:var(--p-action-primary, #202e78); }
      .Polaris-Navigation__Item:focus:hover .Polaris-Navigation__Icon svg{
        fill:var(--p-action-primary, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__Item:focus:hover .Polaris-Navigation__Icon img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__Item:active, .Polaris-Navigation__Item:active:hover{
      color:var(--p-action-primary-pressed, #202e78); }
      .Polaris-Navigation__Item:active::after, .Polaris-Navigation__Item:active:hover::after{
        content:none; }
      .Polaris-Navigation__Item:active .Polaris-Navigation__Icon svg, .Polaris-Navigation__Item:active:hover .Polaris-Navigation__Icon svg{
        fill:var(--p-action-primary-pressed, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__Item:active .Polaris-Navigation__Icon img, .Polaris-Navigation__Item:active:hover .Polaris-Navigation__Icon img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__Item{
        font-size:1.4rem;
        font-weight:500;
        line-height:3.2rem; } }
    .Polaris-Navigation__Item::-moz-focus-inner{
      border:0; }

  .Polaris-Navigation__Item--selected{
    font-weight:600;
    color:var(--p-action-primary, #202e78);
    background-color:var(--p-surface-primary-selected, rgba(92, 106, 196, 0.12));
    position:relative; }
    @media (-ms-high-contrast: active){
      .Polaris-Navigation__Item--selected{
        background-color:highlight; } }
    .Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg,
    .Polaris-Navigation__Item--selected:focus .Polaris-Navigation__Icon svg{
      fill:var(--p-action-primary, #5c6ac4);
      color:var(--p-override-transparent, white); }
    .Polaris-Navigation__Item--selected:hover{
      background-color:var(--p-surface-primary-selected-hovered, rgba(92, 106, 196, 0.12));
      color:var(--p-action-primary-hovered, #202e78); }
    .Polaris-Navigation__Item--selected::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
    .Polaris-Navigation__Item--selected:focus{
      color:var(--p-action-primary, #202e78); }
      .Polaris-Navigation__Item--selected:focus::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Navigation__Item--selected:active{
      background-color:var(--p-surface-primary-selected-pressed, rgba(92, 106, 196, 0.12));
      color:var(--p-action-primary-pressed, #202e78); }
      .Polaris-Navigation__Item--selected:active::after{
        content:none; }

  .Polaris-Navigation__Item--disabled{
    color:var(--p-text-disabled, #919eab);
    pointer-events:none;
    opacity:var(--p-override-one, 0.6); }
    .Polaris-Navigation__Item--disabled .Polaris-Navigation__Icon svg{
      fill:var(--p-icon-disabled, #919eab);
      color:var(--p-override-transparent, white); }

  .Polaris-Navigation__Badge{
    margin-left:0.8rem;
    display:inline-flex;
    margin-top:1.2rem; }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__Badge{
        margin-top:0.8rem; } }

  .Polaris-Navigation__Icon{
    flex-shrink:0;
    align-self:flex-start;
    width:2rem;
    height:2rem;
    margin-top:1rem;
    margin-right:2rem;
    margin-bottom:1rem; }
    .Polaris-Navigation__Icon svg{
      fill:var(--p-icon, #919eab);
      color:var(--p-override-transparent, white); }
    .Polaris-Navigation__Icon img{
      -webkit-filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%);
              filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%); }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__Icon{
        margin-top:0.6rem;
        margin-right:1.6rem;
        margin-bottom:0.6rem; } }
    .Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg,
    .Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon svg,
    .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg,
    .Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon svg,
    .Polaris-Navigation--subNavigationActive:focus .Polaris-Navigation__Icon svg{
      fill:var(--p-action-primary, #5c6ac4);
      color:var(--p-override-transparent, white); }
    .Polaris-Navigation__Item--selected .Polaris-Navigation__Icon img,
    .Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon img,
    .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon img,
    .Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon img,
    .Polaris-Navigation--subNavigationActive:focus .Polaris-Navigation__Icon img{
      -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
              filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__Icon svg{
      display:block; }

  .Polaris-Navigation__ListItem{
    position:relative;
    display:flex;
    flex-wrap:wrap; }
    .Polaris-Navigation__RollupSection .Polaris-Navigation__ListItem,
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__ListItem{
      opacity:0;
      animation:Polaris-Navigation__fade--in 500ms 1 forwards; }
    .Polaris-Navigation__ListItem:nth-child(1){
      animation-delay:0ms; }
    .Polaris-Navigation__ListItem:nth-child(2){
      animation-delay:50ms; }
    .Polaris-Navigation__ListItem:nth-child(3){
      animation-delay:100ms; }
    .Polaris-Navigation__ListItem:nth-child(4){
      animation-delay:150ms; }
    .Polaris-Navigation__ListItem:nth-child(5){
      animation-delay:200ms; }
    .Polaris-Navigation__ListItem:nth-child(6){
      animation-delay:250ms; }
    .Polaris-Navigation__ListItem:nth-child(7){
      animation-delay:300ms; }
    .Polaris-Navigation__ListItem:nth-child(8){
      animation-delay:350ms; }
    .Polaris-Navigation__ListItem:nth-child(9){
      animation-delay:400ms; }
    .Polaris-Navigation__ListItem:nth-child(10){
      animation-delay:450ms; }
    .Polaris-Navigation__ListItem:nth-child(11){
      animation-delay:500ms; }
    .Polaris-Navigation__ListItem:nth-child(12){
      animation-delay:550ms; }

  .Polaris-Navigation__ListItem--hasAction .Polaris-Navigation__Item{
    max-width:calc(100% - 5.6rem); }

  .Polaris-Navigation__ItemWrapper{
    display:flex;
    flex-wrap:nowrap;
    width:100%; }

  .Polaris-Navigation__Text{
    flex:1 1 auto;
    margin-top:1rem;
    margin-bottom:1rem;
    line-height:2rem; }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__Text{
        margin-top:0.6rem;
        margin-bottom:0.6rem; } }

  .Polaris-Navigation__SecondaryAction{
    display:flex;
    align-items:center;
    height:4rem;
    margin-right:0.4rem;
    padding:0.4rem 1.6rem;
    border-radius:var(--p-border-radius-base, 3px);
    position:relative; }
    .Polaris-Navigation__SecondaryAction svg{
      fill:var(--p-icon-subdued, #919eab);
      color:var(--p-override-transparent, white); }
    .Polaris-Navigation__SecondaryAction img{
      -webkit-filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%);
              filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%); }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__SecondaryAction{
        height:3.2rem; } }
    .Polaris-Navigation__SecondaryAction:hover{
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
      background-color:var(--p-surface-hovered); }
      .Polaris-Navigation__SecondaryAction:hover svg{
        fill:var(--p-action-primary, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__SecondaryAction:hover img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__SecondaryAction::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
    .Polaris-Navigation__SecondaryAction:focus{
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3))); }
      .Polaris-Navigation__SecondaryAction:focus svg{
        fill:var(--p-icon-subdued, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__SecondaryAction:focus img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
      .Polaris-Navigation__SecondaryAction:focus::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-Navigation__SecondaryAction:focus:hover svg{
      fill:var(--p-action-primary, #5c6ac4);
      color:var(--p-override-transparent, white); }
    .Polaris-Navigation__SecondaryAction:focus:hover img{
      -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
              filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__SecondaryAction:active{
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
      background-color:var(--p-surface-pressed); }
      .Polaris-Navigation__SecondaryAction:active svg{
        fill:var(--p-action-primary, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__SecondaryAction:active img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
      .Polaris-Navigation__SecondaryAction:active::after{
        content:none; }
    .Polaris-Navigation__SecondaryAction:focus, .Polaris-Navigation__SecondaryAction:active{
      outline:none; }

  .Polaris-Navigation__SecondaryNavigation{
    flex-basis:100%;
    margin-bottom:0.8rem;
    margin-left:4rem;
    overflow-x:var(--p-override-visible, hidden); }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__SecondaryNavigation{
        margin-left:3.6rem; } }
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__List{
      margin:0;
      padding:0;
      list-style:none; }
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item{
      font-size:1.5rem;
      font-weight:400;
      line-height:4rem;
      color:var(--p-text, #454f5b);
      position:relative; }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:hover{
        color:var(--p-action-primary, #202e78); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:focus{
        color:var(--p-text, #202e78); }
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:focus::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:focus:hover{
        color:var(--p-action-primary, #202e78); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active, .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active:hover{
        color:var(--p-action-primary-pressed, #202e78); }
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active::after, .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active:hover::after{
          content:none; }
      @media (min-width: 48.0625em){
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item{
          font-size:1.4rem;
          line-height:2.8rem; } }
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text{
      margin-top:1rem;
      margin-bottom:1rem;
      line-height:2rem; }
      @media (min-width: 48.0625em){
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text{
          margin-top:0.4rem;
          margin-bottom:0.4rem; } }
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected{
      font-weight:600;
      color:var(--p-action-primary, #202e78);
      position:relative; }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:hover{
        color:var(--p-action-primary-hovered, #202e78); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:focus{
        color:var(--p-action-primary, #202e78); }
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:focus::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:active{
        color:var(--p-action-primary-pressed, #202e78); }
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:active::after{
          content:none; }
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--disabled{
      font-weight:400;
      color:var(--p-text-disabled, #919eab); }

  .Polaris-Navigation__Section{
    margin:0;
    padding:0;
    list-style:none;
    flex:0 0 auto;
    padding:1.6rem 0;
    padding-left:0px;
    padding-left:calc(0px + constant(safe-area-inset-left));
    padding-left:calc(0px + env(safe-area-inset-left)); }
    .Polaris-Navigation__Navigation--newDesignLanguage .Polaris-Navigation__Section{
      padding-top:0; }
    .Polaris-Navigation__Section + .Polaris-Navigation__Section{
      padding-top:0.4rem;
      padding-bottom:1.6rem; }

  .Polaris-Navigation__Section--fill{
    flex:1 0 auto; }

  .Polaris-Navigation__Section--withSeparator{
    border-top:var(--p-thin-border-subdued, 0.1rem solid var(--p-border-subdued, #dfe3e8)); }

  .Polaris-Navigation__SectionHeading{
    font-size:1.3rem;
    font-weight:600;
    line-height:1.6rem;
    text-transform:uppercase;
    display:flex;
    align-items:center;
    padding-left:1.6rem;
    color:var(--p-text-subdued, #637381); }
    @media (min-width: 40em){
      .Polaris-Navigation__SectionHeading{
        font-size:1.2rem; } }
    .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action{
      -webkit-appearance:none;
         -moz-appearance:none;
              appearance:none;
      margin:0;
      padding:0;
      background:none;
      border:none;
      font-size:inherit;
      line-height:inherit;
      cursor:pointer;
      display:flex;
      align-items:center;
      margin-right:0.4rem;
      padding:0.4rem 1.6rem;
      border-radius:var(--p-border-radius-base, 3px);
      position:relative; }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus{
        outline:none; }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action svg{
        fill:var(--p-icon-subdued, #919eab);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action img{
        -webkit-filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%);
                filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover{
        background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
        background-color:var(--p-surface-hovered); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg{
          fill:var(--p-action-primary, #5c6ac4);
          color:var(--p-override-transparent, white); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover img{
          -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                  filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus{
        background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3))); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus svg{
          fill:var(--p-icon-subdued, #5c6ac4);
          color:var(--p-override-transparent, white); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus img{
          -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                  filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus:hover svg{
        fill:var(--p-action-primary, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus:hover img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active{
        background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
        background-color:var(--p-surface-pressed); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active svg{
          fill:var(--p-action-primary, #5c6ac4);
          color:var(--p-override-transparent, white); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active img{
          -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                  filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active::after{
          content:none; }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active{
        outline:none; }

  .Polaris-Navigation__RollupToggle{
    font-weight:400;
    color:var(--p-text, #212b36);
    color:var(--p-text-subdued, #637381); }
    .Polaris-Navigation__RollupToggle:hover{
      color:var(--p-action-primary, #5c6ac4); }
      .Polaris-Navigation__RollupToggle:hover svg{
        fill:var(--p-action-primary, #5c6ac4); }
      .Polaris-Navigation__RollupToggle:hover img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__RollupToggle:focus{
      outline:none; }
      .Polaris-Navigation__RollupToggle:focus::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }

  .Polaris-Navigation__List{
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-Navigation__Indicator{
    position:relative;
    display:inline-block;
    height:1rem;
    width:1rem; }


  .Polaris-Navigation-Message{
    padding:1rem 1.6rem;
    color:#454f5b; }


  .Polaris-Tooltip{
    margin:0.4rem 1.6rem 1.6rem;
    opacity:1;
    box-shadow:var(--p-popover-shadow, 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08));
    border-radius:var(--p-border-radius-base, 3px);
    pointer-events:none;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity, left, top;
    transition:opacity 200ms cubic-bezier(0.36, 0, 1, 1) 100ms; }
    @media screen and (-ms-high-contrast: active){
      .Polaris-Tooltip{
        border:0.2rem solid windowText; } }

  .Polaris-Tooltip--measuring{
    opacity:0; }

  .Polaris-Tooltip--positionedAbove{
    margin:1.6rem 1.6rem 0.4rem; }

  .Polaris-Tooltip--light .Polaris-Tooltip__Wrapper{
    background:var(--p-surface, white);
    color:var(--p-text, #212b36); }

  .Polaris-Tooltip__Wrapper{
    position:relative;
    display:flex;
    background-color:var(--p-surface, #212b36);
    border-radius:var(--p-border-radius-base, 3px);
    color:var(--p-text, white);
    max-height:10rem; }

  .Polaris-Tooltip__Content{
    position:relative;
    border-radius:3px;
    max-width:20rem;
    max-height:10rem; }

  .Polaris-Tooltip__Label{
    padding:0.4rem 0.8rem;
    word-break:break-word; }


  .Polaris-Pagination{
    display:inline-flex; }
    @media (max-width: 30.625em), (min-width: 48.0625em) and (max-width: 47.125em){
      [data-has-navigation] .Polaris-Pagination:not(.Polaris-Pagination--plain){
        display:flex;
        width:100%; }
        [data-has-navigation] .Polaris-Pagination:not(.Polaris-Pagination--plain) .Polaris-Pagination__Button{
          flex:1 0 auto; } }
    @media (max-width: 30.625em){
      .Polaris-Pagination:not(.Polaris-Pagination--plain){
        display:flex;
        width:100%; }
        .Polaris-Pagination:not(.Polaris-Pagination--plain) .Polaris-Pagination__Button{
          flex:1 0 auto; } }

  .Polaris-Pagination--plain .Polaris-Pagination__Button{
    position:relative;
    margin-top:-0.4rem;
    margin-bottom:-0.4rem;
    padding-top:0.8rem;
    padding-bottom:0.8rem;
    background:transparent;
    border:none;
    cursor:pointer;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    box-shadow:none; }
    .Polaris-Pagination--plain .Polaris-Pagination__Button:hover, .Polaris-Pagination--plain .Polaris-Pagination__Button:active{
      background:transparent;
      border:none;
      box-shadow:none; }
      .Polaris-Pagination--plain .Polaris-Pagination__Button:hover svg, .Polaris-Pagination--plain .Polaris-Pagination__Button:active svg{
        fill:#212b36; }
    .Polaris-Pagination--plain .Polaris-Pagination__Button:focus{
      border:none;
      box-shadow:none; }
      .Polaris-Pagination--plain .Polaris-Pagination__Button:focus::after:not(.Polaris-Pagination--newDesignLanguage){
        background:rgba(33, 43, 54, 0.1); }
    .Polaris-Pagination--plain .Polaris-Pagination__Button:disabled{
      background:transparent;
      border:none;
      box-shadow:none; }
      .Polaris-Pagination--plain .Polaris-Pagination__Button:disabled svg{
        fill:#c4cdd5; }
    .Polaris-Pagination--plain .Polaris-Pagination__Button::after:not(.Polaris-Pagination--newDesignLanguage){
      content:'';
      position:absolute;
      top:0.4rem;
      right:0;
      left:0;
      display:block;
      height:2.8rem;
      border-radius:3px;
      -webkit-backface-visibility:hidden;
              backface-visibility:hidden;
      will-change:background;
      transition-property:background;
      transition-duration:200ms;
      transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Pagination--plain .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage{
      position:relative; }
      .Polaris-Pagination--plain .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage svg{
        fill:var(--p-icon); }
      .Polaris-Pagination--plain .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Pagination--plain .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:hover, .Polaris-Pagination--plain .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:active, .Polaris-Pagination--plain .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:disabled{
        background:transparent;
        border:none;
        box-shadow:none; }
      .Polaris-Pagination--plain .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:hover{
        background:var(--p-action-secondary-hovered); }
      .Polaris-Pagination--plain .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:active{
        background:var(--p-action-secondary-pressed); }
      .Polaris-Pagination--plain .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:focus:not(:active)::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Pagination--plain .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:disabled svg{
        fill:var(--p-icon-disabled); }

  .Polaris-Pagination--plain .Polaris-Pagination__PreviousButton{
    margin-left:-0.8rem; }

  .Polaris-Pagination--plain .Polaris-Pagination__NextButton{
    margin-right:-0.8rem;
    margin-left:0.2rem; }

  .Polaris-Pagination__Button{
    position:relative;
    z-index:10;
    display:inline-block;
    min-width:3.6rem;
    margin:0;
    padding:0.7rem 0.8rem;
    background:var(--p-action-secondary, linear-gradient(to bottom, white, #f9fafb));
    border:0.1rem solid var(--p-border, #c4cdd5);
    border-radius:3px;
    line-height:1;
    color:var(--p-text, #212b36);
    text-align:center;
    cursor:pointer;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    transition-property:color, background, border, box-shadow;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Pagination__Button svg{
      fill:var(--p-icon, #637381); }
    .Polaris-Pagination__Button:hover{
      background:linear-gradient(to bottom, #f9fafb, #f4f6f8);
      border-color:#c4cdd5; }
    .Polaris-Pagination__Button:focus{
      z-index:20;
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
    .Polaris-Pagination__Button:active{
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      border-color:#c4cdd5;
      box-shadow:0 0 0 0 transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2); }
    .Polaris-Pagination__Button:disabled{
      background:#f4f6f8;
      color:#919eab;
      cursor:default;
      box-shadow:none; }
      .Polaris-Pagination__Button:disabled svg{
        fill:#919eab; }
    .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage{
      position:relative;
      box-shadow:none;
      border-color:transparent;
      border-radius:var(--p-border-radius-base); }
      .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
      .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage.Polaris-Pagination--rightButton{
        margin-left:0.8rem; }
      .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:hover{
        background:var(--p-action-secondary-hovered); }
      .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:focus{
        outline:0; }
      .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:focus:not(:active){
        z-index:20; }
        .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:focus:not(:active)::after{
          box-shadow:0 0 0 0.2rem var(--p-focused); }
      .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:active{
        background:var(--p-action-secondary-pressed); }
      .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:disabled{
        background:var(--p-action-secondary-disabled);
        color:var(--p-text-disabled);
        cursor:default;
        box-shadow:none; }
        .Polaris-Pagination__Button.Polaris-Pagination--newDesignLanguage:disabled svg{
          fill:var(--p-icon-disabled); }

  .Polaris-Pagination__PreviousButton{
    border-top-right-radius:0;
    border-bottom-right-radius:0; }

  .Polaris-Pagination__NextButton{
    margin-left:-1px;
    border-top-left-radius:0;
    border-bottom-left-radius:0; }

  .Polaris-Pagination__Label{
    padding:0 0.8rem;
    display:flex;
    align-items:center;
    justify-content:center; }


  .Polaris-Header-Title{
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word; }

  .Polaris-Header-Title__SubTitle{
    margin-top:0.8rem; }

  .Polaris-Header-Title--hasThumbnail{
    display:grid;
    grid-gap:1.6rem;
    grid-template-columns:auto 1fr; }
    .Polaris-Header-Title--hasThumbnail .Polaris-Header-Title__TitleAndSubtitleWrapper{
      display:flex;
      flex-direction:column;
      justify-content:center; }

  .Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title{
    display:inline;
    margin-right:0.8rem; }
    .Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title > *{
      display:inline; }

  .Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title__TitleMetadata{
    margin-top:0.8rem;
    display:inline-block; }


  .Polaris-Page-Header{
    padding:2rem 2rem 0;
    position:relative; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Page-Header{
        padding-left:0;
        padding-right:0; } }
    @media (min-width: 30.625em){
      .Polaris-Page-Header{
        padding-left:0;
        padding-right:0; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-Page-Header{
        padding-top:3.2rem; } }
    @media (min-width: 46.5em){
      .Polaris-Page-Header{
        padding-top:3.2rem; } }

  .Polaris-Page-Header--separator{
    padding-bottom:1.6rem;
    border-bottom:0.1rem solid var(--p-border-subdued, #dfe3e8); }

  .Polaris-Page-Header--titleHidden{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-Page-Header__Navigation{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:0.4rem; }
    .Polaris-Page-Header--hasActionMenu.Polaris-Page-Header--mobileView .Polaris-Page-Header__Navigation{
      padding-right:3.6rem; }
    @media print{
      .Polaris-Page-Header__Navigation{
        display:none !important; } }

  .Polaris-Page-Header__BreadcrumbWrapper{
    flex:0 1 auto;
    max-width:100%; }

  .Polaris-Page-Header__PaginationWrapper{
    flex:0 0 auto;
    margin-left:auto;
    line-height:1; }

  .Polaris-Page-Header:not(.Polaris-Page-Header--mobileView) .Polaris-Page-Header__MainContent{
    display:flex;
    align-items:center; }

  .Polaris-Page-Header__TitleActionMenuWrapper{
    flex:1 1 auto; }
    .Polaris-Page-Header:not(.Polaris-Page-Header--mobileView) .Polaris-Page-Header__TitleActionMenuWrapper{
      padding-right:2rem; }
    .Polaris-Page-Header--hasActionMenu.Polaris-Page-Header--mobileView:not(.Polaris-Page-Header--hasNavigation) .Polaris-Page-Header__TitleActionMenuWrapper{
      padding-right:3.6rem; }

  .Polaris-Page-Header__PrimaryActionWrapper{
    flex:0 0 auto; }
    .Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper{
      margin-top:1.6rem; }
    @media print{
      .Polaris-Page-Header__PrimaryActionWrapper{
        display:none !important; } }

  .Polaris-Page-Header__ActionMenuWrapper{
    margin-top:0.8rem; }
    .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper{
      position:absolute;
      top:2.9rem;
      right:0;
      margin-top:0; }
      @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
        [data-has-navigation] .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper{
          right:-0.8rem; } }
      @media (min-width: 30.625em){
        .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper{
          right:-0.8rem; } }
    .Polaris-Page-Header--mobileView.Polaris-Page-Header--hasNavigation .Polaris-Page-Header__ActionMenuWrapper{
      top:1.8rem; }
    @media print{
      .Polaris-Page-Header__ActionMenuWrapper{
        display:none !important; } }


  html,
  body{
    min-height:100%;
    height:100%; }

  .Polaris-Page{
    margin:0 auto;
    padding:0;
    max-width:99.8rem; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Page{
        padding:0 2rem; } }
    @media (min-width: 30.625em){
      .Polaris-Page{
        padding:0 2rem; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-Page{
        padding:0 3.2rem; } }
    @media (min-width: 46.5em){
      .Polaris-Page{
        padding:0 3.2rem; } }
    .Polaris-Page::after{
      content:'';
      display:table; }

  .Polaris-Page--fullWidth{
    max-width:none; }

  .Polaris-Page--narrowWidth{
    max-width:66.2rem; }

  .Polaris-Page__Content{
    margin:2rem 0; }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-Page__Content{
        margin-top:2rem; } }
    @media (min-width: 46.5em){
      .Polaris-Page__Content{
        margin-top:2rem; } }


  .Polaris-PageActions{
    margin:0 auto;
    padding:2rem;
    border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-PageActions{
        padding:2rem 0; } }
    @media (min-width: 30.625em){
      .Polaris-PageActions{
        padding:2rem 0; } }


  @keyframes Polaris-ProgressBar--fillup{
    0%{
      width:0; } }

  .Polaris-ProgressBar{
    overflow:hidden;
    width:100%;
    background-color:var(--p-action-secondary, #dfe3e8);
    border-radius:3px; }
    @media screen and (-ms-high-contrast: active){
      .Polaris-ProgressBar{
        border:1px solid windowText; } }

  .Polaris-ProgressBar--sizeSmall{
    height:0.8rem; }

  .Polaris-ProgressBar--sizeMedium{
    height:1.6rem; }

  .Polaris-ProgressBar--sizeLarge{
    height:3.2rem; }

  .Polaris-ProgressBar__Indicator{
    height:inherit;
    width:0;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:width;
    background-color:var(--p-border-highlight, #47c1bf);
    animation:Polaris-ProgressBar--fillup 500ms cubic-bezier(0.64, 0, 0.35, 1);
    transition:width 500ms cubic-bezier(0.64, 0, 0.35, 1); }
    @media screen and (-ms-high-contrast: active){
      .Polaris-ProgressBar__Indicator{
        border:1.6rem solid highlight; } }

  .Polaris-ProgressBar__Progress,
  .Polaris-ProgressBar__Label{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }


  .Polaris-RangeSlider-DualThumb__Wrapper{
    position:relative;
    width:100%;
    display:flex;
    align-items:center; }

  .Polaris-RangeSlider-DualThumb__TrackWrapper{
    position:relative;
    display:flex;
    align-items:center;
    width:100%;
    min-height:2.8rem;
    cursor:pointer; }
    .Polaris-RangeSlider-DualThumb__TrackWrapper.Polaris-RangeSlider-DualThumb--disabled{
      opacity:0.8;
      cursor:not-allowed; }

  .Polaris-RangeSlider-DualThumb__Track{
    position:absolute;
    z-index:1;
    width:100%;
    height:0.4rem;
    border-radius:2.4rem;
    --unselected-range:var(--p-override-transparent, #c4cdd5);
    --selected-range:var(--p-interactive, #5c6ac4);
    --gradient-colors:var(--unselected-range, transparent) 0%,
      var(--unselected-range, transparent)
        var(--Polaris-RangeSlider-progress-lower),
      var(--selected-range, transparent) var(--Polaris-RangeSlider-progress-lower),
      var(--selected-range, transparent) var(--Polaris-RangeSlider-progress-upper),
      var(--unselected-range, transparent)
        var(--Polaris-RangeSlider-progress-upper),
      var(--unselected-range, transparent) 100%;
    background-image:linear-gradient(to right, var(--gradient-colors)); }
    .Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Track{
      --selected-range:var(--p-action-critical, #de3618);
      --gradient-colors:var(--unselected-range, transparent) 0%,
        var(--unselected-range, transparent)
          var(--Polaris-RangeSlider-progress-lower),
        var(--selected-range, transparent)
          var(--Polaris-RangeSlider-progress-lower),
        var(--selected-range, transparent)
          var(--Polaris-RangeSlider-progress-upper),
        var(--unselected-range, transparent)
          var(--Polaris-RangeSlider-progress-upper),
        var(--unselected-range, transparent) 100%;
      background-image:linear-gradient(to right, var(--gradient-colors)); }
    .Polaris-RangeSlider-DualThumb--disabled .Polaris-RangeSlider-DualThumb__Track{
      background-image:none;
      background:var(--p-border-secondary-disabled, #c4cdd5); }

  .Polaris-RangeSlider-DualThumb--trackDashed{
    content:'';
    position:absolute;
    height:0.4rem;
    width:100%;
    background-image:linear-gradient(to right, var(--p-border-secondary), var(--p-border-secondary) 50%, transparent 50%, transparent 100%);
    background-size:0.4rem 0.4rem;
    border-radius:var(--p-border-radius-base, 3px);
    border-right:var(--p-border-radius-base, 3px) var(--p-border-secondary) solid; }

  .Polaris-RangeSlider-DualThumb__Thumbs{
    position:absolute;
    z-index:20;
    width:2.4rem;
    height:2.4rem;
    border-radius:50%;
    border:0.1rem solid #f9fafb;
    box-shadow:0 0 0 0.1rem rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(22, 29, 37, 0.05);
    background:linear-gradient(#f9fafb, #f4f6f8);
    -webkit-tap-highlight-color:transparent;
    cursor:-webkit-grab; }
    .Polaris-RangeSlider-DualThumb__Thumbs.Polaris-RangeSlider-DualThumb--disabled{
      cursor:not-allowed;
      border-color:#c4cdd5; }
    .Polaris-RangeSlider-DualThumb__Thumbs:hover{
      background:linear-gradient(#f9fafb, #f4f6f8);
      box-shadow:0 0 0 0.1rem rgba(0, 0, 0, 0.4), 0 1px 0 0 rgba(22, 29, 37, 0.05); }
    .Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Thumbs{
      border-color:#de3618;
      box-shadow:0 0 0 0.1rem #de3618; }
    .Polaris-RangeSlider-DualThumb__Thumbs:focus{
      outline:0;
      background:linear-gradient(#f9fafb, #f4f6f8);
      border-color:#5c6ac4;
      box-shadow:0 0 0 0.1rem #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-RangeSlider-DualThumb__Thumbs:focus{
          outline:1px solid windowText; } }

  .Polaris-RangeSlider-DualThumb__Prefix{
    flex:0 0 auto;
    margin-right:0.8rem; }

  .Polaris-RangeSlider-DualThumb__Suffix{
    flex:0 0 auto;
    margin-left:0.8rem; }

  .Polaris-RangeSlider-DualThumb__Output{
    position:absolute;
    z-index:10;
    bottom:2.4rem;
    opacity:0;
    visibility:hidden;
    pointer-events:none;
    transition-property:opacity, visibility;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    transform:translateX(calc(-50% + 1.2rem)); }
    .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output,
    .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output,
    .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output{
      opacity:1;
      visibility:visible; }

  .Polaris-RangeSlider-DualThumb__OutputBubble{
    position:relative;
    display:flex;
    padding:0 0.8rem;
    min-width:3.2rem;
    height:3.2rem;
    background-color:#212b36;
    border-radius:3px;
    transition-property:transform;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble,
    .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble,
    .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble{
      transform:translateY(-1.6rem); }
      @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
        [data-has-navigation] .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, [data-has-navigation]
        .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, [data-has-navigation]
        .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble{
          transform:translateY(-0.8rem); } }
      @media (min-width: 46.5em){
        .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble,
        .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble,
        .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble{
          transform:translateY(-0.8rem); } }

  .Polaris-RangeSlider-DualThumb__OutputText{
    font-size:1.3rem;
    font-weight:600;
    line-height:1.6rem;
    text-transform:uppercase;
    display:block;
    flex:1 1 auto;
    margin:auto;
    text-align:center;
    color:white; }
    @media (min-width: 40em){
      .Polaris-RangeSlider-DualThumb__OutputText{
        font-size:1.2rem; } }


  .Polaris-RangeSlider-SingleThumb{
    display:flex;
    align-items:center; }
    .Polaris-RangeSlider-SingleThumb.Polaris-RangeSlider-SingleThumb--disabled{
      opacity:0.8; }

  .Polaris-RangeSlider-SingleThumb__InputWrapper{
    position:relative;
    display:flex;
    align-items:center;
    flex:1 1 auto;
    height:2.4rem; }
    .Polaris-RangeSlider-SingleThumb__InputWrapper input{
      padding:1.2rem 0;
      background-color:transparent;
      cursor:pointer; }
    .Polaris-RangeSlider-SingleThumb__InputWrapper::after{
      content:'';
      position:absolute;
      height:0.4rem;
      width:100%;
      background-image:linear-gradient(to right, var(--p-border-secondary), var(--p-border-secondary) 50%, transparent 50%, transparent 100%);
      background-size:0.4rem 0.4rem;
      border-radius:var(--p-border-radius-base, 3px);
      border-right:var(--p-border-radius-base, 3px) var(--p-border-secondary) solid; }

  .Polaris-RangeSlider-SingleThumb--disabled input{
    cursor:not-allowed; }

  .Polaris-RangeSlider-SingleThumb__Prefix{
    flex:0 0 auto;
    margin-right:0.8rem; }

  .Polaris-RangeSlider-SingleThumb__Suffix{
    flex:0 0 auto;
    margin-left:0.8rem; }

  .Polaris-RangeSlider-SingleThumb__Input{
    --progress-lower:var(--p-interactive, #5c6ac4);
    --progress-upper:var(--p-override-transparent, #c4cdd5);
    --gradient-colors:var(--progress-lower, transparent) 0%,
      var(--progress-lower, transparent) var(--Polaris-RangeSlider-progress, 0%),
      var(--progress-upper, transparent) var(--Polaris-RangeSlider-progress, 100%),
      var(--progress-upper, transparent) 100%;
    margin:0;
    padding:0;
    width:100%;
    background-color:transparent;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    position:relative;
    z-index:20;
    flex:1 1 auto; }
    .Polaris-RangeSlider-SingleThumb__Input::-ms-tooltip{
      display:none; }
    .Polaris-RangeSlider-SingleThumb__Input:focus{
      outline:0; }
    .Polaris-RangeSlider-SingleThumb__Input::-moz-focus-outer{
      border:0; }
    .Polaris-RangeSlider-SingleThumb__Input::-ms-track{
      cursor:pointer;
      width:100%;
      height:0.4rem;
      background-image:linear-gradient(to right, var(--gradient-colors));
      border:none;
      border-radius:0.4rem; }
    .Polaris-RangeSlider-SingleThumb__Input::-moz-range-track{
      cursor:pointer;
      width:100%;
      height:0.4rem;
      background-image:linear-gradient(to right, var(--gradient-colors));
      border:none;
      border-radius:0.4rem; }
    .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track{
      cursor:pointer;
      width:100%;
      height:0.4rem;
      background-image:linear-gradient(to right, var(--gradient-colors));
      border:none;
      border-radius:0.4rem; }
    .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb{
      cursor:-webkit-grab;
      width:2.4rem;
      height:2.4rem;
      border:0.1rem solid #f9fafb;
      border-radius:50%;
      background:linear-gradient(white, #f9fafb);
      box-shadow:0 0 0 0.1rem rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(22, 29, 37, 0.05);
      appearance:none;
      transition-property:border-color, box-shadow;
      transition-duration:200ms;
      transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
      .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb:hover{
        background:linear-gradient(#f9fafb, #f4f6f8);
        box-shadow:0 0 0 0.1rem rgba(0, 0, 0, 0.4), 0 1px 0 0 rgba(22, 29, 37, 0.05); }
    .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb{
      cursor:-webkit-grab;
      width:2.4rem;
      height:2.4rem;
      border:0.1rem solid #f9fafb;
      border-radius:50%;
      background:linear-gradient(white, #f9fafb);
      box-shadow:0 0 0 0.1rem rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(22, 29, 37, 0.05);
      -moz-appearance:none;
           appearance:none;
      transition-property:border-color, box-shadow;
      transition-duration:200ms;
      transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
      .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb:hover{
        background:linear-gradient(#f9fafb, #f4f6f8);
        box-shadow:0 0 0 0.1rem rgba(0, 0, 0, 0.4), 0 1px 0 0 rgba(22, 29, 37, 0.05); }
    .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb{
      cursor:-webkit-grab;
      width:2.4rem;
      height:2.4rem;
      border:0.1rem solid #f9fafb;
      border-radius:50%;
      background:linear-gradient(white, #f9fafb);
      box-shadow:0 0 0 0.1rem rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(22, 29, 37, 0.05);
      -webkit-appearance:none;
              appearance:none;
      transition-property:border-color, box-shadow;
      transition-duration:200ms;
      transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
      .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb:hover{
        background:linear-gradient(#f9fafb, #f4f6f8);
        box-shadow:0 0 0 0.1rem rgba(0, 0, 0, 0.4), 0 1px 0 0 rgba(22, 29, 37, 0.05); }
    .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb{
      margin-top:0;
      transform:translateY(0.48rem) scale(0.4); }
    .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb{
      margin-top:-1rem; }
    .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input{
      --progress-lower:var(--p-action-critical, #de3618); }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-ms-track{
        background-color:var(--p-override-none, #fead9a); }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-moz-range-track{
        background-color:var(--p-override-none, #fead9a); }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track{
        background-color:var(--p-override-none, #fead9a); }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb{
        border-color:#de3618;
        box-shadow:0 0 0 0.1rem #de3618; }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb{
        border-color:#de3618;
        box-shadow:0 0 0 0.1rem #de3618; }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb{
        border-color:#de3618;
        box-shadow:0 0 0 0.1rem #de3618; }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-ms-track{
      cursor:auto;
      background-image:none;
      background-color:var(--p-border-secondary-disabled, #c4cdd5); }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-moz-range-track{
      cursor:auto;
      background-image:none;
      background-color:var(--p-border-secondary-disabled, #c4cdd5); }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track{
      cursor:auto;
      background-image:none;
      background-color:var(--p-border-secondary-disabled, #c4cdd5); }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb{
      cursor:not-allowed;
      border-color:#c4cdd5; }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb{
      cursor:not-allowed;
      border-color:#c4cdd5; }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb{
      cursor:not-allowed;
      border-color:#c4cdd5; }
    .Polaris-RangeSlider-SingleThumb__Input:focus::-ms-thumb{
      background:linear-gradient(#f9fafb, #f4f6f8);
      border-color:#5c6ac4;
      box-shadow:0 0 0 0.1rem #5c6ac4; }
    .Polaris-RangeSlider-SingleThumb__Input:focus::-moz-range-thumb{
      background:linear-gradient(#f9fafb, #f4f6f8);
      border-color:#5c6ac4;
      box-shadow:0 0 0 0.1rem #5c6ac4; }
    .Polaris-RangeSlider-SingleThumb__Input:focus::-webkit-slider-thumb{
      background:linear-gradient(#f9fafb, #f4f6f8);
      border-color:#5c6ac4;
      box-shadow:0 0 0 0.1rem #5c6ac4; }
    @media (-ms-high-contrast: active){
      .Polaris-RangeSlider-SingleThumb__Input:focus{
        outline:1px solid windowText; } }

  .Polaris-RangeSlider-SingleThumb__Output{
    position:absolute;
    z-index:10;
    bottom:2.4rem;
    left:var(--Polaris-RangeSlider-progress, 50%);
    transform:translateX(calc(-50% + var(--Polaris-RangeSlider-output-factor, 0)*2.4rem));
    opacity:0;
    visibility:hidden;
    pointer-events:none;
    transition-property:opacity, visibility;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output,
    .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output,
    .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output{
      opacity:1;
      visibility:visible; }

  .Polaris-RangeSlider-SingleThumb__OutputBubble{
    position:relative;
    display:flex;
    padding:0 0.8rem;
    min-width:3.2rem;
    height:3.2rem;
    background-color:#212b36;
    border-radius:3px;
    transition-property:transform;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble,
    .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble,
    .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble{
      transform:translateY(-1.6rem); }
      @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
        [data-has-navigation] .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, [data-has-navigation]
        .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, [data-has-navigation]
        .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble{
          transform:translateY(-0.8rem); } }
      @media (min-width: 46.5em){
        .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble,
        .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble,
        .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble{
          transform:translateY(-0.8rem); } }

  .Polaris-RangeSlider-SingleThumb__OutputText{
    font-size:1.3rem;
    font-weight:600;
    line-height:1.6rem;
    text-transform:uppercase;
    display:block;
    flex:1 1 auto;
    margin:auto;
    text-align:center;
    color:white; }
    @media (min-width: 40em){
      .Polaris-RangeSlider-SingleThumb__OutputText{
        font-size:1.2rem; } }


  .Polaris-ResourceItem__CheckboxWrapper{
    display:flex; }

  .Polaris-ResourceItem{
    position:relative;
    outline:none;
    cursor:pointer; }
    .Polaris-ResourceItem:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions{
      right:1.6rem; }
    .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--newDesignLanguage){
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions{
      clip:auto;
      overflow:visible;
      height:100%; }
      @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
        [data-has-navigation] .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions{
          display:none; } }
      @media (max-width: 46.5em){
        .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions{
          display:none; } }
    .Polaris-ResourceItem:active:not(.Polaris-ResourceItem--newDesignLanguage){
      background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }

  .Polaris-ResourceItem--selected:not(.Polaris-ResourceItem--newDesignLanguage){
    background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)); }
    .Polaris-ResourceItem--selected:not(.Polaris-ResourceItem--newDesignLanguage).Polaris-ResourceItem--focused{
      box-shadow:inset 0.2rem 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
      .Polaris-ResourceItem--selected:not(.Polaris-ResourceItem--newDesignLanguage).Polaris-ResourceItem--focused:hover{
        box-shadow:inset 0.2rem 0 0 #5c6ac4;
        background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ResourceItem--selected:not(.Polaris-ResourceItem--newDesignLanguage):hover{
      background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ResourceItem--selected:not(.Polaris-ResourceItem--newDesignLanguage):active{
      background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }

  .Polaris-ResourceItem--focused:not(.Polaris-ResourceItem--newDesignLanguage){
    box-shadow:inset 0.2rem 0 0 #5c6ac4;
    background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ResourceItem--focused:not(.Polaris-ResourceItem--newDesignLanguage):hover{
      box-shadow:inset 0.2rem 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ResourceItem--focused:not(.Polaris-ResourceItem--newDesignLanguage):active{
      box-shadow:inset 0.2rem 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }

  .Polaris-ResourceItem--focusedInner,
  .Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused,
  .Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused.Polaris-ResourceItem--selected{
    box-shadow:none; }

  .Polaris-ResourceItem__Link,
  .Polaris-ResourceItem__Button{
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    height:100%;
    width:100%;
    opacity:0; }

  .Polaris-ResourceItem__Button{
    padding:0;
    border:none; }

  .Polaris-ResourceItem__Container{
    position:relative;
    z-index:2;
    padding:1.2rem 1.6rem;
    min-height:4.4rem;
    display:flex;
    align-items:flex-start; }
    @media (min-width: 28.625em){
      .Polaris-ResourceItem__Container{
        padding:1.2rem 2rem; } }

  .Polaris-ResourceItem__Owned{
    display:flex;
    align-self:flex-start; }

  .Polaris-ResourceItem__Handle{
    width:4.8rem;
    min-height:4.4rem;
    justify-content:center;
    align-items:center;
    margin:-1.2rem 0.4rem -1.2rem -1.2rem;
    display:flex; }
    @media (max-width: 28.615em){
      .Polaris-ResourceItem__Handle{
        visibility:hidden; }
        .Polaris-ResourceItem--selectMode .Polaris-ResourceItem__Handle{
          visibility:visible; } }

  .Polaris-ResourceItem--selectable{
    width:calc(100% + 4rem);
    transform:translateX(-4rem);
    transition:transform cubic-bezier(0.64, 0, 0.35, 1) 200ms;
    margin-right:-4rem; }
    .Polaris-ResourceItem--selectable.Polaris-ResourceItem--selectMode{
      transform:translateX(0); }
    @media (min-width: 28.625em){
      .Polaris-ResourceItem--selectable{
        width:100%;
        transform:translateX(0);
        margin-right:0; } }

  .Polaris-ResourceItem__Media{
    flex:0 0 auto;
    margin-right:2rem;
    color:inherit;
    text-decoration:none; }

  .Polaris-ResourceItem__Content{
    min-width:0;
    max-width:100%;
    flex:1 1 auto;
    align-self:center; }

  .Polaris-ResourceItem__Actions{
    position:absolute;
    top:0;
    display:flex;
    pointer-events:initial;
    height:100%;
    max-height:5.6rem;
    clip:rect(1px, 1px, 1px, 1px);
    overflow:hidden;
    height:1px; }
    .Polaris-ResourceItem--focused .Polaris-ResourceItem__Actions{
      clip:auto;
      overflow:visible;
      height:100%; }
    @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
      [data-has-navigation] .Polaris-ResourceItem__Actions{
        display:none; } }
    @media (max-width: 46.5em){
      .Polaris-ResourceItem__Actions{
        display:none; } }

  .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions{
    position:relative;
    display:flex;
    flex:0 0 auto;
    flex-basis:auto;
    align-items:center;
    margin-top:0;
    margin-left:1.6rem;
    pointer-events:initial;
    height:100%; }
    @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
      [data-has-navigation] .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions{
        display:none; } }
    @media (max-width: 46.5em){
      .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions{
        display:none; } }

  .Polaris-ResourceItem__Disclosure{
    position:relative;
    top:-1.2rem;
    right:-1.6rem;
    display:none;
    width:4.8rem;
    min-height:4.4rem;
    pointer-events:initial; }
    .Polaris-ResourceItem--selectMode .Polaris-ResourceItem__Disclosure{
      display:none; }
    @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
      [data-has-navigation] .Polaris-ResourceItem__Disclosure{
        display:flex;
        flex:0 0 4.8rem;
        justify-content:center;
        align-items:center; } }
    @media (max-width: 46.5em){
      .Polaris-ResourceItem__Disclosure{
        display:flex;
        flex:0 0 4.8rem;
        justify-content:center;
        align-items:center; } }

  .Polaris-ResourceItem--newDesignLanguage{
    position:relative; }
    .Polaris-ResourceItem--newDesignLanguage::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
    @media (min-width: 28.625em){
      .Polaris-ResourceItem--newDesignLanguage.Polaris-ResourceItem--focused:not(:active):not(.Polaris-ResourceItem--focusedInner)::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); } }
    @media (max-width: 28.615em){
      .Polaris-ResourceItem--newDesignLanguage.Polaris-ResourceItem--focused:not(:active):not(.Polaris-ResourceItem--focusedInner){
        box-shadow:inset 0.2rem 0 0 var(--p-focused); } }
    .Polaris-ResourceItem--newDesignLanguage.Polaris-ResourceItem--selected{
      background-color:var(--p-surface-selected); }
    .Polaris-ResourceItem--newDesignLanguage:hover{
      background-color:var(--p-surface-selected-hovered); }
    .Polaris-ResourceItem--newDesignLanguage:active{
      background-color:var(--p-surface-selected-pressed); }


  .Polaris-Select{
    position:relative; }
    .Polaris-Select select::-ms-expand{
      display:none; }

  .Polaris-Select--disabled .Polaris-Select__Content{
    color:var(--p-text-disabled, #919eab); }

  .Polaris-Select--disabled .Polaris-Select__InlineLabel{
    color:inherit; }

  .Polaris-Select--disabled .Polaris-Select__Icon{
    opacity:var(--p-override-one, 0.4); }
    .Polaris-Select--disabled .Polaris-Select__Icon svg{
      fill:var(--p-icon-disabled, #637381); }

  .Polaris-Select--disabled.Polaris-Select--newDesignLanguage .Polaris-Select__Backdrop{
    border-color:var(--p-border-secondary-disabled); }
    .Polaris-Select--disabled.Polaris-Select--newDesignLanguage .Polaris-Select__Backdrop::before{
      background-color:var(--p-action-secondary-disabled); }
    .Polaris-Select--disabled.Polaris-Select--newDesignLanguage .Polaris-Select__Backdrop:hover{
      cursor:default; }

  .Polaris-Select--placeholder.Polaris-Select--error .Polaris-Select__Input{
    color:#9c9798; }

  .Polaris-Select--placeholder.Polaris-Select--error .Polaris-Select__Input:-moz-focusring{
    color:transparent;
    text-shadow:var(--p-override-none, 0 0 0 #212b36); }

  .Polaris-Select__Content{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    z-index:20;
    display:flex;
    align-items:center;
    width:100%;
    min-height:3.6rem;
    padding:0.5rem 0.8rem 0.5rem 1.2rem; }
    @media (min-width: 40em){
      .Polaris-Select__Content{
        font-size:1.4rem; } }

  .Polaris-Select__InlineLabel{
    color:var(--p-text-subdued, #637381);
    margin-right:0.4rem;
    white-space:nowrap;
    overflow:hidden; }

  .Polaris-Select__SelectedOption{
    flex:1 1;
    white-space:nowrap;
    overflow:hidden; }

  .Polaris-Select__Icon svg{
    fill:var(--p-icon, #637381); }

  .Polaris-Select__Input{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem;
    text-transform:initial;
    letter-spacing:initial;
    position:absolute;
    text-rendering:auto;
    top:0;
    left:0;
    z-index:30;
    width:100%;
    height:100%;
    margin:0;
    opacity:0.001;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none; }
    @media (min-width: 40em){
      .Polaris-Select__Input{
        font-size:1.4rem; } }

  .Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop{
    background-color:#c4cdd5;
    border:0.1rem solid transparent;
    box-shadow:0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    transition-property:box-shadow, background-color;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    position:absolute;
    z-index:10;
    top:0;
    bottom:0;
    left:0;
    right:0; }
    .Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop::after{
      content:'';
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      display:block;
      background:linear-gradient(to bottom, white, #f9fafb);
      border-radius:2px; }
    @media (-ms-high-contrast: active){
      .Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop{
        background:transparent;
        border:2px solid buttonText;
        transition:none;
        color:rgba(223, 227, 232, 0.3); }
        .Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop::after{
          top:-4px;
          right:-4px;
          bottom:-4px;
          left:-4px;
          background:none;
          border-radius:4px; } }

  .Polaris-Select:not(.Polaris-Select--newDesignLanguage).Polaris-Select--error .Polaris-Select__Backdrop{
    background:#bf0711;
    box-shadow:0 0 0 1px transparent; }
    .Polaris-Select:not(.Polaris-Select--newDesignLanguage).Polaris-Select--error .Polaris-Select__Backdrop::after{
      background:#fbeae5; }

  .Polaris-Select:not(.Polaris-Select--newDesignLanguage).Polaris-Select--error .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop{
    background:#5c6ac4;
    box-shadow:0 0 0 1px #5c6ac4; }
    .Polaris-Select:not(.Polaris-Select--newDesignLanguage).Polaris-Select--error .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after{
      background:#fbeae5; }

  .Polaris-Select:not(.Polaris-Select--newDesignLanguage).Polaris-Select--disabled .Polaris-Select__Backdrop{
    background:#dfe3e8;
    box-shadow:none; }
    .Polaris-Select:not(.Polaris-Select--newDesignLanguage).Polaris-Select--disabled .Polaris-Select__Backdrop::after{
      background:#f9fafb; }
    @media (-ms-high-contrast: active){
      .Polaris-Select:not(.Polaris-Select--newDesignLanguage).Polaris-Select--disabled .Polaris-Select__Backdrop{
        color:grayText; } }

  .Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop{
    background:#5c6ac4;
    box-shadow:0 0 0 1px #5c6ac4, 0 0 0 0 transparent;
    border-color:transparent; }
    .Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after{
      background:white; }
    @media (-ms-high-contrast: active){
      .Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after{
        border:1px dashed buttonText; } }

  .Polaris-Select--newDesignLanguage .Polaris-Select__Backdrop{
    z-index:10;
    top:0;
    right:0;
    bottom:0;
    left:0;
    border:0.1rem solid var(--p-border-secondary);
    border-radius:var(--p-border-radius-base);
    background-color:var(--p-surface);
    position:relative;
    position:absolute; }
    .Polaris-Select--newDesignLanguage .Polaris-Select__Backdrop::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.2rem;
      right:-0.2rem;
      bottom:-0.2rem;
      left:-0.2rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.2rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }

  .Polaris-Select--newDesignLanguage.Polaris-Select--error .Polaris-Select__Backdrop{
    border-color:var(--p-border-critical);
    background-color:var(--p-surface-critical); }
    .Polaris-Select--newDesignLanguage.Polaris-Select--error .Polaris-Select__Backdrop.Polaris-Select--hover, .Polaris-Select--newDesignLanguage.Polaris-Select--error .Polaris-Select__Backdrop:hover{
      border-color:var(--p-border-critical); }

  .Polaris-Select--newDesignLanguage.Polaris-Select--error .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after{
    box-shadow:0 0 0 0.2rem var(--p-focused); }

  .Polaris-Select--newDesignLanguage .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after{
    box-shadow:0 0 0 0.2rem var(--p-focused); }

  @media (-ms-high-contrast: active){
    .Polaris-Select__Content{
      color:windowText;
      -ms-high-contrast-adjust:none; }
    .Polaris-Select__InlineLabel{
      color:inherit; }
      .Polaris-Select__InlineLabel::after{
        content:':'; }
    .Polaris-Select__SelectedOption{
      color:inherit; }
    .Polaris-Select__Icon svg{
      fill:buttonText; }
    .Polaris-Select__Backdrop::after{
      display:none; }
    .Polaris-Select__Input:focus ~ .Polaris-Select__Content{
      color:highlightText; }
    .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop{
      background-color:highlight; }
    .Polaris-Select--disabled .Polaris-Select__Content{
      color:grayText; }
    .Polaris-Select--disabled .Polaris-Select__Icon{
      opacity:1; }
      .Polaris-Select--disabled .Polaris-Select__Icon svg{
        fill:grayText; } }


  .Polaris-ResourceList-CheckableButton{
    font-size:1.5rem;
    font-weight:var(--p-button-font-weight, 400);
    line-height:1.6rem;
    text-transform:initial;
    letter-spacing:initial;
    display:flex;
    align-items:center;
    min-height:3.6rem;
    min-width:3.6rem;
    margin:0;
    padding:0.7rem 1.6rem;
    line-height:1;
    cursor:pointer;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    text-align:left;
    background:transparent;
    border:var(--p-override-none, 0.1rem solid var(--p-border, #c4cdd5));
    border-radius:var(--p-border-radius-base, 3px);
    width:100%;
    box-shadow:var(--p-override-none, 0 1px 0 0 rgba(22, 29, 37, 0.05)); }
    @media (min-width: 40em){
      .Polaris-ResourceList-CheckableButton{
        font-size:1.4rem; } }
    [data-buttongroup-segmented] .Polaris-ResourceList-CheckableButton{
      border-top-right-radius:0;
      border-bottom-right-radius:0;
      border-right:none; }
    .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton--newDesignLanguage{
      background:var(--p-action-secondary);
      color:var(--p-text); }
      .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton--newDesignLanguage svg{
        fill:var(--p-icon-on-interactive); }
      .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton--newDesignLanguage:hover{
        background:var(--p-action-secondary-hovered); }
      .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton--newDesignLanguage:active{
        background:var(--p-action-secondary-pressed); }
      .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton--newDesignLanguage.Polaris-ResourceList-CheckableButton__CheckableButton--selectMode{
        font-weight:500; }
    .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--measuring{
      font-size:1.5rem;
      font-weight:700; }
      .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--measuring::before{
        content:'';
        display:inline-block;
        width:1.5rem; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList-CheckableButton{
        flex:0 1 auto; } }
    .Polaris-ResourceList-CheckableButton:focus{
      outline:none; }
    .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--plain{
      border:0.1rem solid transparent;
      border-radius:3px;
      box-shadow:none;
      background:transparent; }
      .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--plain.Polaris-ResourceList-CheckableButton--newDesignLanguage{
        border:none; }
      .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--plain:hover{
        background:transparent; }
    .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--selectMode{
      color:var(--p-text-subdued, #637381);
      font-weight:600; }
    .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--selected{
      color:var(--p-text, #5c6ac4); }
      @media (min-width: 28.625em){
        .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--selected{
          border-color:#c4cdd5; } }

  .Polaris-ResourceList-CheckableButton__Checkbox{
    pointer-events:none;
    height:1.6rem;
    width:1.6rem;
    margin-left:-0.9rem; }
    .Polaris-ResourceList-CheckableButton--newDesignLanguage .Polaris-ResourceList-CheckableButton__Checkbox{
      height:var(--p-choice-size);
      width:var(--p-choice-size);
      margin-left:calc(-0.8rem - var(--p-control-border-width)); }

  .Polaris-ResourceList-CheckableButton__Label{
    margin-left:2rem;
    flex:1 1;
    white-space:nowrap;
    overflow:hidden;
    max-width:100%;
    text-overflow:ellipsis;
    padding:0.1rem 0; }
    .Polaris-ResourceList-CheckableButton--newDesignLanguage .Polaris-ResourceList-CheckableButton__Label{
      margin-left:calc(2rem - var(--p-control-border-width)); }


  .Polaris-ResourceList-BulkActions__Group{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem;
    text-transform:initial;
    letter-spacing:initial;
    width:100%;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity, display;
    transition:opacity cubic-bezier(0.64, 0, 0.35, 1) 200ms;
    display:none;
    align-items:center;
    flex-wrap:wrap;
    opacity:0; }
    @media (min-width: 40em){
      .Polaris-ResourceList-BulkActions__Group{
        font-size:1.4rem; } }
    .Polaris-ResourceList-BulkActions__Group.Polaris-ResourceList-BulkActions__Group--measuring{
      transition:none;
      display:flex;
      opacity:0; }

  .Polaris-ResourceList-BulkActions__Group--entering,
  .Polaris-ResourceList-BulkActions__Group--exiting{
    opacity:0;
    display:flex; }

  .Polaris-ResourceList-BulkActions__Group--entered{
    opacity:1;
    display:flex; }

  .Polaris-ResourceList-BulkActions__Group--exited{
    opacity:0;
    display:none; }

  @media (min-width: 28.625em){
    .Polaris-ResourceList-BulkActions__Group--smallScreen{
      display:none; } }

  .Polaris-ResourceList-BulkActions__Group--largeScreen{
    display:none; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList-BulkActions__Group--largeScreen{
        display:flex; }
        .Polaris-ResourceList-BulkActions__Group--largeScreen.Polaris-ResourceList-BulkActions__Group--exiting{
          transition:none; }
        .Polaris-ResourceList-BulkActions__Group--largeScreen.Polaris-ResourceList-BulkActions__Group--exited{
          opacity:0;
          display:none; } }

  .Polaris-ResourceList-BulkActions__ButtonGroupWrapper{
    width:100%;
    max-width:100%; }
    @media (max-width: 28.625em){
      .Polaris-ResourceList-BulkActions__ButtonGroupWrapper > div > div:first-child{
        flex:1 1 auto; } }
    @media (min-width: 28.625em){
      .Polaris-ResourceList-BulkActions__ButtonGroupWrapper{
        width:auto;
        justify-content:flex-start;
        margin-right:0.8rem; } }
    .Polaris-ResourceList-BulkActions__Group--measuring .Polaris-ResourceList-BulkActions__ButtonGroupWrapper{
      position:absolute;
      width:auto; }

  .Polaris-ResourceList-BulkActions__BulkActionButton{
    white-space:nowrap; }

  .Polaris-ResourceList-BulkActions__CheckableContainer{
    flex:1 1; }

  .Polaris-ResourceList-BulkActions--disabled{
    transition:none;
    background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
    color:#919eab;
    cursor:default;
    pointer-events:none; }
    .Polaris-ResourceList-BulkActions--disabled svg{
      fill:#919eab; }
    .Polaris-ResourceList-BulkActions--disabled.Polaris-ResourceList-BulkActions--newDesignLanguage{
      background:var(--p-action-secondary-disabled);
      color:var(--p-text-disabled); }
      .Polaris-ResourceList-BulkActions--disabled.Polaris-ResourceList-BulkActions--newDesignLanguage svg{
        fill:var(--p-icon-disabled); }

  .Polaris-ResourceList-BulkActions__PaginatedSelectAll{
    padding:0.4rem 0; }

  .Polaris-ResourceList-BulkActions__Slide{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:transform;
    transform:translateY(0);
    transition:transform cubic-bezier(0.64, 0, 0.35, 1) 200ms; }

  .Polaris-ResourceList-BulkActions__Slide--appear,
  .Polaris-ResourceList-BulkActions__Slide--enter,
  .Polaris-ResourceList-BulkActions__Slide--exit{
    transform:translateX(-4rem); }

  .Polaris-ResourceList-BulkActions__Slide--appearing,
  .Polaris-ResourceList-BulkActions__Slide--entering{
    transform:translateY(0); }


  .Polaris-FilterControl-DateSelector__DateTextField{
    margin-top:0.8rem; }

  .Polaris-FilterControl-DateSelector__DatePicker{
    margin-top:1.6rem; }


  .Polaris-ResourceList-FilterControl__AppliedFilters{
    list-style-type:none;
    padding:0;
    margin:0;
    display:flex;
    flex-wrap:nowrap;
    margin-top:-1.2rem;
    margin-left:-1.2rem;
    margin-right:-1.2rem;
    overflow-x:auto;
    overflow-y:hidden;
    -webkit-overflow-scrolling:touch; }
    .Polaris-ResourceList-FilterControl__AppliedFilters::after{
      content:'';
      flex:0 0 1.2rem; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList-FilterControl__AppliedFilters{
        flex-wrap:wrap;
        margin-right:0;
        margin-left:-0.8rem;
        margin-top:-1.6rem; }
        .Polaris-ResourceList-FilterControl__AppliedFilters::after{
          content:none;
          flex:none; } }
    @media (max-width: 28.625em){
      .Polaris-ResourceList-FilterControl__AppliedFilters{
        padding-bottom:0.8rem; } }

  .Polaris-ResourceList-FilterControl__AppliedFilter{
    flex:1 0 auto;
    margin-top:0.8rem;
    margin-left:1.2rem;
    max-width:calc(100% - 0.8rem); }
    @media (min-width: 28.625em){
      .Polaris-ResourceList-FilterControl__AppliedFilter{
        flex:0 1 auto;
        margin-left:0.8rem; } }


  .Polaris-ResourceList__FiltersWrapper{
    padding:1.2rem 1.2rem 1.6rem; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__FiltersWrapper{
        padding:1.6rem; } }
    .Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList{
      border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }

  .Polaris-ResourceList__HeaderOuterWrapper{
    position:relative;
    background-color:var(--p-surface, white);
    z-index:2; }
    .Polaris-ResourceList__HeaderOuterWrapper + .Polaris-ResourceList{
      border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }

  .Polaris-ResourceList__HeaderWrapper--disabled{
    pointer-events:none; }

  .Polaris-ResourceList__HeaderWrapper--overlay{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:4;
    background-color:var(--p-overlay, rgba(255, 255, 255, 0.5)); }

  .Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList__HeaderOuterWrapper{
    margin-top:-1.6rem; }

  .Polaris-ResourceList__HeaderWrapper{
    position:relative;
    display:flex;
    align-items:flex-start;
    width:100%;
    min-height:5.6rem;
    padding:1rem 1.2rem;
    background-color:var(--p-surface, white); }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__HeaderWrapper{
        flex-direction:row;
        padding:1rem 1.6rem; } }

  .Polaris-ResourceList__HeaderWrapper--isSticky{
    box-shadow:0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15); }

  .Polaris-ResourceList__HeaderContentWrapper{
    position:absolute;
    z-index:1;
    right:1.2rem;
    left:1.2rem;
    display:flex;
    min-height:3.6rem;
    opacity:1;
    transition:opacity cubic-bezier(0.64, 0, 0.35, 1) 200ms; }
    .Polaris-ResourceList__HeaderWrapper--inSelectMode .Polaris-ResourceList__HeaderContentWrapper{
      opacity:0; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__HeaderContentWrapper{
        right:1.6rem;
        left:1.6rem;
        align-self:flex-start; } }

  .Polaris-ResourceList__SortWrapper,
  .Polaris-ResourceList__AlternateToolWrapper{
    position:relative;
    display:flex;
    flex:1 1;
    align-items:center; }
    .Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__AlternateToolWrapper{
      padding-right:0.8rem; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__SortWrapper,
      .Polaris-ResourceList__AlternateToolWrapper{
        position:relative;
        left:auto;
        flex:0 1 auto;
        margin-left:1.6rem; }
        .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper,
        .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect
        .Polaris-ResourceList__AlternateToolWrapper,
        .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect
        .Polaris-ResourceList__AlternateToolWrapper{
          padding-right:0; } }

  .Polaris-ResourceList__HeaderTitleWrapper{
    white-space:nowrap;
    text-overflow:ellipsis;
    flex:1 1;
    padding-left:0.4rem;
    align-self:center; }
    .Polaris-ResourceList__HeaderWrapper--hasAlternateTool .Polaris-ResourceList__HeaderTitleWrapper,
    .Polaris-ResourceList__HeaderWrapper--hasSort .Polaris-ResourceList__HeaderTitleWrapper{
      display:none; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper,
      .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper,
      .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper{
        display:none; }
      .Polaris-ResourceList__HeaderWrapper--hasAlternateTool .Polaris-ResourceList__HeaderTitleWrapper,
      .Polaris-ResourceList__HeaderWrapper--hasSort .Polaris-ResourceList__HeaderTitleWrapper{
        display:block; } }

  .Polaris-ResourceList__BulkActionsWrapper{
    position:relative;
    z-index:2;
    width:100%; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__BulkActionsWrapper{
        flex:0 1 auto;
        align-self:flex-start; } }

  .Polaris-ResourceList__CheckableButtonWrapper{
    display:none; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__CheckableButtonWrapper{
        flex:1 1;
        display:block; } }

  .Polaris-ResourceList__SelectButtonWrapper{
    position:relative;
    flex:none; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__SelectButtonWrapper{
        display:none; } }

  .Polaris-ResourceList__EmptySearchResultWrapper{
    padding-top:60px;
    padding-bottom:60px; }
    @media screen and (max-height: 600px){
      .Polaris-ResourceList__EmptySearchResultWrapper{
        padding-top:32px;
        padding-bottom:32px; } }

  .Polaris-ResourceList__ResourceListWrapper{
    position:relative; }

  .Polaris-ResourceList{
    position:relative;
    z-index:1;
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-ResourceList__ItemWrapper{
    position:relative;
    z-index:1;
    overflow:hidden;
    max-width:100%; }
    .Polaris-ResourceList__ItemWrapper + .Polaris-ResourceList__ItemWrapper{
      border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }

  .Polaris-ResourceList__ItemWrapper--isLoading{
    min-height:6.4rem; }

  .Polaris-ResourceList__SpinnerContainer{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:3;
    display:flex;
    justify-content:center; }

  .Polaris-ResourceList__LoadingOverlay{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:2;
    display:flex;
    justify-content:center;
    background-color:var(--p-overlay, rgba(255, 255, 255, 0.5)); }

  .Polaris-ResourceList__DisabledPointerEvents{
    pointer-events:none; }

  .Polaris-ResourceList--disableTextSelection{
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none; }


  .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity; }
    .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
      animation:polaris-SkeletonShimmerAnimation 800ms linear infinite alternate; }

  .Polaris-SkeletonBodyText{
    height:0.8rem;
    position:relative; }
    .Polaris-SkeletonBodyText::after{
      content:'';
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      display:block;
      background-color:#dfe3e8;
      border-radius:3px; }
      @media screen and (-ms-high-contrast: active){
        .Polaris-SkeletonBodyText::after{
          background-color:grayText; } }
    .Polaris-SkeletonBodyText:last-child:not(:first-child){
      width:80%; }
    .Polaris-SkeletonBodyText + .Polaris-SkeletonBodyText{
      margin-top:1.2rem; }


  .Polaris-SkeletonDisplayText__DisplayText{
    max-width:12rem;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity;
    position:relative; }
    .Polaris-SkeletonDisplayText__DisplayText{
      animation:polaris-SkeletonShimmerAnimation 800ms linear infinite alternate; }
    .Polaris-SkeletonDisplayText__DisplayText::after{
      content:'';
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      display:block;
      background-color:#dfe3e8;
      border-radius:3px; }
      @media screen and (-ms-high-contrast: active){
        .Polaris-SkeletonDisplayText__DisplayText::after{
          background-color:grayText; } }

  .Polaris-SkeletonDisplayText--sizeSmall{
    height:2.4rem; }
    @media (min-width: 40em){
      .Polaris-SkeletonDisplayText--sizeSmall{
        height:2.8rem; } }

  .Polaris-SkeletonDisplayText--sizeMedium{
    height:2.8rem; }
    @media (min-width: 40em){
      .Polaris-SkeletonDisplayText--sizeMedium{
        height:3.2rem; } }

  .Polaris-SkeletonDisplayText--sizeLarge{
    height:2.8rem; }
    @media (min-width: 40em){
      .Polaris-SkeletonDisplayText--sizeLarge{
        height:3.2rem; } }

  .Polaris-SkeletonDisplayText--sizeExtraLarge{
    height:3.6rem; }
    @media (min-width: 40em){
      .Polaris-SkeletonDisplayText--sizeExtraLarge{
        height:4.4rem; } }


  .Polaris-SkeletonPage__Page{
    margin:0 auto;
    padding:0;
    max-width:99.8rem; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-SkeletonPage__Page{
        padding:0 2rem; } }
    @media (min-width: 30.625em){
      .Polaris-SkeletonPage__Page{
        padding:0 2rem; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__Page{
        padding:0 3.2rem; } }
    @media (min-width: 46.5em){
      .Polaris-SkeletonPage__Page{
        padding:0 3.2rem; } }

  .Polaris-SkeletonPage--fullWidth{
    max-width:none; }

  .Polaris-SkeletonPage--narrowWidth{
    max-width:66.2rem; }

  .Polaris-SkeletonPage__Content{
    margin:2rem 0; }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__Content{
        margin-top:2rem; } }
    @media (min-width: 46.5em){
      .Polaris-SkeletonPage__Content{
        margin-top:2rem; } }

  .Polaris-SkeletonPage__Header{
    padding:2rem 2rem 0;
    padding-bottom:0.8rem; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-SkeletonPage__Header{
        padding-left:0;
        padding-right:0; } }
    @media (min-width: 30.625em){
      .Polaris-SkeletonPage__Header{
        padding-left:0;
        padding-right:0; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__Header{
        padding-top:3.2rem; } }
    @media (min-width: 46.5em){
      .Polaris-SkeletonPage__Header{
        padding-top:3.2rem; } }

  .Polaris-SkeletonPage__Header--hasSecondaryActions{
    padding-top:2.4rem;
    margin-top:0; }

  .Polaris-SkeletonPage__BreadcrumbAction{
    padding-top:1.6rem;
    padding-bottom:1.6rem;
    margin-top:-0.4rem;
    margin-bottom:-0.4rem; }

  .Polaris-SkeletonPage__TitleAndPrimaryAction{
    display:flex; }
    @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__TitleAndPrimaryAction{
        display:block; } }
    @media (max-width: 46.5em){
      .Polaris-SkeletonPage__TitleAndPrimaryAction{
        display:block; } }

  .Polaris-SkeletonPage__Title{
    flex:1 1; }

  .Polaris-SkeletonPage__PrimaryAction{
    align-self:stretch; }
    .Polaris-SkeletonPage__PrimaryAction > *{
      height:3.6rem;
      min-width:10rem; }
    @media (max-width: 48.0625em), (min-width: 48.0625em) and (max-width: 65em){
      [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction{
        margin-top:1.6rem;
        margin-bottom:-0.8rem; } }
    @media (max-width: 50em){
      .Polaris-SkeletonPage__PrimaryAction{
        margin-top:1.6rem;
        margin-bottom:-0.8rem; } }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0.8rem;
        margin-bottom:-0.8rem; } }
    @media (min-width: 30.625em){
      .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0.8rem;
        margin-bottom:-0.8rem; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0; } }
    @media (min-width: 46.5em){
      .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0; } }
    @media (max-width: 48.0625em) and (min-width: 50em), (min-width: 65em){
      [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0; } }
    @media (min-width: 50em){
      .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0; } }

  .Polaris-SkeletonPage__Actions{
    margin-top:0.8rem;
    display:flex;
    flex-direction:row-reverse;
    justify-content:flex-end;
    align-items:center; }

  .Polaris-SkeletonPage__Action{
    display:flex;
    flex-direction:column;
    justify-content:center;
    min-height:2.8rem;
    padding-right:2.4rem;
    margin-top:-0.4rem;
    margin-bottom:-0.4rem;
    padding-top:1.6rem; }
    .Polaris-SkeletonPage__Action:first-child{
      padding-right:0; }
    @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__Action:not(:last-child){
        display:none; } }
    @media (max-width: 46.5em){
      .Polaris-SkeletonPage__Action:not(:last-child){
        display:none; } }


  .Polaris-SkeletonThumbnail{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity;
    position:relative; }
    .Polaris-SkeletonThumbnail{
      animation:polaris-SkeletonShimmerAnimation 800ms linear infinite alternate; }
    .Polaris-SkeletonThumbnail::after{
      content:'';
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      display:block;
      background-color:#dfe3e8;
      border-radius:3px; }
      @media screen and (-ms-high-contrast: active){
        .Polaris-SkeletonThumbnail::after{
          background-color:grayText; } }

  .Polaris-SkeletonThumbnail--sizeSmall{
    height:4rem;
    width:4rem; }

  .Polaris-SkeletonThumbnail--sizeMedium{
    height:6rem;
    width:6rem; }

  .Polaris-SkeletonThumbnail--sizeLarge{
    height:8rem;
    width:8rem; }


  .Polaris-Tabs{
    display:flex;
    flex-wrap:wrap;
    margin:0;
    padding:0;
    border-bottom:0.1rem solid var(--p-border-subdued, #dfe3e8);
    list-style:none; }

  .Polaris-Tabs--fitted{
    flex-wrap:nowrap; }
    .Polaris-Tabs--fitted .Polaris-Tabs__TabContainer{
      flex:1 1 100%; }
    .Polaris-Tabs--fitted .Polaris-Tabs__Tab{
      padding:0; }
    .Polaris-Tabs--fitted .Polaris-Tabs__Title{
      width:100%;
      padding:0.8rem 1.6rem; }

  .Polaris-Tabs--fillSpace .Polaris-Tabs__TabContainer{
    flex:1 1 auto; }

  .Polaris-Tabs__TabContainer{
    display:flex;
    margin:0;
    padding:0; }

  .Polaris-Tabs__Tab{
    color:inherit;
    text-decoration:none;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    font-size:1.5rem;
    font-weight:400;
    line-height:2rem;
    text-transform:initial;
    letter-spacing:initial;
    color:var(--p-text-subdued, #637381);
    position:relative;
    justify-content:center;
    width:100%;
    min-width:100%;
    margin-top:1px;
    margin-bottom:-1px;
    padding:0 1.6rem;
    outline:none;
    text-align:center;
    white-space:nowrap;
    text-decoration:none;
    cursor:pointer; }
    .Polaris-Tabs__Tab:visited{
      color:inherit; }
    .Polaris-Tabs__Tab:focus{
      outline:none; }
    @media (min-width: 40em){
      .Polaris-Tabs__Tab{
        font-size:1.4rem; } }
    .Polaris-Tabs__Tab:hover .Polaris-Tabs__Title{
      font-weight:400;
      color:var(--p-text, #212b36);
      text-decoration:none;
      border-bottom:0.3rem solid #dfe3e8; }
      @media (-ms-high-contrast: active){
        .Polaris-Tabs__Tab:hover .Polaris-Tabs__Title{
          border-bottom-color:highlight; } }
    .Polaris-Tabs__Tab:focus{
      box-shadow:inset 0 0 2px 0 rgba(92, 106, 196, 0.8), 0 0 2px 0 rgba(92, 106, 196, 0.8); }
      .Polaris-Tabs__Tab:focus .Polaris-Tabs__Title{
        font-weight:400;
        color:var(--p-text, #212b36);
        border-bottom:0.3rem solid #b3bcf5; }
    .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title{
      border-bottom:0.3rem solid #5c6ac4; }
    @media (-ms-high-contrast: active){
      .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected{
        outline:1px solid highlightText;
        background:highlight;
        border-bottom-color:highlight; } }

  .Polaris-Tabs__Tab--selected{
    font-weight:400;
    color:var(--p-text, #212b36); }

  .Polaris-Tabs__Title{
    display:block;
    padding:1.6rem 0 1.3rem 0;
    border-bottom:0.3rem solid transparent;
    min-width:5rem; }

  .Polaris-Tabs__Panel{
    display:block; }
    .Polaris-Tabs__Panel:focus{
      outline:none; }

  .Polaris-Tabs__Panel--hidden{
    display:none; }

  .Polaris-Tabs__List{
    list-style:none;
    margin:0;
    padding:0.8rem 0; }

  .Polaris-Tabs__Item{
    color:inherit;
    text-decoration:none;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    display:block;
    width:100%;
    min-height:1.6rem;
    padding:0.8rem 1.6rem;
    text-align:left;
    cursor:pointer; }
    .Polaris-Tabs__Item:visited{
      color:inherit; }
    .Polaris-Tabs__Item:focus{
      outline:none; }
    .Polaris-Tabs__Item:active{
      background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }
      .Polaris-Tabs__Item:active:hover{
        background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
        .Polaris-Tabs__Item:active:hover:focus{
          box-shadow:inset 0.2rem 0 0 #5c6ac4;
          background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-Tabs__Item:hover{
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
      .Polaris-Tabs__Item:hover:focus{
        box-shadow:inset 0.2rem 0 0 #5c6ac4;
        background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-Tabs__Item:focus{
      box-shadow:inset 0.2rem 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-Tabs__Item::-moz-focus-inner{
      border:none; }

  .Polaris-Tabs__DisclosureTab{
    display:none; }

  .Polaris-Tabs__DisclosureTab--visible{
    display:flex; }

  .Polaris-Tabs__DisclosureActivator{
    position:relative;
    display:block;
    justify-content:center;
    height:100%;
    margin:1px 1px -1px 0;
    padding:0 1.6rem;
    background-color:transparent;
    cursor:pointer;
    border:none;
    outline:none;
    text-align:center; }
    .Polaris-Tabs__DisclosureActivator svg{
      fill:#637381; }
    .Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title{
      border-bottom:0.3rem solid #dfe3e8; }
      .Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title svg{
        fill:#212b36; }
      @media (-ms-high-contrast: active){
        .Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title{
          background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3));
          border-bottom-color:highlight; }
          .Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title svg{
            fill:black; } }
    .Polaris-Tabs__DisclosureActivator:focus{
      box-shadow:inset 0 0 2px 0 rgba(92, 106, 196, 0.8), 0 0 2px 0 rgba(92, 106, 196, 0.8); }
      .Polaris-Tabs__DisclosureActivator:focus .Polaris-Tabs__Title{
        border-bottom:0.3rem solid #b3bcf5; }

  .Polaris-Tabs__TabMeasurer{
    display:flex;
    visibility:hidden;
    height:0; }


  .Polaris-Thumbnail{
    position:relative;
    display:block;
    overflow:hidden;
    background:var(--p-surface, white);
    min-width:4rem;
    max-width:100%;
    border-radius:3px; }
    .Polaris-Thumbnail::after{
      content:'';
      position:relative;
      z-index:20;
      display:block;
      padding-bottom:100%;
      box-shadow:inset 0 0 0 1px rgba(33, 43, 54, 0.1);
      border-radius:3px; }

  .Polaris-Thumbnail--sizeSmall{
    width:4rem; }

  .Polaris-Thumbnail--sizeMedium{
    width:6rem; }

  .Polaris-Thumbnail--sizeLarge{
    width:8rem; }

  .Polaris-Thumbnail__Image{
    position:absolute;
    z-index:10;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin:auto;
    max-width:100%;
    max-height:100%; }


  .Polaris-TopBar-SearchDismissOverlay{
    position:fixed;
    top:5.6rem;
    left:0;
    right:0;
    z-index:515;
    height:calc(100vh - 5.6rem); }

  .Polaris-TopBar-SearchDismissOverlay--visible{
    background-color:var(--p-backdrop, rgba(33, 43, 54, 0.4));
    animation:Polaris-TopBar-SearchDismissOverlay__fade--in 200ms 1 forwards; }

  @keyframes Polaris-TopBar-SearchDismissOverlay__fade--in{
    0%{
      opacity:0; }
    100%{
      opacity:1; } }


  .Polaris-TopBar-Search{
    position:fixed;
    visibility:hidden;
    pointer-events:none;
    top:5.6rem;
    left:0;
    right:0; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-TopBar-Search{
        position:absolute;
        top:100%;
        max-width:69.4rem;
        margin:0.4rem 2rem 0; } }
    @media (min-width: 30.625em){
      .Polaris-TopBar-Search{
        position:absolute;
        top:100%;
        max-width:69.4rem;
        margin:0.4rem 2rem 0; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-TopBar-Search{
        margin:0.4rem 3.2rem 0; } }
    @media (min-width: 46.5em){
      .Polaris-TopBar-Search{
        margin:0.4rem 3.2rem 0; } }

  .Polaris-TopBar-Search--visible{
    visibility:initial;
    pointer-events:all; }

  .Polaris-TopBar-Search__Results{
    position:relative;
    z-index:516;
    display:flex;
    flex-direction:column;
    max-height:calc(100vh - 5.6rem);
    margin:0; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-TopBar-Search__Results{
        max-height:60vh; } }
    @media (min-width: 30.625em){
      .Polaris-TopBar-Search__Results{
        max-height:60vh; } }


  .Polaris-TopBar-SearchField{
    position:relative;
    display:flex;
    flex:1 1 auto;
    align-items:center;
    border:1px solid transparent;
    width:100%;
    max-width:69.4rem; }

  .Polaris-TopBar-SearchField__SearchField--newDesignLanguage{
    max-width:58rem; }
    .Polaris-TopBar-SearchField__SearchField--newDesignLanguage .Polaris-TopBar-SearchField__Backdrop{
      position:relative;
      position:absolute; }
      .Polaris-TopBar-SearchField__SearchField--newDesignLanguage .Polaris-TopBar-SearchField__Backdrop::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.1rem;
        right:-0.1rem;
        bottom:-0.1rem;
        left:-0.1rem;
        display:block;
        pointer-events:none;
        box-shadow:0 0 0 -0.1rem var(--p-focused);
        transition:box-shadow 100ms var(--p-ease);
        border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
    .Polaris-TopBar-SearchField__SearchField--newDesignLanguage .Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Backdrop::after{
      box-shadow:0 0 0 0.2rem var(--p-focused); }

  .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input,
  .Polaris-TopBar-SearchField__Input:focus{
    border:none;
    color:var(--p-text, #212b36); }
    .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input::-webkit-input-placeholder,
    .Polaris-TopBar-SearchField__Input:focus::-webkit-input-placeholder{
      color:var(--p-text-subdued, #919eab); }
    .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input::-ms-input-placeholder,
    .Polaris-TopBar-SearchField__Input:focus::-ms-input-placeholder{
      color:var(--p-text-subdued, #919eab); }
    .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input::placeholder,
    .Polaris-TopBar-SearchField__Input:focus::placeholder{
      color:var(--p-text-subdued, #919eab); }

  .Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Backdrop{
    background-color:var(--p-action-secondary, white); }

  .Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Icon svg{
    fill:var(--p-icon-subdued, #919eab); }

  .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Backdrop{
    background-color:var(--p-action-secondary, white); }

  .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Icon svg{
    fill:var(--p-icon-subdued, #919eab); }

  .Polaris-TopBar-SearchField__Input{
    font-size:1.6rem;
    font-weight:400;
    line-height:2.4rem;
    text-transform:initial;
    letter-spacing:initial;
    z-index:2;
    width:calc(100% - 3.6rem);
    height:3.4rem;
    padding:0 0 0 3.6rem;
    border:none;
    background-color:transparent;
    outline:none;
    color:var(--p-text, var(--top-bar-color));
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:fill, color;
    transition:fill 200ms cubic-bezier(0.64, 0, 0.35, 1), color 200ms cubic-bezier(0.64, 0, 0.35, 1);
    -webkit-appearance:textfield;
       -moz-appearance:textfield;
            appearance:textfield; }
    @media (min-width: 40em){
      .Polaris-TopBar-SearchField__Input{
        font-size:1.4rem; } }
    .Polaris-TopBar-SearchField__Input::-webkit-input-placeholder{
      color:var(--p-text, var(--top-bar-color)); }
    .Polaris-TopBar-SearchField__Input::-ms-input-placeholder{
      color:var(--p-text, var(--top-bar-color)); }
    .Polaris-TopBar-SearchField__Input::placeholder{
      color:var(--p-text, var(--top-bar-color)); }
    .Polaris-TopBar-SearchField__Input::-webkit-search-decoration, .Polaris-TopBar-SearchField__Input::-webkit-search-cancel-button{
      -webkit-appearance:none;
              appearance:none; }
    .Polaris-TopBar-SearchField__SearchField--newDesignLanguage .Polaris-TopBar-SearchField__Input{
      height:3.6rem;
      width:100%; }

  .Polaris-TopBar-SearchField__Icon{
    position:absolute;
    z-index:3;
    top:50%;
    left:0.8rem;
    display:flex;
    height:2rem;
    pointer-events:none;
    transform:translateY(-50%); }
    .Polaris-TopBar-SearchField__Icon svg{
      fill:var(--p-icon, var(--top-bar-color)); }

  .Polaris-TopBar-SearchField__Clear{
    position:relative;
    position:relative;
    z-index:3;
    align-self:stretch;
    border:none;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    background:transparent;
    width:3.6rem; }
    .Polaris-TopBar-SearchField__Clear svg{
      fill:var(--p-icon, #919eab); }
    .Polaris-TopBar-SearchField__Clear::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-wide) + 0.1rem); }
    .Polaris-TopBar-SearchField__Clear:focus, .Polaris-TopBar-SearchField__Clear:hover{
      outline:none; }
      .Polaris-TopBar-SearchField__Clear:focus svg, .Polaris-TopBar-SearchField__Clear:hover svg{
        fill:var(--p-icon-hovered, #637381); }
    .Polaris-TopBar-SearchField__Clear:focus::after{
      box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-TopBar-SearchField__Clear:active svg{
      fill:var(--p-icon-pressed); }
    .Polaris-TopBar-SearchField__Clear:active::after{
      border:none; }

  .Polaris-TopBar-SearchField__Backdrop{
    position:absolute;
    z-index:1;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:var(--p-action-secondary, var(--top-bar-background-lighter));
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:background-color;
    transition:background-color 200ms cubic-bezier(0.64, 0, 0.35, 1);
    border-radius:var(--p-border-radius-wide, 3px);
    animation:Polaris-TopBar-SearchField--toLightBackground 0.01ms; }

  @keyframes Polaris-TopBar-SearchField--toLightBackground{
    to{
      background-color:var(--p-action-secondary, var(--top-bar-background-lighter)); } }


  .Polaris-MessageIndicator__MessageIndicatorWrapper{
    position:relative; }

  .Polaris-MessageIndicator{
    position:absolute;
    z-index:1;
    top:-0.3rem;
    right:-0.3rem;
    width:1.2rem;
    height:1.2rem;
    border-radius:100%;
    background-color:var(--p-icon-highlight, #47c1bf);
    border:solid 0.2rem var(--p-background, var(--top-bar-background)); }


  .Polaris-Menu-Message__Section{
    max-width:32.5rem;
    margin-top:0.8rem;
    padding-top:0.8rem;
    border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }


  .Polaris-TopBar-Menu__ActivatorWrapper{
    height:5.6rem;
    display:flex;
    align-items:center; }

  .Polaris-TopBar-Menu__Activator{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    position:relative;
    color:var(--p-text, var(--top-bar-color));
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    min-width:3.6rem;
    padding:0.6rem 0.8rem;
    border:0;
    cursor:pointer;
    transition:background-color 0.1s;
    margin-right:0.8rem;
    border-radius:3px; }
    .Polaris-TopBar-Menu__Activator:focus{
      outline:none; }
    .Polaris-TopBar-Menu__Activator::after{
      content:var(--p-non-null-content, none);
      position:absolute;
      z-index:1;
      top:-0.1rem;
      right:-0.1rem;
      bottom:-0.1rem;
      left:-0.1rem;
      display:block;
      pointer-events:none;
      box-shadow:0 0 0 -0.1rem var(--p-focused);
      transition:box-shadow 100ms var(--p-ease);
      border-radius:calc(var(--p-border-radius-base) + 0.1rem); }
    .Polaris-TopBar-Menu__Activator:focus{
      background-color:var(--p-override-transparent, rgba(255, 255, 255, 0.16));
      outline:none; }
      .Polaris-TopBar-Menu__Activator:focus::after{
        box-shadow:0 0 0 0.2rem var(--p-focused); }
    .Polaris-TopBar-Menu__Activator:hover{
      background-color:var(--p-surface-hovered, rgba(255, 255, 255, 0.08)); }
    .Polaris-TopBar-Menu__Activator:active, .Polaris-TopBar-Menu__Activator[aria-expanded='true']{
      background-color:var(--p-surface-pressed, rgba(0, 0, 0, 0.28));
      outline:none;
      transition:none; }
      .Polaris-TopBar-Menu__Activator:active::after, .Polaris-TopBar-Menu__Activator[aria-expanded='true']::after{
        border:none; }
    @media (max-width: 48.0525em){
      .Polaris-TopBar-Menu__Activator{
        margin:0 0.4rem; }
        .Polaris-TopBar-Menu__Activator:focus, .Polaris-TopBar-Menu__Activator:hover, .Polaris-TopBar-Menu__Activator:active, .Polaris-TopBar-Menu__Activator[aria-expanded='true']{
          background-color:transparent;
          opacity:0.85; } }

  .Polaris-TopBar-Menu__Section{
    margin-top:0.8rem;
    padding-top:0.8rem;
    border-top:0.1rem solid var(--p-border-subdued, #dfe3e8); }


  .Polaris-TopBar-UserMenu__Details{
    max-width:16rem;
    margin-left:0.8rem; }
    @media (max-width: 48.0525em){
      .Polaris-TopBar-UserMenu__Details{
        display:none; } }

  .Polaris-TopBar-UserMenu__Name{
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    font-size:1.5rem;
    font-weight:400;
    line-height:2rem;
    text-transform:initial;
    letter-spacing:initial;
    font-weight:500;
    line-height:1.6rem;
    color:var(--p-text, var(--top-bar-color));
    text-align:left; }
    @media (min-width: 40em){
      .Polaris-TopBar-UserMenu__Name{
        font-size:1.4rem; } }

  .Polaris-TopBar-UserMenu__Detail{
    font-size:1.3rem;
    font-weight:400;
    line-height:2rem;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    opacity:0.7;
    color:var(--p-text, var(--top-bar-color));
    text-align:left; }
    @media (min-width: 40em){
      .Polaris-TopBar-UserMenu__Detail{
        font-size:1.2rem;
        line-height:1.6rem; } }


  .Polaris-TopBar{
    position:relative;
    display:flex;
    height:5.6rem;
    background-color:var(--p-background, var(--top-bar-background)); }
    .Polaris-TopBar::after{
      content:'';
      position:absolute;
      bottom:0;
      width:100%;
      border-bottom:1px solid transparent; }

  .Polaris-TopBar__TopBar--newDesignLanguage{
    height:6.8rem;
    transition:box-shadow 100ms var(--p-ease); }
    .Polaris-TopBar__TopBar--newDesignLanguage.Polaris-TopBar--isScrolled{
      box-shadow:var(--p-top-bar-shadow); }

  .Polaris-TopBar__LogoContainer{
    display:none; }
    @media (min-width: 48.0625em){
      .Polaris-TopBar__LogoContainer{
        display:flex;
        flex:0 0 24rem;
        align-items:center;
        height:100%;
        padding:0 0.8rem 0 1.6rem;
        flex-basis:24rem;
        flex-basis:calc(24rem + constant(safe-area-inset-left));
        flex-basis:calc(24rem + env(safe-area-inset-left));
        padding-left:1.6rem;
        padding-left:calc(1.6rem + constant(safe-area-inset-left));
        padding-left:calc(1.6rem + env(safe-area-inset-left)); } }

  .Polaris-TopBar__Logo,
  .Polaris-TopBar__LogoLink{
    display:block; }

  .Polaris-TopBar__ContextControl{
    display:none; }
    @media (min-width: 48.0625em){
      .Polaris-TopBar__ContextControl{
        width:24rem;
        display:block; } }
    @media (min-width: 87.5em){
      .Polaris-TopBar__ContextControl{
        width:28rem; } }

  .Polaris-TopBar__NavigationIcon{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    position:relative;
    align-self:center;
    margin-left:1rem;
    margin-right:0.8rem;
    padding:0.8rem;
    border-radius:3px;
    fill:var(--p-icon, white); }
    .Polaris-TopBar__NavigationIcon:focus{
      outline:none; }
    .Polaris-TopBar__NavigationIcon.Polaris-TopBar--focused{
      background-color:var(--p-override-transparent, rgba(255, 255, 255, 0.16)); }
    .Polaris-TopBar__NavigationIcon.Polaris-TopBar--focused:active{
      background-color:var(--p-surface-pressed, rgba(0, 0, 0, 0.28)); }
    .Polaris-TopBar__NavigationIcon:hover{
      background-color:var(--p-surface-hovered, rgba(255, 255, 255, 0.08)); }
    .Polaris-TopBar__NavigationIcon::after{
      content:'';
      position:absolute;
      top:-0.8rem;
      left:-0.8rem;
      width:calc(100% + 2rem);
      height:calc(100% + 2rem); }
    @media (min-width: 48.0625em){
      .Polaris-TopBar__NavigationIcon{
        display:none; } }

  .Polaris-TopBar__Contents{
    z-index:10;
    display:flex;
    flex:1 1 auto;
    align-items:center;
    justify-content:flex-end;
    height:100%; }
    @media (min-width: 42.5em){
      .Polaris-TopBar__Contents{
        position:relative; } }

  .Polaris-TopBar__SearchField{
    margin:0 auto;
    padding:0;
    max-width:99.8rem;
    position:relative;
    width:100%;
    margin:0;
    max-width:none;
    margin-left:calc((100% - 99.8rem)/2); }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-TopBar__SearchField{
        padding:0 2rem; } }
    @media (min-width: 30.625em){
      .Polaris-TopBar__SearchField{
        padding:0 2rem; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-TopBar__SearchField{
        padding:0 3.2rem; } }
    @media (min-width: 46.5em){
      .Polaris-TopBar__SearchField{
        padding:0 3.2rem; } }
    @media (min-width: 87.5em){
      .Polaris-TopBar__SearchField{
        margin-left:calc((100% - 103.8rem)/2); } }
    @media (max-width: 1268px){
      .Polaris-TopBar__SearchField{
        margin-left:0; } }
    .Polaris-TopBar__TopBar--newDesignLanguage .Polaris-TopBar__SearchField{
      margin-left:0; }

  @media (max-width: 48.0525em){
    .Polaris-TopBar__SecondaryMenu{
      display:none; } }
}
