@import '../legacy/modal';

.Modal {
  border: 2px solid $green;
  background-color: $white;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
}

.Modal-Overlay {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 30;
  background-color: rgba($white, 0.75);
}

/**************************
 * Polaris Overrides
 **************************/
.Modal {
  .Polaris-Connected {
    width: 26.25rem;
  }

  .Polaris-TextField {
    height: 2.625rem;
    width: 26.25rem;
  }

  .Polaris-TextField__Input {
    font-size: 1.05rem;
    padding: 0.5rem 1.5rem 0.5rem 0rem;
  }

  .Polaris-TextField__Backdrop {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 0.1rem solid rgba($dark-blue, 0.4);
  }

  .Polaris-TextField__Backdrop:focus {
    border: 2px solid purple;
    background-color: green;
  }

  .Polaris-TextField__Backdrop:after {
    border: none;
  }

  .Polaris-TextField__Backdrop:focus {
    border: 0.1rem solid $dark-blue;
  }

  .Polaris-TextField--error > .Polaris-TextField__Backdrop:focus {
    border: 0.1rem solid #bf0711;
  }

  .Polaris-Label__Text {
    display: none;
  }

  .Polaris-TextField__Input::placeholder {
    visibility: visible !important;
  }

  .Polaris-InlineError {
    font-size: 0.875rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.263rem;
  }

  .Polaris-Icon {
    display: block;
    height: 1.31rem;
    width: 1.31rem;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
}

.Polaris-VisuallyHidden {
  display: none;
}
